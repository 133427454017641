import styled, { keyframes } from 'styled-components'
import {colors, fontSizes, fontWeights, spacings, borders} from '../../styles/theme'

type PositionType  = "top" | "bottom" | "left" | "right";

interface TooltipProps {
    position: PositionType
}

const fadeIn = keyframes`
    from{
        opacity: 0
    }

    to{
        opacity: 1
    }
`

export const TooltipContainer = styled.div<TooltipProps>`
    position: relative;

    .tooltip-box {
        position: absolute;
        display: none; 

        text-align: center;
        font-weight: ${fontWeights.w700};
        font-size: ${ fontSizes.xs};
        padding: ${spacings.sl};
        
        white-space: nowrap;
        border-radius: ${borders.sm};
        z-index: 50;

        color: ${colors.white};
        background:  ${colors.neutral_darkest};

        ${props => props.position === "left" ? "right: calc(100% + 10px); top: 50%; transform: translateY(-50%);" : ""}
        
        ${props => props.position === "right" ? "left: calc(100% + 10px); top: 50%; transform: translateY(-50%);" : ""}
        
        ${props => props.position === "top" ? "bottom: calc(100% + 10px); left: 50%; transform: translateX(-50%);" : ""}
        
        ${props => props.position === "bottom" ? "top: calc(100% + 10px); left: 50%; transform: translateX(-50%);" : ""}
    }

    .tooltip-box.visible {
        display: block;
        animation: ${fadeIn} 0.2s linear both;
    }

    .tooltip-arrow {
        position: absolute;
        border-width: 5px;
        border-style: solid;

        ${props => props.position === "left" ? `right: -15px; transform: translate(-50%); border-color: transparent transparent transparent ${ colors.neutral_darkest}` : ""};

        ${props => props.position === "right" ? `left: -5px; transform: translate(-50%); border-color: transparent ${ colors.neutral_darkest} transparent transparent; `: ""};

        ${props => props.position === "top" ? `left: 50%; bottom: -10px; transform: translate(-50%); border-color: ${ colors.neutral_darkest} transparent transparent transparent;` : ""};

        ${props => props.position === "bottom" ? `left: 50%; top: -10px; transform: translate(-50%); border-color: transparent transparent ${ colors.neutral_darkest} transparent;` : ""};
    }

    .tooltip-arrow.visible {
        display: block;
        animation: ${fadeIn} 0.2s linear both;
    }
`;