import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { Title } from "../../components/Title";
import { AlertContainer } from "./styles"

interface AlertContextProps {
  setAlertPosition: (type: string) => void;
  openAlert: (type: boolean) => void;
  closeAlert: () => void;
  resetTypeContinue: () => void;
  setTextAlertInterface: (value:AlertTextsProps) => void;
  alert: AlertProps;
}

interface AlertProviderProps {
  children: ReactNode;
}

type ColorBtnProps = "default" | "outline" | "blue" | "danger" | "warning" | "success";

interface AlertTextsProps {
  title: string;
  description: string;
  closeAlertText: string;
  continueAlertText: string;
  closeAlertTypeBtn?: ColorBtnProps;
  continueAlertTypeBtn?: ColorBtnProps;
}

interface AlertProps {
  isVisible: boolean,
  load: boolean,
  type: boolean,
  typeContinue?: string,
}

const initAlert = {
  isVisible: false,
  load: false,
  type: false,
  typeContinue: ""
}

const AlertContext = createContext({} as AlertContextProps);

export const AlertProvider = ({children}: AlertProviderProps) => {
  const [alert, setAlert] = useState<AlertProps>(initAlert);
  const [alertPosition, setAlertPosition] = useState("default");
  const [textAlertInterface, setTextAlertInterface] = useState<AlertTextsProps>({} as AlertTextsProps);

  const closeAlert = useCallback(() => {
      setAlert({isVisible: false, load: false, type: false, typeContinue: ""});
      setAlertPosition("default");
  }, [])

  const openAlert = useCallback((type: boolean) => {
      setAlert({...alert, isVisible: true, type})
  }, [alert])

  const buttonContinue = useCallback((valor: string) => {
      setAlert({...alert, isVisible: true, load: true, typeContinue: valor})
  }, [alert])

  const resetTypeContinue = () => {
    setAlert({...alert, typeContinue: ""})
  }

  useEffect(() => {
    
  }, [alert])

  return(
    <AlertContext.Provider value={{
      setTextAlertInterface,
      setAlertPosition,
      openAlert,
      closeAlert,
      resetTypeContinue,
      alert
    }}>
      {alert.isVisible ? 
        <AlertContainer>
          {alertPosition === "center" ? (
            <div className="alert-popup" id="center">
              <div className="alert-popup-mensage">
                <Title 
                  elementHTML="h3" 
                  text={textAlertInterface.title} 
                  className="subtitle-small" 
                />
                <Title 
                  elementHTML="p" 
                  text={textAlertInterface.description}
                  className="paragraph-small" 
                />
              </div>
              <div className="alert-popup-btn">
                <Button
                  label={textAlertInterface.closeAlertText}
                  typeBtn={textAlertInterface.closeAlertTypeBtn || "outline"}
                  onClick={closeAlert}
                />
                
                <Button 
                  label={textAlertInterface.continueAlertText} 
                  typeBtn={textAlertInterface.continueAlertTypeBtn || "blue"} 
                  onClick={() => buttonContinue("save")}
                  load={alert.load}
                />
              </div>
            </div>
          ) : (
            <div className="alert-popup">
              <div className="alert-popup-mensage">
                {alert.type ? 
                  <Title elementHTML="h3" text="Salvar alterações?" className="subtitle-small" /> : 
                  <Title elementHTML="h3" text="Descartar alterações?" className="subtitle-small" />
                }
              </div>

              <div className="alert-popup-btn">
                <Button
                  label="Voltar"
                  typeBtn="outline"
                  onClick={closeAlert}
                />
                {alert.type ?
                  <Button label="Salvar" typeBtn="blue" onClick={() => buttonContinue("save")} load={alert.load} /> :
                  <Button label="Descartar" typeBtn="danger" onClick={() => buttonContinue("discard")} load={alert.load} />
                }
              </div>
            </div>
          )}
        </AlertContainer> : null
      }
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext);