import { Button } from "components/Button";
import { Card } from "components/Card";
import { InputFormField } from "components/InputFormField";
import { Select, SelectChangeEvent } from "components/Select";
import { ChangeEvent } from "react";
import { iconPlus, iconPlusWhite, iconWhiteTrash } from "../../../assets/icons"
import { CardContent, Col, Col2, Grid, Row } from "./styles";

interface UserFormProps {
  inputsValues: any,
  companyOptions?: any[],
  applicationOptions?: any[],
  roleOptions?: any[],
  inputHandler: (e: ChangeEvent<HTMLInputElement>) => void,
  companyHandler: (e: SelectChangeEvent) => void,
  permissionHandler: (e: SelectChangeEvent) => void,
  addPermission: () => void,
  removePermission: (id: string) => void,
  editable?: boolean,
}

export const UserForm:React.FC<UserFormProps> = ({
  inputsValues,
  companyOptions,
  applicationOptions,
  roleOptions,
  inputHandler,
  companyHandler,
  permissionHandler,
  addPermission,
  removePermission,
  editable = true,
}) => {
  return (
    <div>
      <Card title="Dados pessoais">
        <CardContent>
          <InputFormField
            name="userName"
            label="Nome completo"
            backGround="white"
            onChange={inputHandler}
            error={inputsValues.userName.error}
            mensageError="Nome inválido"
            value={inputsValues.userName.value}
            disabled={!editable}
          />
          <InputFormField
            name="email"
            label="Email"
            backGround="white"
            onChange={inputHandler}
            error={inputsValues.email.error}
            mensageError="Email inválido"
            value={inputsValues.email.value}
            disabled={!editable}
          />
          <Select
            name="Empresa"
            optionsDescription={companyOptions?.map((option) => option.description) ?? []}
            optionsValue={companyOptions?.map((option) => option.value) ?? []}
            onChange={companyHandler}
            value={inputsValues.tGroupCompany.description}
            readOnly={!editable}
          />
        </CardContent>
      </Card>
      <Card 
        title="Permissões"
        hasButton={editable} 
        labelButton="Adicionar permissão"
        iconButton={iconPlus} 
        iconHover={iconPlusWhite}
        onClick={addPermission}
      >
        <Grid>
          {
            Object.keys(inputsValues.permissions).map((key) => {
              let value = inputsValues.permissions[key];
              let applicationDescriptions = applicationOptions?.map((option) => option.description);
              let applicationValues = applicationOptions?.map((option) => option.value);

              return <Row key={key} className="user-permissions">
                <Col>
                  <Select
                    name="Plataforma"
                    dataId={`application:${key}`}
                    optionsDescription={applicationDescriptions ?? []}
                    optionsValue={applicationValues ?? []}
                    block={true}
                    onChange={permissionHandler}
                    value={value.application.description}
                    readOnly={!editable || value.disabled}
                  />
                </Col>
                <Col>
                  <Select
                    name="Role"
                    dataId={`role:${key}`}
                    optionsDescription={roleOptions?.map((option) => option.description) ?? []}
                    optionsValue={roleOptions?.map((option) => option.value) ?? []}
                    block={true}
                    onChange={permissionHandler}
                    value={value.role.description}
                    readOnly={!editable || value.disabled}
                  />
                </Col>
                <Col2 className={"d-flex"}>
                  {
                    editable
                      ? <Button
                          label=""
                          typeBtn="danger"
                          onlyIcon={true}
                          icon={iconWhiteTrash}
                          className="remove-role"
                          onClick={() => removePermission(key)}
                        />
                      : null
                  }
                </Col2>
              </Row>
            })
          }
        </Grid>
      </Card>
    </div>
  )
}