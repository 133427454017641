export const BaseRoutes = {
  login: '/',
  fogotPassword: '/forgot-password',

  home: '/home',
  customerDetails: '/home/customer-details/:id',

  lottery: '/lottery',
  lotteryDetails: '/lottery/lottery-details/:id',
  BallotDetails: '/lottery/ballot-details/:id',
  WinnerDetails: '/lottery/winner-details/:description/:ballotId/:id',
  PrizeDetails: '/lottery/prize-details/:id',
  RegisterNewPlan: '/lottery/register-new-plan',
  RegisterNewBallot: '/lottery/register-new-ballot/:id',
  RegisterNewPrize: '/lottery/register-new-prize',
  EditPlan: '/lottery/edit-plan/:id',

  permissions: '/permissions',
  newUser: '/permissions/new-user',
  newRole: '/permissions/new-role',
  userDetails: '/permissions/user-details/:id',
  roleDetails: '/permissions/role-details/:id'
}