import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Badge } from '../../../components/Badge';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Footer } from '../../../components/Footer';
import { InputFormField } from '../../../components/InputFormField';
import { Loading } from '../../../components/Loading';
import { Title } from '../../../components/Title';
import { PlaceHolderNotExists } from '../../../components/PlaceholderNotExists';

import { useAuth } from '../../../hooks/useAuth';
import { useAlert } from '../../../hooks/useAlert';
import { useToast } from '../../../hooks/useToast';

import { formattingCPF } from '../../../utils/formattingCPF';
import { formattingDate } from '../../../utils/formattingDate';
import { maskCEP, maskPhone } from '../../../utils/masks';
import { isValidatedInput, ValueInputTypes } from "../../../utils/validateInput";
import { checkChange } from '../../../utils/checkChange';
import { Container, CustumerDetailsCard } from './styles';

interface CustomerProps {
  accountTermsOfUse: null | boolean;
  active: boolean;
  cpf: string;
  customerId: string;
  deleted: boolean;
  emailVerified: boolean;
  insertDate: string;
  isConfi: boolean;
  password: string
  phoneVerified: boolean
  phoneNumberVerificationDate: string
  updateChannel: null | boolean
  updateDate: string
  updateUser: null | boolean
  emailVerificationDate: string;
  name: string,
  email: string,
  phoneNumber: string,
  zipcode: string,
  uf: string,
  city: string,
  neighborhood: string,
  address: string,
  addressNumber: string,
  addressComplement: string,
}

interface ParamsProps {
  id: string;
}

interface InputsEncryptedProps {
  email: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
  phoneNumber: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
  cpf: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
}

type FieldDataDecriptedProps = "email" | "cpf" | "phoneNumber";

const initFieldsEncryptedIsChanged = {
  email: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
  phoneNumber: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
  cpf: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
}

const initCustomer = {
  name: {value: '', error: false},
  email: {value: '', error: false},
  phoneNumber: {value: "+0(00)00000-0000", error: false},
  zipcode: {value: "00000-000", error: false},
  uf: {value: '', error: false, disabled: true},
  city: {value: '', error: false, disabled: true},
  neighborhood: {value: '', error: false, disabled: true},
  address: {value: '', error: false},
  addressNumber: {value: '', error: false},
  addressComplement: {value: '', error: false},
}

export const PersonalData: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { setToast } = useToast();
  const { openAlert, closeAlert, alert } = useAlert();
  const { connectAPI, cancelRequest } = useAuth();

  const [ customer, setCustomer ] = useState<CustomerProps>();
  const [ inputsValues, setInputsValues ] = useState(initCustomer);
  const [ changeCustomer, setChangeCustomer ] = useState<CustomerProps>();
  const [ changeInputs, setChangeInputs ] = useState(initCustomer);
  const [ changeFooter, setChangeFooter ] = useState<string[]>([])
  const [ animationInput, setAnimationInput ] = useState(false);
  const [ submitButton, setSubmitButton ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ fieldsEncryptedIsChanged, setFieldsEncryptedIsChanged ] = useState<InputsEncryptedProps>(initFieldsEncryptedIsChanged);
  const [ isLoadingRequestInputEncrypted, setIsLoadingRequestInputEncrypted ] = useState(false);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();

  const debounceFN = (fn: any, value: string) => {
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      fn(value);
    }, 600)

    setDebounce(timer);
  }

  const emptyFields = () => {
    let arrayEmpty = [];
    for (let [key, value] of Object.entries(inputsValues)) {
      if (value.value === "") {
        arrayEmpty.push(changeNameTag(key))
      }
    }
    return arrayEmpty;
  }

  const changeNameTag = (props: string) => (
    {
      "name": "nome",
      "email": "e-mail",
      "phoneNumber": "Telefone",
      "zipcode": "CEP",
      "uf": "Estado",
      "city": "Cidade",
      "neighborhood": "Bairro",
      "address": "Logradouro",
      "addressNumber": "Numero",
      "addressComplement": "Complemento"
    }
  )[props];

  const formHasError = () => {
    for (let [key, value] of Object.entries(inputsValues)) {
      if (value.error) return true;
    }
    return false;
  };

  const onLoadingDataCustomer = async () => {
    setIsLoading(true);

    const response = await connectAPI({
      url:`customers/${id}`,
      type:"get",
      messageError: "Erro ao carregar dados do customer"
    });

    const data = response.connect ? response.data : {};
    const obj = {
      name: { value: data.name, error: false },
      email: { value: data.email, error: false },
      phoneNumber: { value: data.phoneNumber, error: false },
      zipcode: { value: data.zipcode, error: false },
      uf: { value: data.uf, error: false, disabled: data.uf != null},
      city: { value: data.city, error: false, disabled: data.city != null},
      neighborhood: { value: data.neighborhood, error: false, disabled: data.neighborhood != null},
      address: { value: data.address, error: false },
      addressNumber: { value: data.addressNumber, error: false },
      addressComplement: { value: data.addressComplement, error: false },
    };

    setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
      email: {...initFieldsEncryptedIsChanged.email, initialValue: data.email},
      phoneNumber: {...initFieldsEncryptedIsChanged.phoneNumber, initialValue: data.phoneNumber},
      cpf: {...initFieldsEncryptedIsChanged.cpf, initialValue: data.cpf},
    })

    setCustomer(data);
    setChangeCustomer(data);
    setChangeInputs(obj);
    setInputsValues(obj);    
    setIsLoading(false);      
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const targetRequired = event.target.required;

    setInputsValues({
      ...inputsValues,
      [targetName]: targetValue
    })

    verifyChange(targetName, targetValue, targetRequired);
  }

  const verifyChange = (targetName: string, targetValue: string, targetRequired: boolean) => {
    const isValidateInput = isValidatedInput([
      {
        name: targetName,
        valueInput: targetValue,
        validateOptions: [targetName as ValueInputTypes, 'minCharacter'],
        min: targetRequired ? 1 : 0,
      },
    ]);
    const { hasError, validatedObject } = isValidateInput;

    setInputsValues({
      ...inputsValues,
      [targetName]: {
        value: validatedObject[targetName].valueInput,
        error: !validatedObject[targetName].isValidated,
      }
    });

    
    if (targetName === "zipcode") {
      let unmaskedZip = targetValue.trim().replace(/[`_()" "-]/gi, '')
      if (unmaskedZip.length >= 8 && unmaskedZip != customer?.zipcode) {
        handleSearchCEP(unmaskedZip)
      }
    }

    if (targetName === "phoneNumber") {
      let unmaskedPhone = targetValue.trim().replace(/[^0-9]/g, '')
      if (unmaskedPhone.length < 13) {
        setInputsValues({
          ...inputsValues,
          phoneNumber: {
            value: validatedObject[targetName].valueInput,
            error: true
          }
        })
      }
    }
  }

  const handleSearchCEP = async (value: string) => {
    setAnimationInput(true);

    const response = await connectAPI({
      url: `helper/cep/${value}`, 
      type: "get", 
      messageError: "CEP não encontrado"
    });

    const data = (response.connect && response.data.zipcode) ? response.data : false;
    delete data.zone;

    if(data) {
      setCustomer({
        ...customer,
        ...data
      })

      setInputsValues({
        ...inputsValues,
        zipcode: {value: value, error: false},
        address: {value: data.address || '', error: data.address == null},
        uf: {value: data.uf || '', error: data.uf == null, disabled: data.uf != null},
        city: {value: data.city || '', error: data.city == null, disabled: data.city != null},
        neighborhood: {value: data.neighborhood || '', error: data.neighborhood == null, disabled: data.neighborhood != null},
        addressNumber: {value: '' , error: true},
        addressComplement: {value: '' , error: false},
      });

    }else{
      setInputsValues({
        ...inputsValues,
        zipcode: {value: value, error: true},
      })
    } 

    setAnimationInput(false);
  }

  const handleSubmit = async () => {
    if (formHasError()) {
      setToast({
        message: `Favor preencher o(s) campo(s) abaixo:\n ${String(emptyFields()).split(" ")}.`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })

      closeAlert();
      return
    }

    if (!submitButton && customer) {
      const customerId = customer.customerId;
      const body = Object();

      for (let [key, value] of Object.entries(inputsValues)) {
        body[key] = value.value;
      }

      setSubmitButton(true);

      const response = await connectAPI({
        url: `customers/${customerId}`, 
        body, 
        type: "put", 
        messageError: "Ops, ocorreu um erro. Tente novamente.", 
        messageSuccess: "Alterações salvas com sucesso!"

      });

      const data = response.connect;

      if(data){
        setChangeInputs({...inputsValues});
        setChangeFooter([]);
        onLoadingDataCustomer();
      }
      
      setSubmitButton(false);
      closeAlert();
    }
  }

  const hiddenFooter = () => {
    if (customer) {
      setInputsValues({
        ...changeInputs
      })
      setCustomer(changeCustomer)
      setChangeFooter([]);

      closeAlert();
    }
  }

  const loadDataDecripted = async (field: FieldDataDecriptedProps) => {
    if(!isLoadingRequestInputEncrypted){
      if(fieldsEncryptedIsChanged[field].currentStateEncrypted){
        setIsLoadingRequestInputEncrypted(true);
        setFieldsEncryptedIsChanged({
          ...fieldsEncryptedIsChanged,
          [field]: {
            ...fieldsEncryptedIsChanged[field], 
            isLoading: true
          }
        })
  
        const response = await connectAPI({
          url: `/customers/${id}/unmask?field=${field}`,
          type: "get",
          messageError: "Falha ao exibir dado."
        })
  
        if(response.connect){
          setFieldsEncryptedIsChanged({
            ...fieldsEncryptedIsChanged,
            [field]: {
              ...fieldsEncryptedIsChanged[field], 
              currentStateEncrypted: false,
              isLoading: false,
            }
          })
  
          if(field !== "cpf"){
            setChangeInputs({
              ...changeInputs,
              [field]: {...changeInputs[field], value: response.data[field]}
            });
  
            setInputsValues({
              ...inputsValues,
              [field]: {...inputsValues[field], value: response.data[field]}
            })
          }else{
            customer && setCustomer({...customer, cpf: response.data[field]})
          }
        }else{
          setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
            [field]: {
              ...fieldsEncryptedIsChanged[field],
              isLoading: false,
            }
          })
        }
        setIsLoadingRequestInputEncrypted(false);
      }else{
        setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
          [field]: {
            ...fieldsEncryptedIsChanged[field], 
            currentStateEncrypted: true,
            isLoading: false,
          }
        })
  
        if(field !== "cpf"){
          setChangeInputs({
            ...changeInputs,
            [field]: {...changeInputs[field], value: fieldsEncryptedIsChanged[field].initialValue}
          });
          setInputsValues({
            ...inputsValues,
            [field]: {...inputsValues[field], value: fieldsEncryptedIsChanged[field].initialValue}
          })
        }else{
          customer &&  setCustomer({...customer, cpf: fieldsEncryptedIsChanged[field].initialValue})
        }  
      }
    }else{
      setToast({
        message: `Favor aguardar a requisição em execução.`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }
  }

  useEffect(() => {
    console.log(fieldsEncryptedIsChanged)
  }, [fieldsEncryptedIsChanged])

  useEffect(() => {
    if(customer){
      let initialValues: {[key: string]: any} = {};
      let currentValues: {[key: string]: any} = {};
    
      for (let [key, value] of Object.entries(changeInputs)) {
        if (key === 'phoneNumber' || key === 'zipcode') {
          initialValues[key] = value.value.trim().replace(/[^0-9]/g, '')
        } else {
          initialValues[key] = value.value;
        }
      }
  
      for (let [key, value] of Object.entries(inputsValues)) {
        if (key === 'phoneNumber' || key === 'zipcode') {
          currentValues[key] = value.value.trim().replace(/[^0-9]/g, '')
        } else {
          currentValues[key] = value.value;
        }
      }

      const changed = checkChange(currentValues, initialValues);
      setChangeFooter(changed);
      setSubmitButton(formHasError());
    }

  }, [inputsValues]);

  useEffect(() => {
    onLoadingDataCustomer();

    return () => {
      setInputsValues(initCustomer)
      setCustomer({} as CustomerProps)
      cancelRequest.cancel("cancel")
    };
  }, [])

  useEffect(() => {
    alert.typeContinue === "save" && handleSubmit();
    alert.typeContinue === "discard" && hiddenFooter();
  }, [changeFooter, alert])

  if (isLoading) {
    return (
      <Card title="Dados pessoais">
        <Loading />
      </Card>
    )
  }

  if (!isLoading && customer) {
    return (
      <Container className={changeFooter.length > 0 ? 'isActive' : ''}>

        <Card title="Dados pessoais">
          <CustumerDetailsCard>
            <div className="card-body">
              <div className="card-body-wrapper">
                <InputFormField
                  name="name"
                  label="Nome completo"
                  value={inputsValues.name.value}
                  error={inputsValues.name.error}
                  mensageError="Nome inválido"
                  backGround="white"
                  block={true}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />

                <InputFormField
                  name="email"
                  label="E-mail"
                  value={inputsValues.email.value}
                  error={inputsValues.email.error}
                  mensageError="E-mail inválido"
                  backGround="white"
                  block={true}
                  mensagem={customer?.emailVerified ? `Verificado em ${formattingDate(customer?.emailVerificationDate)}` : "Não foi verificado"}
                  marginBotton="0"
                  onChange={onChange}
                  required
                  readOnly={fieldsEncryptedIsChanged.email.currentStateEncrypted}
                  animation={fieldsEncryptedIsChanged.email.isLoading}
                  isEncrypted={true}
                  onClick={() => debounceFN(loadDataDecripted, "email")}
                  currentStateEncrypted={fieldsEncryptedIsChanged.email.currentStateEncrypted}
                />
              </div>

              <div className="card-body-wrapper">
                <div className="card-body-input">
                  <InputFormField
                    name="cpf"
                    label="CPF"
                    value={formattingCPF(customer ? customer.cpf : "")}
                    backGround="white"
                    block={true}
                    marginBotton="0"
                    readOnly
                    onChange={() => { }}
                    animation={fieldsEncryptedIsChanged.cpf.isLoading}
                    isEncrypted={true}
                    onClick={() => debounceFN(loadDataDecripted, "cpf")}
                    currentStateEncrypted={fieldsEncryptedIsChanged.cpf.currentStateEncrypted}
                  />

                  <InputFormField
                    name="phoneNumber"
                    label="Telefone"
                    value={inputsValues.phoneNumber.value}
                    error={inputsValues.phoneNumber.error}
                    mensageError="Telefone inválido"
                    backGround="white"
                    block={true}
                    mask={!fieldsEncryptedIsChanged.phoneNumber.currentStateEncrypted && maskPhone}
                    mensagem={customer?.phoneVerified ? `Verificado em ${formattingDate(customer.phoneNumberVerificationDate)}` : "Não foi verificado"}
                    marginBotton="0"
                    onChange={onChange}
                    required
                    readOnly={fieldsEncryptedIsChanged.phoneNumber.currentStateEncrypted}
                    animation={fieldsEncryptedIsChanged.phoneNumber.isLoading}
                    isEncrypted={true}
                    onClick={() => debounceFN(loadDataDecripted, "phoneNumber")}
                    currentStateEncrypted={fieldsEncryptedIsChanged.phoneNumber.currentStateEncrypted}
                  />
                </div>

                <div className="verify-identity">
                  <h3>Identidade {customer?.isConfi ? "verificada em 26/07/2021" : "não verificada"}</h3>
                  {customer?.isConfi ? <span></span> : null}
                </div>
              </div>

            </div>
          </CustumerDetailsCard>
        </Card>

        <Card title="Endereço">
          <CustumerDetailsCard>
            <div className="card-body">

              <div className="card-body-wrapper">
                <InputFormField
                  name="zipcode"
                  label="CEP"
                  value={inputsValues.zipcode.value}
                  error={inputsValues.zipcode.error}
                  mensageError="CEP não encontrado"
                  typeInput="text"
                  backGround="white"
                  block={true}
                  mask={maskCEP}
                  icon={true}
                  animation={animationInput}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />

                <InputFormField
                  name="uf"
                  label="Estado"
                  value={inputsValues.uf.value}
                  error={inputsValues.uf.error}
                  mensageError="Estado inválido"
                  typeInput="text"
                  backGround="white"
                  block={true}
                  readOnly={inputsValues.uf.disabled}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />

                <InputFormField
                  name="city"
                  label="Cidade"
                  value={inputsValues.city.value}
                  error={inputsValues.city.error}
                  mensageError="Cidade inválida"
                  typeInput="text"
                  backGround="white"
                  block={true}
                  readOnly={inputsValues.city.disabled}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />

                <InputFormField
                  name="neighborhood"
                  label="Bairro"
                  value={inputsValues.neighborhood.value}
                  error={inputsValues.neighborhood.error}
                  mensageError="Bairro inválido"
                  typeInput="text"
                  backGround="white"
                  block={true}
                  readOnly={inputsValues.neighborhood.disabled}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />
              </div>

              <div className="card-body-wrapper">
                <InputFormField
                  name="address"
                  label="Logradouro"
                  value={inputsValues.address.value}
                  error={inputsValues.address.error}
                  mensageError="Logradouro inválido"
                  typeInput="text"
                  backGround="white"
                  block={true}
                  marginBotton="0"
                  onChange={onChange}
                  required
                />

                <div className="card-body-input">
                  <InputFormField
                    name="addressNumber"
                    label="Número"
                    value={inputsValues.addressNumber.value}
                    error={inputsValues.addressNumber.error}
                    mensageError="Número inválido"
                    typeInput="text"
                    backGround="white"
                    block={true}
                    marginBotton="0"
                    onChange={onChange}
                    required
                  />

                  <InputFormField
                    name="addressComplement"
                    label="Complemento"
                    value={inputsValues.addressComplement.value}
                    typeInput="text"
                    backGround="white"
                    block={true}
                    marginBotton="0"
                    onChange={onChange}
                  />
                </div>
              </div>

            </div>
          </CustumerDetailsCard>
        </Card>
        <Footer isVisible={changeFooter.length > 0}>

          <Title elementHTML="strong" text="Campos atualizados:" className="tag" />

          {changeFooter.map((item: string) => (item ? <Badge value={changeNameTag(item) || ""} key={item} /> : null))}
          
          <Button label="Descartar" size="medium" typeBtn="outline" onClick={() => openAlert(false)} />
          <Button label="Atualizar dados" size="medium" typeBtn="blue" disable={submitButton} onClick={() => openAlert(true)} />

        </Footer>
      </Container>
    )
  }

  return (
    <Card title="Dados pessoais">
      <PlaceHolderNotExists title="Erro ao tentar conectar com o servidor" />
    </Card>
  );
}