import { useHistory, useParams } from "react-router";
import { ChangeEvent, useEffect, useState } from 'react'
import { useSession } from 'hooks/useSession'
import { api } from 'services/api'
import { v4 as uuid } from 'uuid';
import { checkChange, checkChangeDeep } from 'utils/checkChange'
import { isValidatedInput, ValueInputTypes } from 'utils/validateInput'
import { SelectChangeEvent } from 'components/Select'
import { useAlert } from 'hooks/useAlert'
import { useToast } from 'hooks/useToast'
import { BaseRoutes } from 'routes/BaseRoutes'

interface ParamsProps {
  id: string;
}

interface PermissionProps {
  roleId: number,
  role?: string,
  applicationId: number,
  application?: string
}

interface UserProps {
  userId: number,
  userName?: string,
  email?: string,
  tGroupCompanyId: string,
  tGroupCompany?: string,
  editable: boolean,
  roles?: PermissionProps[] 
}

interface InputInterface {
  value: string,
  error: boolean,
}

interface SelectInterface {
  value: string,
  description: string,
  error: boolean,
}

interface SelectOption {
  value: string,
  description: string,
}

interface InputsValues {
  userName: InputInterface,
  email: InputInterface,
  tGroupCompany: SelectInterface,
  permissions: {
    [id: string]: {
      role: SelectInterface,
      application: SelectInterface,
      disabled: boolean,
    }
  }
}

interface TgroupCompany {
  tGroupCompanyId: number,
  tGroupCompany?: string,
}

interface Application {
  applicationId: number,
  applicationDescription?: string,
}

interface Role {
  roleId: number,
  roleName?: string,
}

const initInputs:InputsValues = {
  userName: {value: '', error: false},
  email: {value: '', error: false},
  tGroupCompany: {value: '', description: '', error: false},
  permissions: {
    [uuid()]: {
      role: {
        value: '',
        description: '',
        error: false
      },
      application: {
        value: '',
        description: '',
        error: false
      },
      disabled: false,
    },
  }
}

const changeNameTag = (props: string) => (
  {
    "userName": "nome completo",
    "email": "e-mail",
    "tGroupCompany": "empresa",
    "permissions": "permissões"
  }
)[props];

export const useUserDetails = () => {
  const [user, setUser] = useState<UserProps>();
  const [initInputsValues, setInitInputsValues] = useState<InputsValues>(initInputs);
  const [inputsValues, setInputsValues] = useState<InputsValues>(initInputs);
  const [changeFooter, setChangeFooter] = useState<string[]>([])
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<SelectOption[]>();
  const [applicationOptions, setApplicationOptions] = useState<SelectOption[]>();
  const [initApplicationOptions, setInitApplicationOptions] = useState<SelectOption[]>();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>();
  const [reduced, setReduced] = useState(false);
  const session = useSession();
  const history = useHistory();
  const { openAlert, closeAlert, alert } = useAlert();
  const { id } = useParams<ParamsProps>();
  const {setToast} = useToast();

  const push = (route: string): void => {
    history.push(route);
  }

  /* HOOKS */
  useEffect(()=>{
    getUser();
  }, [])

  useEffect(()=>{
    let permissionsAux:{[id: string]: any} = {};
    user?.roles?.forEach((permission) => {
      permissionsAux[uuid()] = {
        role: {
          value: permission.roleId,
          description: permission.role,
          error: false,
        },
        application: {
          value: permission.applicationId,
          description: permission.application,
          error: false,
        },
        disabled: true,
      }
    })
    const inputs:InputsValues = {
      userName: {value: user?.userName ?? '', error: false},
      email: {value: user?.email ?? '', error: false},
      tGroupCompany: {value: user?.tGroupCompanyId ?? '', description: user?.tGroupCompany ?? '', error: false},
      permissions: permissionsAux,
    }
    setInitInputsValues(inputs);
    setInputsValues(inputs);

    if (user?.editable) {
      getSelectOptions();
    }
  }, [user])

  useEffect(() => {
    const changed = checkChangeDeep(inputsValues, initInputsValues);
    const translatedChange = changed.map((change) => {
      return changeNameTag(change) ?? '';
    })
    setChangeFooter(translatedChange)
  }, [inputsValues])

  useEffect(() => {
    alert.typeContinue === "save" && submitAlertHandler();
    alert.typeContinue === "discard" && discartAlertHandler();
  }, [alert])

  useEffect(() => {
    setValidForm(formValid());
  }, [inputsValues]);

  useEffect(() => {
    reduceApplicationOptions();
  }, [inputsValues.permissions]);

  useEffect(() => {
    if (initApplicationOptions) {
      reduceApplicationOptions();
    }
  }, [initApplicationOptions]);

  /* LOCAL METHODS */
  const reduceApplicationOptions = () => {
    let selectedApplications:SelectOption[] = [];
    let auxApplications = [...(initApplicationOptions ?? [])];

    for (let [key, value] of Object.entries(inputsValues.permissions))   {
      let application = value.application;
      if (application.value)
        selectedApplications.push({value: application.value.toString(), description: application.description});
    }

    for (let i = 0; i < selectedApplications.length; ++ i) {
      let application = selectedApplications[i];
      let option = auxApplications.find((a) => a.value === application.value);
      if (option) {
        const index = auxApplications.indexOf(option, 0);
        if (index > -1) {
          auxApplications.splice(index, 1);
        }
      }
    }
    setApplicationOptions(auxApplications);
  }
  const formValid = ():boolean => {
    // essa logica ta horrorosa mas eh o que deu por enquanto
    if (inputsValues.userName.value.length == 0) {
      return false;
    }
    if (
      isValidatedInput([{
        name: 'email',
        valueInput: inputsValues.email.value,
        validateOptions: ['email'],
      }]).hasError
    ) {
      return false;
    }
    if (inputsValues.tGroupCompany.value.length == 0) {
      return false;
    }
    for (let [key, value] of Object.entries(inputsValues.permissions)) {
      if (value.application.value.length == 0 || value.role.value.length == 0 ) {
        return false;
      }
    }
    return true;
  }

  /* EVENT HANDLERS */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const value = event.target.value;

    const { validatedObject, hasError } = isValidatedInput([{
      name: field,
      valueInput: value,
      validateOptions: [field as ValueInputTypes, 'minCharacter'],
      min: 1,
    }])

    setInputsValues({
      ...inputsValues,
      [field]: {
        ...inputsValues[field as keyof typeof inputsValues],
        value: value,
        error: hasError},
    })
  }

  const handleCompanySelectChange = (event: SelectChangeEvent) => {
    const targetId = event.valueId;
    const description = event.optionSelected;

    setInputsValues({
      ...inputsValues,
      tGroupCompany: {
        value: targetId ?? '',
        description: description ?? '',
        error: false
      }
    })
  }

  const handlePermissionSelectChange = (event: SelectChangeEvent) => {
    const [target, index] = event.dataId?.split(':') ?? [];
    let targetId = event.valueId;
    let description = event.optionSelected;
    setInputsValues({
      ...inputsValues,
      permissions: {
        ...inputsValues.permissions,
        [index]: {
          ...inputsValues.permissions[index],
          [target]: {
            ...inputsValues[target as keyof typeof inputsValues],
            value: targetId,
            description: description,
            error: false
          }
        }
      }
    })
  }

  const addPermission = ():void => {
    let newPermission = {
      role: {value: '', description: '', error: false},
      application: {value: '', description: '', error: false},
      disabled: false,
    };

    setInputsValues({
      ...inputsValues,
      permissions: {
        ...inputsValues.permissions,
        [uuid()]: newPermission
      }
    });
  }

  const removePermission = (permissionId: string): void => {
    let auxPermissions = {...inputsValues.permissions};
    delete auxPermissions[permissionId];
    setInputsValues({
      ...inputsValues,
      permissions: auxPermissions,
    });
  }

  const discartAlertHandler = () => {
    setInputsValues(initInputsValues);
    setChangeFooter([]);
    closeAlert();
  }

  const submitAlertHandler = () => {
    updateUser();
  }

  const discardHandler = () => {
    openAlert(false);
  }

  const submitHandler = () => {
    openAlert(true);
  }

  /* API CALLS */
  const getUser = async() => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    api.get(`users/${id}`, config).then((response) => {
      setUser(response.data);
      setHasError(false);
    }).catch((error) => {
      setHasError(true);
    }).finally(() => {
      setLoading(false);
    })
  }

  const updateUser = async() => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    const user = {
      userName: inputsValues.userName.value,
      email: inputsValues.email.value,
      tGroupCompanyId: inputsValues.tGroupCompany.value,
      roles: Object.keys(inputsValues.permissions).map((key) => {
        return {
          roleId: inputsValues.permissions[key].role.value,
          applicationId: inputsValues.permissions[key].application.value,
        }
      })
    }

    setSubmitting(true);
    api.put(`users/${id}`).then((response) => {
      setToast({
        message: `Usuário atualizado com sucesso`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      });
      push(BaseRoutes.permissions)
    }).catch((error) => {
      const errorResponse = error.response.data;
      setToast({
        message: `Erro ${errorResponse.StatusCode}: ${errorResponse.Message}`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }).finally(() => {
      closeAlert();
      setSubmitting(false);
    })

  }

  const getSelectOptions = async () => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    try {
      const response = await Promise.allSettled([
        api.get(`users/tgroup-companies`, config),
        api.get(`users/applications`, config),
        api.get(`users/roles`, config),
      ]);

      if (response[0].status !== "rejected") {
        let body = response[0].value.data;
        setHasError(false);
        setCompanyOptions(
          body.map((item: TgroupCompany):SelectOption => ({
            value: item.tGroupCompanyId.toString(),
            description: item.tGroupCompany ?? 'N/A',
          })),
        );
      } else {
        setHasError(true);
        return;
      }

      if (response[1].status !== "rejected") {
        let body = response[1].value.data;
        let options = body.map((item: Application):SelectOption => ({
          value: item.applicationId.toString(),
          description: item.applicationDescription ?? 'N/A',
        }));
        setHasError(false);
        setInitApplicationOptions(options);
        setApplicationOptions(options);
      } else {
        setHasError(true);
        return;
      }

      if (response[2].status !== "rejected") {
        let body = response[2].value.data;
        setHasError(false);
        setRoleOptions(
          body.map((item: Role):SelectOption => ({
            value: item.roleId.toString(),
            description: item.roleName ?? 'N/A',
          })),
        );
      } else {
        setHasError(true);
        return;
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }
  
  return {
    loading,
    hasError,
    inputsValues,
    companyOptions,
    applicationOptions,
    roleOptions,
    editable: user?.editable,
    changeFooter,
    validForm,
    handleInputChange,
    handleCompanySelectChange,
    handlePermissionSelectChange,
    addPermission,
    removePermission,
    discardHandler,
    submitHandler
  }
}