import React, { ReactNode } from "react"
import { useLocation } from 'react-router-dom'
import { iconLogoWhite } from "../../assets/icons/logo"
import { MenuItemProps } from "../../assets/menu/menuList"
import ItemMenu from "./ItemMenu"

import { MenuContainer, Content } from './styles'

interface MenuProps {
  children: ReactNode;
  links: MenuItemProps[]
}

export const Menu: React.FC<MenuProps> = ({ children, links }) => {
  const { pathname } = useLocation();

  const isCurrentPath = (path: string) => {
    const checkURLCleared = path.split("/")[1];
    const baseURLCleared = pathname.split("/").splice(1)[0]

    return checkURLCleared === baseURLCleared;
  }

  const positionTopMenu = links.filter(link => link.position === "top");
  const positionBottomMenu = links.filter(link => link.position === "bottom");

  return (
    <>
      <MenuContainer >
        <div className="menu-logo">
          {iconLogoWhite}
        </div>

        <nav>
          <ul>
            {positionTopMenu.map((link) => (
              <ItemMenu
                key={link.path}
                name={link.label}
                isActive={isCurrentPath(link.path)}
                urlImg={isCurrentPath(link.path) ? link.iconSelected : link.icon}
                urlImgHover={link.iconSelected}
                path={link.path}
              />
            ))}
          </ul>
          <ul className="nav-ul-divider"/>
          <ul>
            {positionBottomMenu.map((link) => (
              <ItemMenu
                key={link.path}
                name={link.label}
                isActive={isCurrentPath(link.path)}
                urlImg={isCurrentPath(link.path) ? link.iconSelected : link.icon}
                urlImgHover={link.iconSelected}
                path={link.path}
              />
            ))}
          </ul>
        </nav>

      </MenuContainer>
      
      <Content>
        {children}
      </Content>
    </>
  )
};
