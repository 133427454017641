import styled from 'styled-components';
import {
  colors,
  spacings,
  borders,
  fontSizes,
  fontWeights
} from '../../../styles/theme'


export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px;
  left: 88px;
  width: calc(100% - 88px);
  height: 115px;
  border-bottom: 1px solid ${colors.neutral_regular};
  background: ${colors.white};

  .header-text{
    display: flex;
    h1{
      padding-bottom: ${borders.md};
      display: inline;
      border-bottom: ${borders.sm} solid ${colors.primary};
    }
  }
`

export const CardContent = styled.div`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 32px;

  & + div {
    margin-top: ${spacings.lg};
  }

  div:last-child {
    margin-right: 0;
  }

  .sortable{
    display: flex;
    align-items: center;
    
    label{
      display: inline-block;
      margin-left: ${spacings.sl};
    }
  }
`

export const Footer = styled.footer`

  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spacings.md}  ${spacings.lg};
  border-top: ${spacings.xs} solid #${colors.neutral_regular};
  background: ${colors.white};
  transition: all 0.6s linear;

  .btn-tag{
    padding: ${spacings.sl};
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.xs};
    color: ${colors.neutral_darkest};
    background: ${colors.neutral_light};
    border-radius: ${borders.md};
    margin-right: ${spacings.sl};
    margin-left: ${spacings.sl};

    &:last-child{
      margin-right: ${spacings.lg};
    }
  }

  button{
    margin-left: ${spacings.lg};
    min-width: 150px;
  }
`;