import { iconDataNotExists } from "../../assets/icons"
import { BoxNotification } from "./styles"

interface PlaceHoilderNotExistsProps {
  title: string;
}

export const PlaceHolderNotExists : React.FC<PlaceHoilderNotExistsProps> = ({title}) => {
  return (
    <BoxNotification>
        <div>
          {iconDataNotExists}
          <span>{title}</span>
      </div>
    </BoxNotification>
  )
}