import link_user from '../../assets/imgs/img_menu/menu_link_user_selected.svg'
import link_user_not_selected from '../../assets/imgs/img_menu/menu_link_user_not_selected.svg'
import link_lottery from '../../assets/imgs/img_menu/menu_link_lottery_selected.svg'
import link_lottery_not_selected from '../../assets/imgs/img_menu/menu_link_lottery_not_selected.svg'
import link_settings from '../../assets/imgs/img_menu/menu_link_settings_selected.svg'
import link_settings_not_selected from '../../assets/imgs/img_menu/menu_link_settings_not_selected.svg'
import link_account from '../../assets/imgs/img_menu/menu_link_account_selected.svg'
import link_account_not_selected from '../../assets/imgs/img_menu/menu_link_account_not_selected.svg'
import { iconArrowRight } from "../../assets/icons"
import { Button } from 'components/Button'
import { useHistory } from 'react-router'
import { api } from 'services/api'
import { useSession } from 'hooks/useSession'
import { useEffect, useState } from 'react'
import { BaseRoutes } from 'routes/BaseRoutes'
import { Badge } from 'components/Badge'

interface userProps {
  userId: number,
  userName?: string,
  email?: string,
  roleName?: string
}

interface roleProps {
  roleId: number,
  roleName?: string,
  usersEmail: string[],
  actions?: JSX.Element[],
}

interface userRowProps {
  userId: number,
  roleName: string
  userName: string,
  email: string,
  actions: JSX.Element[],
}

interface roleRowProps {
  roleId: number,
  roleName: string,
  usersEmail: React.FC[],
  actions: JSX.Element[],
}

const linkMenu = [
  {
    url_img: link_user,
    url_img_hover: link_user_not_selected,
    path: "/home",
    name: "Usuários",
    isActive: false,
  },
  {
    url_img: link_lottery_not_selected,
    url_img_hover: link_lottery,
    path: "/lottery",
    name: "Sorteios",
    isActive: false,
  },
  {
    url_img: link_settings_not_selected,
    url_img_hover: link_settings,
    path: "/permissions",
    name: "Permissões",
    isActive: true,
  },
  {
    url_img: link_account_not_selected,
    url_img_hover: link_account,
    path: "/login",
    name: "Minha conta",
    isActive: false,
  }
];

export const usePermissions = () => {
  const [loading, setLoading] = useState(true);
  const [hasUsersError, setHasUsersError] = useState(false);
  const [hasRolesError, setHasRolesError] = useState(false);
  const [userRows, setUserRows] = useState<userRowProps[]>();
  const [roleRows, setRoleRows] = useState<roleRowProps[]>();
  const history = useHistory();
  const session = useSession();
  var currentPage = 1;

  useEffect(() => {
    getData();
  }, []);

  const rowActionButton = (route: string) => {
    return (
      <Button 
        label="" 
        size="small" 
        icon={iconArrowRight} 
        onlyIcon={true} 
        onClick={() => push(route)} 
      />
    )
  }

  const getData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    try {
      const response = await Promise.allSettled([
        api.get(`users?rowsPerPage=999&pageNumber=${currentPage}`, config),
        api.get('users/roles', config),
      ]);
      if (response[0].status !== "rejected") {
        let body = response[0].value.data;
        setHasUsersError(false);
        setUserRows([
          ...body.map((item: userProps) => ({
            userId: item.userId,
            roleName: item.roleName ?? 'N/A',
            userName: item.userName ?? 'N/A',
            email: item.email ?? 'N/A',
            actions: [rowActionButton(BaseRoutes.userDetails.replaceAll(':id', item.userId.toString()))],
          })),
        ]);
      } else {
        setHasUsersError(true);
      }
      if (response[1].status !== "rejected") {
        setHasRolesError(false);
        setRoleRows([
          ...response[1].value.data.map((item: roleProps) => ({
            roleId: item.roleId,
            roleName: item.roleName,
            usersEmail: item.usersEmail.slice(0, 3).map((email, index) => {
              if (index < 2)
                return <Badge fontSize={14} fontWeight={500} value={email}/>
              else if (item.usersEmail.length > 2)
                return <Badge fontSize={14} fontWeight={500} value={`+${item.usersEmail.length-2}`}/>
            }),
            actions: [rowActionButton(BaseRoutes.roleDetails.replaceAll(':id', item.roleId.toString()))],
          })),
        ]);
      } else {
        setHasRolesError(true);
      }
    } catch (error) {
      setHasUsersError(true);
      setHasRolesError(true);
    } finally {
      setLoading(false);
    }
  }
  

  const push = (route: string): void => {
    history.push(route);
  }

  return {linkMenu, userRows, roleRows, push, loading, hasUsersError, hasRolesError};
}