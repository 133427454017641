import { createGlobalStyle } from 'styled-components';
import {
    colors,
    fontSizes,
    fontWeights
} from '../../styles/theme'

const baseTitle = `
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  color: ${colors.neutral_darkest}; 
  margin: 0px;
`

export const GlobalStyle = createGlobalStyle`
  .title{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.xx};
  }
  
  .subtitle{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.xl};
  }

  .subtitle-medium{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.lg};
  }

  .subtitle-small{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.md};
  }

  .btn-text{
      ${baseTitle}
      font-weight: ${fontWeights.w700}; 
      font-size: ${fontSizes.md};
  }

  .hiperlink{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.md};
  }

  .paragraph{
      ${baseTitle}
      font-weight: ${fontWeights.w500};
      font-size: ${fontSizes.ml};
  }

  .overline{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sl};
  }

  .paragraph-small{
      ${baseTitle}
      font-weight: ${fontWeights.w500};
      font-size: ${fontSizes.sm};
  }

  .tag{
      ${baseTitle}
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.xs};
  }

  @media (max-width: 425px) {
    .title{
      font-size: ${fontSizes.xl};
    }
  
    .subtitle{
      font-size: ${fontSizes.lg};
    }

    .subtitle-medium{
      font-size: ${fontSizes.ls}; 
    }

    .subtitle-small{
      font-size: ${fontSizes.md};
    }

    .btn-text{
      font-size: ${fontSizes.sm};
    }

    .hiperlink{
      font-size: ${fontSizes.sm};
    }

    .paragraph{
      font-size: ${fontSizes.sm};
    }

    .overline{
      font-size: ${fontSizes.sl};
    }

    .paragraph-small{
      font-size: ${fontSizes.sl};
    }

    .tag{
      font-size: ${fontSizes.xs};
    }
  }   
`