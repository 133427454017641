import React, { useState } from 'react';
import { useEffect } from 'react';

import { RouteLink } from '../../components/Link';
import { SelectTableFilter } from '../../components/SelectTableFilter';
import { useTable } from '../../hooks/useTable';
import { CheckBox } from '../../components/CheckBox'
import { 
  TableFilters, 
  TableCards, 
  Container,
  ContainerTable, 
  TableContent, 
  TablePagination, 
  ContainerLoading,
  TableSearchUsers 
} from './styles';
import { Header } from '../Header';
import { InputFormField } from '../InputFormField';
import { Button } from '../Button';
import { formattingDate } from '../../utils/formattingDate';
import { useToast } from '../../hooks/useToast';
import { iconArrowTop, iconX } from '../../assets/icons';
import { PlaceHolderNotExists } from '../PlaceholderNotExists';
import { Loading } from '../Loading';
import { formattingOnlyNumber } from '../../utils/formattingOnlyNumber';
import { useAuth } from '../../hooks/useAuth';

interface TableProps {
  usersTotal: number,
  isConfiTotal: number,
  active30DaysTotal: number,
  customerId: string,
  name: string,
  cpf: string,
  phoneNumber: string,
  email: string,
  lastTimeLogged: string | null,
  isConfi: boolean,
  isSelected?: boolean
}

interface CardsFilter {
  name: string,
  value: string
}

interface TableHeadProps {
  tableHead: string[],
  tableBody: TableProps[],
  cardFilter?: CardsFilter[] | null,
}

const svg = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.0855103" width="16.0127" height="16" rx="4" fill="#F8F8F9" />
  <path d="M9.24643 5L13.0956 5" stroke="#3A3956" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M3.58829 10.1923L4.94793 11.551C5.02602 11.629 5.15256 11.629 5.23066 11.551L6.59066 10.1923M5.08947 11.1923V5.69226" stroke="#3A3956" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M9.24643 8.46155L11.0941 8.46155" stroke="#C2C2CD" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M9.24643 11.9231L10.0933 11.9231" stroke="#E0E3EB" strokeWidth="1.5" strokeLinecap="round" />
</svg>

const svgArrowChevron = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 6L5.7035 1.59811C5.83111 1.4673 6.0638 1.4673 6.19141 1.59811L10.5 6" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

const svgArow = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.2948 16L11.0597 16" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M16.0516 20L20.9348 16.2636C21.0799 16.1501 21.0799 15.9433 20.9348 15.8299L16.0516 12" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>


export const Table: React.FC<TableHeadProps> = ({ tableHead, tableBody, cardFilter = null }) => {
  const {
    paginate,clearFilters,
    calculatePages,
    totalPages,
    selectCurrentPage,
    maxPageNumberLimit,
    minPageNumberLimit,
    handleInitbtn,
    handleNextbtn,
    handleLastbtn,
    handlePrevbtn,
    currentPage,
    customer,
    daysToFilter,
    cityToFilter,
    selectedUsers,
    userType,
    filterCustomersTable,
    requestWithoutFilter,
    nameToSearch, 
    changeNameToSearch,
    setDaysToFilter,
    setCityToFilter,
    setUserType,
    showResultsForName,
    setShowResultsForName,
    isLoadingContent,
    hiddenTable,
  } = useTable();

  const [head, setHead] = useState(tableHead);
  const [bodyFilter, setBodyFilter] = useState(tableBody);
  const [cityResponse, setCityResponse] = useState<string[]>([]);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();
  const {setToast} = useToast();
  const {connectAPI} = useAuth();

  const debounceFN = (fn: any, value?: string) => {   
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      value ? fn(value) : fn();
    }, 700)

    setDebounce(timer);
  }

  const renderPageNumbers = totalPages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <button
          type="button"
          className={`button-paginate ${currentPage === number ? 'isActive' : ''}`}
          key={number}
          onClick={(event: any) => debounceFN(selectCurrentPage, event)}
          value={number}
        >
          {number}
        </button>
      )
    } else {
      return null;
    }
  });

  const onChangeLocale = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const {data} = await connectAPI({
        url: `https://apibackofficeqa.compreconfie.com.br/api/v1/helper/city?keyWorld=${event.target.value}`,
        config: {},
        type: "get"
      });
      setCityResponse(data);
    } catch (error) {
      setToast({
        message: "Localidade não encontrada!",
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }
  }

  const onChangeDaysLogged = () => {
    setDaysToFilter("");
  }

  const onChangeVerify = () => {
    setUserType("")
  }

  const handleCleanFilterName = () => {
    changeNameToSearch("");
    filterCustomersTable("");
  }

  useEffect(() => {
    return () => {
      clearFilters()
    }
  }, [])

  useEffect(() => {}, [showResultsForName, selectedUsers])

  // Alteração de página quando clicado no botao de proxima página
  useEffect(() => {
    setBodyFilter(customer || []);
  }, [customer, paginate, currentPage, daysToFilter, cityToFilter, userType]);

  // Alterar quantidade de resultados vai ser exibido por página
  useEffect(() => {
    const numberTotalCustomers = cardFilter?.filter(card => card.name === "Total usuários").shift();
    calculatePages(Number(numberTotalCustomers?.value));
    setBodyFilter(tableBody.slice(0, paginate));
  }, []);

  useEffect(() => {
    nameToSearch === "" && setShowResultsForName(false);
  }, [daysToFilter, cityToFilter, userType, customer, currentPage, nameToSearch])

  return (
    <Container>
      <Header>
        <TableSearchUsers className="table-search-user">
          <InputFormField 
            label="Buscar Usuários" 
            autoFocus={true} 
            name="search" 
            marginBotton={"0"} 
            backGround={"white"} 
            block={true} 
            value={nameToSearch}
            onChange={(event) => changeNameToSearch(String(event.target.value))} />

          <Button 
            label="Buscar" 
            typeBtn="blue"
            block={false} 
            disabled={!nameToSearch}
            onClick={() => filterCustomersTable()}
          />
        </TableSearchUsers>
        
      </Header>

      <TableFilters>
        <div>
          <strong>filtrar por:</strong>

          <SelectTableFilter 
            name="Último login" 
            optionsValue={["7 dias", "30 dias", "90 dias"]} 
            filter="days" 
            onChange={(event: any) => debounceFN(onChangeDaysLogged, event)}
          >
            <button
              onClick={() => setDaysToFilter("")}
            >
              {(daysToFilter) ? (
                iconX
              ) : ( 
                iconArrowTop
              )}
            </button>
          </SelectTableFilter>

          <SelectTableFilter 
            name="Localidade" 
            minWidthOptions={150} 
            search={true} 
            optionsValue={cityResponse} 
            filter="city" 
            onChange={(event: any) => debounceFN(onChangeLocale, event)} 
          >
            <button
              onClick={() => setCityToFilter("")}
            >
              {(cityToFilter) ? (
                  iconX
                ) : ( 
                  iconArrowTop
              )}
            </button>
          </SelectTableFilter>
          
          <SelectTableFilter 
            name="Usuários verificados" 
            optionsValue={["Não verificados", "Verificados"]} 
            filter="userType" 
            onChange={(event: any) => debounceFN(onChangeVerify, event)}
          >
            <button
              onClick={() => setUserType("")}
            >
              {(userType) ? (
                  iconX
                ) : ( 
                  iconArrowTop
              )}
            </button>
          </SelectTableFilter>
        
          {showResultsForName && (
            <>
              <strong className="data-result">resultados para:</strong>
    
              <Button
                onClick={() => handleCleanFilterName()}
                size="small"
                typeBtn="outline"
                label={nameToSearch}
                icon={iconX}
              />
            </>
          )}
                  
        </div>
      </TableFilters>

      {cardFilter ?
        <TableCards>
          {cardFilter.map(card => (
            <div
              className="card"
              key={card.name}
            >
              <span>{card.name}</span>
              <h2>{formattingOnlyNumber(card.value)}</h2>
            </div>
          ))}

        </TableCards> : null
      }
      {hiddenTable ? <PlaceHolderNotExists title="Utilize um filtro para exibir os resultados"/>
       : (
        <>
          <ContainerTable>
              {!isLoadingContent && bodyFilter.length ? (
                <TableContent>
                  <thead>
                    <tr>
                      {head
                      .map(headItem => (
                        <th key={headItem}>
                          {headItem === "Nome" ?
                            <button type="button" onClick={() => { }}>{headItem} {svg} </button> :
                            <button type="button" onClick={() => { }}>{headItem} </button>
                          }
                        </th>
                      ))}
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bodyFilter
                      .map(bodyItem => (
                        <tr key={bodyItem.customerId}>
                          <td>{bodyItem.name}</td>
                          <td>
                            <CheckBox
                              label={bodyItem.isConfi ? "Sim" : "Não"}
                              name="isConfi"
                              value=""
                              readOnly={true}
                              checked={true}
                              checkedError={!bodyItem.isConfi}
                            />
                          </td>
                          <td>{bodyItem.cpf}</td>
                          <td>{bodyItem.lastTimeLogged ? formattingDate(bodyItem.lastTimeLogged, true) : "Sem histórico"}</td>
                          <td>{bodyItem.email}</td>
                          <td>
                            <RouteLink text="" icon={false} route={`/home/customer-details/${bodyItem.customerId}`}>
                              {svgArow}
                            </RouteLink>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                  </tfoot>
                </TableContent>
              ) : (
                customer?.length ? (
                  <Loading />
                ) : (
                  <ContainerLoading>
                    <PlaceHolderNotExists title="Não encontramos usuários para os filtros aplicados"/>
                    <Button 
                      label="Limpar busca" 
                      typeBtn="blue"
                      block={false} 
                      onClick={() => requestWithoutFilter()}
                    />
                  </ContainerLoading>
                )
              )}
          </ContainerTable>
          {customer?.length ? (
            <TablePagination>
    
              <div className="total-itens">
                <strong>Exibindo:</strong>
                <SelectTableFilter name="10 itens" appearWhere={"bottom"} optionsValue={["10 itens", "30 itens", "90 itens"]} filter="itensPerPage" >
                  <button>{iconArrowTop}</button>
                </SelectTableFilter>
              </div>
    
              <div className="paginate">
                
                {currentPage > 5 && (
                  <>
                    <button 
                      type="button"
                      className={`button-paginate ${currentPage === 1 ? 'isActive' : ''}`}
                      onClick={() =>debounceFN(handleInitbtn)}
                      disabled={currentPage === 1}
                      value={1}
                    >
                      Primeira
                    </button>
    
                    <span className="paginate-dot">
                      <strong>.</strong>
                      <strong>.</strong>
                      <strong>.</strong>
                    </span>
                  </>
                )}
    
                {currentPage !== 1 && (
                  <button
                    type="button"
                    className="button-paginate arrow-left"
                    onClick={() =>debounceFN(handlePrevbtn)}
                    style={currentPage === totalPages[0] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                    disabled={currentPage === totalPages[0] ? true : false}
                  >
                    {svgArrowChevron}
                  </button>
                )}
    
                {renderPageNumbers}
    
                {currentPage !== totalPages[totalPages.length-1] && (
                    <button
                      type="button"
                      className="button-paginate arrow-right"
                      onClick={() =>debounceFN(handleNextbtn)}
                      style={currentPage === totalPages[totalPages.length - 1] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentPage === totalPages[totalPages.length - 1] ? true : false}
                    >
                      {svgArrowChevron}
                    </button>
                )}
    
                {currentPage < (totalPages[totalPages.length - 1] - 5) && (
                  <>
                    <span className="paginate-dot">
                      <strong>.</strong>
                      <strong>.</strong>
                      <strong>.</strong>
                    </span>
    
                    <button 
                      type="button"
                      className={`button-paginate ${currentPage === totalPages[totalPages.length-1] ? 'isActive' : ''}`}
                      onClick={() =>debounceFN(handleLastbtn)}
                      style={currentPage === totalPages[totalPages.length - 1] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentPage === totalPages[totalPages.length - 1] ? true : false}
                      value={totalPages[totalPages.length-1]}
                    >
                      Última
                    </button>
                  </>
                )}
                
              </div>
    
            </TablePagination>
          ) : ""}
        </>
      )}

    </Container>
  )

}