import React, { useEffect } from 'react';
import { Table } from '../../components/Table';
import { Loading } from '../../components/Loading';
import { Header } from '../../components/Header';
import { useTable } from '../../hooks/useTable';
import { useAuth } from '../../hooks/useAuth';
import { Container } from './styles';
import { menuListItems } from '../../assets/menu/menuList';
import { Menu } from '../../components/Menu';

import link_user from '../../assets/imgs/img_menu/menu_link_user_selected.svg'
import link_user_not_selected from '../../assets/imgs/img_menu/menu_link_user_not_selected.svg'
import link_lottery from '../../assets/imgs/img_menu/menu_link_lottery_selected.svg'
import link_lottery_not_selected from '../../assets/imgs/img_menu/menu_link_lottery_not_selected.svg'
import link_settings from '../../assets/imgs/img_menu/menu_link_settings_selected.svg'
import link_settings_not_selected from '../../assets/imgs/img_menu/menu_link_settings_not_selected.svg'
import link_account from '../../assets/imgs/img_menu/menu_link_account_selected.svg'
import link_account_not_selected from '../../assets/imgs/img_menu/menu_link_account_not_selected.svg'

const linkMenu = [
  {
    url_img: link_user,
    url_img_hover: link_user_not_selected,
    path: "/home",
    name: "Usuários",
    isActive: true,
  },
  {
    url_img: link_lottery_not_selected,
    url_img_hover: link_lottery,
    path: "/lottery",
    name: "Sorteios",
    isActive: false,
  },
  {
    url_img: link_settings_not_selected,
    url_img_hover: link_settings,
    path: "/permissions",
    name: "Permissões",
    isActive: false,
  },
  {
    url_img: link_account_not_selected,
    url_img_hover: link_account,
    path: "/login",
    name: "Minha conta",
    isActive: false,
  }
];

const tableHead = ["Nome", "Id. verificada", "CPF", "Utimo login", "E-mail"];

export const Home: React.FC = () => {
  const {
    paginate,
    currentPage,
    daysToFilter,
    cityToFilter,
    handleSubmit,
    customer,
    cardsTable,
    userType, 
    isLoadingContent,
    setNameToSearch
  } = useTable(); 
  const { cancelRequest } = useAuth();
  
  useEffect(() => {
    setNameToSearch("")

    return () => {
      cancelRequest.cancel("cancel")
    };
  }, [])

  useEffect(() => {
    handleSubmit();   
  }, [paginate, daysToFilter, cityToFilter, currentPage, userType]);

  useEffect(() => {}, [isLoadingContent])

  return (
    <Menu links={menuListItems}>
      <Container >
        {customer ?
          <Table 
            tableHead={tableHead} 
            tableBody={customer} 
            cardFilter={cardsTable} 
          />
          : 
          <>
            <Header/>
            <Loading />
          </> 
        }
      </Container>
    </Menu>
  )
}