
import { ReactNode } from 'react'
import { Button } from '../Button'
import { Title } from '../Title'
import { CardContainer } from './styles'

type ColorBtnProps = "default" | "outline" | "blue" | "danger" | "warning" | "success";

interface CardProps {
    title: string;
    labelButton?: string;
    iconButton?: any;
    iconHover?: any;
    children: ReactNode;
    hasButton?: boolean;
    hasBorder?: boolean;
    onClick?: any;
    typeBtn?: ColorBtnProps;
}

export const Card: React.FC<CardProps> = ({ title, typeBtn = "outline", hasButton = false, hasBorder = false, labelButton = "Ver mais", iconButton, children, onClick, iconHover }) => {
    return (
        <CardContainer hasBorder={hasBorder}>
            <div className="card-header">
                <Title 
                    text={title} 
                    className="subtitle-small" 
                    elementHTML="h2" 
                />
                {hasButton && <Button 
                    label={labelButton} 
                    typeBtn={typeBtn} 
                    iconSide="left" 
                    size="small" 
                    icon={iconButton} 
                    iconHover={iconHover} 
                    onClick={onClick}
                />}
            </div>
            <div className="card-content">
                {children}
            </div>
        </CardContainer>
    )
}