import { Menu } from '../../components/Menu';
import { usePermissions } from "./usePermissions"
import { Header } from 'components/Header';
import { Card } from 'components/Card';
import { Title } from 'components/Title';
import { TableSimple } from 'components/TableSimple';
import { iconPlus, iconPlusWhite } from "../../assets/icons"
import { BaseRoutes } from 'routes/BaseRoutes';
import { Loading } from 'components/Loading';
import { PlaceHolderNotExists } from 'components/PlaceholderNotExists';
import { menuListItems } from '../../assets/menu/menuList';

export const Permissions: React.FC = () => {
  const {linkMenu, userRows, roleRows, push, loading, hasUsersError, hasRolesError} = usePermissions();
  return (
    <Menu links={menuListItems}>
      <Header>
        <Title text="Permissões" elementHTML="h1" className="subtitle-medium" />
      </Header>
      {
        loading
          ? <Loading/>
          : <div>
              {hasUsersError
                ? <PlaceHolderNotExists title="Falha ao buscar permissões."/>
                : <Card title="Usuários cadastrados" hasButton={true} labelButton="Cadastrar novo usuário" iconButton={iconPlus} iconHover={iconPlusWhite} onClick={() => push(BaseRoutes.newUser)}>
                    <TableSimple
                      headerTable={["Id", "Role", "Nome", "E-mail", ""]}
                      bodyTable={userRows ?? []}
                      listRowsPerPage={["10 itens"]}
                      quantityButtonsOnPaginate={10}
                    >
                    </TableSimple>
                  </Card>}
              {hasRolesError
                ? null
                : <Card title="Roles" hasButton={true} labelButton="Cadastrar nova role" iconButton={iconPlus} iconHover={iconPlusWhite} onClick={() => push(BaseRoutes.newRole)}>
                    <TableSimple
                      headerTable={["Id", "Role", "Usuários", ""]}
                      bodyTable={roleRows ?? []}
                      listRowsPerPage={["10 itens"]}
                      quantityButtonsOnPaginate={10}
                    >
                    </TableSimple>
                  </Card>}
            </div>
      }   
    </Menu>
  );
}