export const iconLogoWhite = <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5867 0C11.9378 0 6.54688 3.51099 6.54688 7.8422C6.54688 12.1734 11.9378 15.6844 18.5867 15.6844C25.2356 15.6844 30.6266 12.1734 30.6266 7.8422C30.6266 3.51099 25.2356 0 18.5867 0ZM22.3852 10.114C22.9142 10.1144 23.4266 9.92981 23.8335 9.59239C23.4165 10.8354 22.5703 11.8898 21.4461 12.5672C20.3219 13.2446 18.993 13.5008 17.697 13.2899C16.4009 13.0791 15.2223 12.4149 14.3717 11.4163C13.5211 10.4176 13.0541 9.14949 13.0541 7.83866C13.0541 6.52783 13.5211 5.25973 14.3717 4.26106C15.2223 3.26239 16.4009 2.59826 17.697 2.38741C18.993 2.17655 20.3219 2.43272 21.4461 3.1101C22.5703 3.78749 23.4165 4.84194 23.8335 6.08493C23.5518 5.85337 23.2184 5.69283 22.8616 5.61683C22.5047 5.54084 22.1347 5.55162 21.7829 5.64827C21.4311 5.74493 21.1077 5.92462 20.84 6.17219C20.5724 6.41977 20.3683 6.72796 20.2449 7.07079C20.1216 7.41362 20.0826 7.78103 20.1314 8.14204C20.1801 8.50304 20.3151 8.84706 20.5249 9.14507C20.7347 9.44308 21.0133 9.68636 21.3371 9.85437C21.6608 10.0224 22.0203 10.1102 22.3852 10.1105V10.114Z" fill="white"/>
<path d="M18.5866 20.7308C15.1913 20.7308 12.0016 19.8516 9.60167 18.2525C9.5211 18.1982 9.45201 18.1287 9.39836 18.0478C9.34472 17.9669 9.30756 17.8762 9.289 17.781C9.27045 17.6858 9.27087 17.5878 9.29023 17.4927C9.3096 17.3977 9.34754 17.3073 9.40187 17.2269C9.45621 17.1465 9.52589 17.0775 9.60693 17.0239C9.68797 16.9704 9.77878 16.9333 9.87418 16.9148C9.96957 16.8962 10.0677 16.8967 10.1629 16.916C10.2582 16.9353 10.3487 16.9732 10.4292 17.0274C12.5844 18.4637 15.4821 19.2544 18.5866 19.2544C21.6912 19.2544 24.5912 18.4684 26.7535 17.0392C26.917 16.9313 27.1168 16.8925 27.3089 16.9316C27.501 16.9707 27.6697 17.0843 27.7779 17.2475C27.8861 17.4108 27.9248 17.6102 27.8857 17.802C27.8466 17.9938 27.7327 18.1622 27.5692 18.2702C25.1657 19.8599 21.9761 20.7308 18.5866 20.7308Z" fill="white"/>
<path d="M5.95135 21.8715C2.51697 21.8715 0 24.4301 0 27.9363C0 31.4426 2.42357 34 5.95135 34C7.90913 34 9.47795 33.1739 10.4947 31.8073C10.4817 31.6893 10.4734 31.622 10.4616 31.5087L9.65647 30.7935L9.33608 30.8277C8.93439 31.3392 8.42016 31.7515 7.83321 32.0327C7.24627 32.3139 6.60235 32.4564 5.95135 32.4493C3.51123 32.4493 1.75561 30.5728 1.75561 27.921C1.75561 25.2987 3.51123 23.3915 5.95135 23.3915C6.60873 23.3848 7.25882 23.5294 7.85115 23.8141C8.44348 24.0988 8.96212 24.5159 9.36682 25.0331L9.68366 25.0709L10.5112 24.3781C10.5254 24.2601 10.5349 24.1917 10.5491 24.076C9.53233 22.7165 7.90676 21.8715 5.95135 21.8715Z" fill="white"/>
<path d="M15.7404 25.5004C13.2861 25.5004 11.4844 27.3002 11.4844 29.749C11.4844 32.1979 13.2719 33.9976 15.7404 33.9976C18.2089 33.9976 20.0142 32.1837 20.0142 29.749C20.0142 27.3143 18.1959 25.5004 15.7404 25.5004ZM15.7404 32.4964C14.2496 32.4964 13.1773 31.3328 13.1773 29.7349C13.1773 28.1369 14.2496 26.9745 15.7404 26.9745C17.2312 26.9745 18.3047 28.1216 18.3047 29.7349C18.3047 31.3481 17.2478 32.4964 15.7404 32.4964Z" fill="white"/>
<path d="M24.9166 25.5359C24.1352 25.5359 23.2721 26.0245 22.8915 26.6417V25.9537L22.6704 25.733H21.4349L21.2139 25.9537V33.5315L21.4349 33.7522H22.6704L22.8915 33.5315V29.6712C22.8915 27.9717 23.6008 26.9745 24.7464 26.9745C25.7099 26.9745 26.2324 27.672 26.2324 28.6645V33.5315L26.4535 33.7522H27.6913L27.9124 33.5315V28.6928C27.91 26.788 26.943 25.5359 24.9166 25.5359Z" fill="white"/>
<path d="M31.9475 25.7495V25.1287C31.9475 24.043 32.632 23.5733 33.6109 23.5733H33.7291L33.9502 23.3538V22.2303L33.7208 22.0107H33.4844C31.542 22.0107 30.2521 23.1744 30.2521 25.0981V25.7495H29.0463L28.8252 25.969V26.9863L29.0463 27.207H30.2486V33.5315L30.4697 33.7522H31.7217L31.9416 33.5315V27.207H35.1868V33.5315L35.4079 33.7522H36.6433L36.8644 33.5315V25.969L36.6433 25.7495H31.9475Z" fill="white"/>
<path d="M36.0265 23.9545C36.2537 23.954 36.4756 23.8863 36.6643 23.76C36.8529 23.6337 36.9999 23.4543 37.0865 23.2447C37.1731 23.035 37.1955 22.8045 37.1509 22.5821C37.1063 22.3597 36.9967 22.1556 36.8359 21.9954C36.6751 21.8352 36.4703 21.7262 36.2475 21.6821C36.0246 21.638 35.7937 21.6609 35.5839 21.7478C35.374 21.8347 35.1947 21.9817 35.0685 22.1703C34.9423 22.3589 34.875 22.5806 34.875 22.8073C34.8742 22.9584 34.9035 23.1081 34.9611 23.2478C35.0187 23.3875 35.1036 23.5144 35.2107 23.6211C35.3178 23.7278 35.4451 23.8122 35.5851 23.8694C35.7252 23.9266 35.8752 23.9556 36.0265 23.9545Z" fill="white"/>
</svg>

export const iconLogoBlue = <svg width="53" height="45" viewBox="0 0 53 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.3072 0C11.9995 0 0.398682 7.56853 0.398682 16.9052C0.398682 26.2418 11.9995 33.8103 26.3072 33.8103C40.6149 33.8103 52.2158 26.2418 52.2158 16.9052C52.2158 7.56853 40.6149 0 26.3072 0Z" fill="#CACEFF"/>
<path d="M26.3071 44.6886C19.0006 44.6886 12.1368 42.7933 6.9724 39.3461C6.62222 39.11 6.38018 38.7444 6.29955 38.3298C6.21892 37.9152 6.30632 37.4855 6.54247 37.1354C6.77862 36.7852 7.14418 36.5432 7.55878 36.4625C7.97338 36.3819 8.40305 36.4693 8.75323 36.7054C13.391 39.8015 19.6265 41.506 26.3071 41.506C32.9878 41.506 39.2283 39.8117 43.8814 36.7309C44.2332 36.4981 44.6632 36.4146 45.0766 36.4988C45.49 36.583 45.853 36.828 46.0858 37.1799C46.3186 37.5317 46.402 37.9617 46.3178 38.3751C46.2336 38.7885 45.9886 39.1515 45.6368 39.3843C40.4647 42.8111 33.6009 44.6886 26.3071 44.6886Z" fill="#5056FF"/>
<path d="M34.4813 21.8025C35.6186 21.8054 36.7211 21.4103 37.5977 20.6856C36.7005 23.3651 34.8795 25.6381 32.4603 27.0984C30.0412 28.5586 27.1815 29.1108 24.3926 28.6562C21.6037 28.2017 19.0673 26.7701 17.237 24.6173C15.4066 22.4645 14.4016 19.7309 14.4016 16.9052C14.4016 14.0795 15.4066 11.3458 17.237 9.19305C19.0673 7.04026 21.6037 5.60862 24.3926 5.15409C27.1815 4.69956 30.0412 5.25177 32.4603 6.71198C34.8795 8.1722 36.7005 10.4452 37.5977 13.1247C36.9916 12.6256 36.2742 12.2795 35.5063 12.1157C34.7383 11.9518 33.9422 11.9751 33.1851 12.1834C32.4281 12.3918 31.7322 12.7791 31.1562 13.3128C30.5802 13.8465 30.1409 14.5109 29.8755 15.2499C29.6101 15.9889 29.5263 16.7809 29.6312 17.5591C29.736 18.3374 30.0265 19.0789 30.478 19.7214C30.9296 20.3638 31.529 20.8882 32.2257 21.2504C32.9224 21.6126 33.696 21.8019 34.4813 21.8025Z" fill="#5056FF"/>
</svg>

export const iconLogoBlueWithName =<svg width="80" height="74" viewBox="0 0 80 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.9962 0C25.6885 0 14.0876 7.55532 14.0876 16.8757C14.0876 26.196 25.6885 33.7513 39.9962 33.7513C54.3039 33.7513 65.9047 26.196 65.9047 16.8757C65.9047 7.55532 54.3039 0 39.9962 0Z" fill="#CACEFF"/>
<path d="M39.9961 44.6106C32.6896 44.6106 25.8258 42.7186 20.6614 39.2774C20.3112 39.0417 20.0691 38.6768 19.9885 38.2629C19.9079 37.849 19.9953 37.4201 20.2314 37.0705C20.4676 36.721 20.8331 36.4794 21.2477 36.3989C21.6623 36.3184 22.092 36.4056 22.4422 36.6413C27.08 39.732 33.3154 41.4336 39.9961 41.4336C46.6768 41.4336 52.9173 39.7422 57.5703 36.6667C57.9222 36.4344 58.3522 36.3511 58.7656 36.4351C59.179 36.5192 59.542 36.7637 59.7748 37.115C60.0075 37.4662 60.091 37.8954 60.0068 38.3081C59.9226 38.7208 59.6776 39.0832 59.3257 39.3155C54.1537 42.7364 47.2899 44.6106 39.9961 44.6106Z" fill="#5056FF"/>
<path d="M48.1702 21.7644C49.3076 21.7673 50.4101 21.3729 51.2867 20.6495C50.3895 23.3243 48.5685 25.5934 46.1493 27.051C43.7301 28.5087 40.8705 29.0599 38.0815 28.6062C35.2926 28.1525 32.7563 26.7233 30.9259 24.5743C29.0956 22.4253 28.0906 19.6964 28.0906 16.8757C28.0906 14.0549 29.0956 11.326 30.9259 9.177C32.7563 7.02797 35.2926 5.59883 38.0815 5.14509C40.8705 4.69135 43.7301 5.2426 46.1493 6.70026C48.5685 8.15793 50.3895 10.427 51.2867 13.1018C50.6805 12.6035 49.9632 12.258 49.1952 12.0945C48.4273 11.931 47.6312 11.9542 46.8741 12.1622C46.117 12.3701 45.4211 12.7568 44.8451 13.2896C44.2691 13.8223 43.8299 14.4855 43.5645 15.2233C43.2991 15.961 43.2153 16.7516 43.3201 17.5285C43.425 18.3053 43.7154 19.0456 44.167 19.6869C44.6186 20.3282 45.2179 20.8517 45.9147 21.2133C46.6114 21.5748 47.385 21.7639 48.1702 21.7644Z" fill="#5056FF"/>
<path d="M12.8067 47.0651C5.42899 47.0651 0 52.571 0 60.1161C0 67.6613 5.21529 73.1646 12.8067 73.1646C17.0197 73.1646 20.3956 71.3868 22.5835 68.446C22.5555 68.192 22.5377 68.0473 22.5123 67.8035L20.7798 66.2645L20.0903 66.3381C19.2259 67.4387 18.1193 68.3259 16.8563 68.931C15.5932 69.5361 14.2076 69.8429 12.8067 69.8275C7.55581 69.8275 3.7779 65.7896 3.7779 60.0831C3.7779 54.4401 7.55581 50.3361 12.8067 50.3361C14.2213 50.3217 15.6203 50.6329 16.8949 51.2455C18.1695 51.8582 19.2856 52.7558 20.1565 53.8687L20.8383 53.95L22.6191 52.4592C22.6497 52.2053 22.67 52.058 22.7005 51.8091C20.5126 48.8835 17.0146 47.0651 12.8067 47.0651Z" fill="#5056FF"/>
<path d="M33.8715 54.8744C28.59 54.8744 24.7129 58.7473 24.7129 64.0169C24.7129 69.2866 28.5595 73.1595 33.8715 73.1595C39.1834 73.1595 43.0682 69.2561 43.0682 64.0169C43.0682 58.7777 39.1554 54.8744 33.8715 54.8744ZM33.8715 69.9291C30.6634 69.9291 28.3559 67.4251 28.3559 63.9865C28.3559 60.5478 30.6634 58.0463 33.8715 58.0463C37.0795 58.0463 39.3895 60.5148 39.3895 63.9865C39.3895 67.4581 37.1151 69.9291 33.8715 69.9291Z" fill="#5056FF"/>
<path d="M53.6184 54.9506C51.9367 54.9506 50.0796 56.002 49.2604 57.3302V55.8496L48.7847 55.3747H46.1261L45.6504 55.8496V72.1564L46.1261 72.6313H48.7847L49.2604 72.1564V63.8494C49.2604 60.1923 50.7868 58.0464 53.252 58.0464C55.3254 58.0464 56.4499 59.5473 56.4499 61.6831V72.1564L56.9256 72.6313H59.5892L60.065 72.1564V61.744C60.0599 57.6451 57.9789 54.9506 53.6184 54.9506Z" fill="#5056FF"/>
<path d="M68.7478 55.4103V54.0745C68.7478 51.738 70.2208 50.7273 72.3273 50.7273H72.5817L73.0574 50.2549V47.8372L72.5817 47.3623H72.0729C67.893 47.3623 65.1175 49.8664 65.1175 54.0059V55.4077H62.5302L62.0544 55.8801V58.0693L62.5302 58.5442H65.1175V72.1538L65.5932 72.6287H68.2874L68.7606 72.1538V58.5467H75.744V72.1564L76.2197 72.6313H78.8782L79.354 72.1564V55.8826L78.8782 55.4103H68.7478Z" fill="#5056FF"/>
<path d="M77.5246 51.5476C78.0134 51.5466 78.491 51.4009 78.897 51.1291C79.303 50.8572 79.6191 50.4714 79.8055 50.0202C79.9919 49.5691 80.0401 49.0729 79.9441 48.5944C79.8481 48.1159 79.6123 47.6765 79.2662 47.3318C78.9202 46.9871 78.4796 46.7525 78 46.6577C77.5205 46.5628 77.0236 46.612 76.572 46.799C76.1205 46.986 75.7345 47.3024 75.4631 47.7082C75.1916 48.114 75.0467 48.5911 75.0467 49.0791C75.045 49.4042 75.1079 49.7263 75.2319 50.0269C75.3559 50.3275 75.5384 50.6005 75.7689 50.8301C75.9994 51.0598 76.2733 51.2414 76.5747 51.3646C76.8761 51.4877 77.1989 51.5499 77.5246 51.5476Z" fill="#5056FF"/>
</svg>

export const iconLogoWhiteWithName = <svg width="80" height="74" viewBox="0 0 80 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.9962 0C25.6885 0 14.0876 7.55531 14.0876 16.8756C14.0876 26.196 25.6885 33.7513 39.9962 33.7513C54.3039 33.7513 65.9047 26.196 65.9047 16.8756C65.9047 7.55531 54.3039 0 39.9962 0ZM48.1702 21.7644C49.3085 21.7651 50.4111 21.368 51.2867 20.6419C50.3894 23.3167 48.5685 25.5857 46.1493 27.0434C43.7301 28.5011 40.8704 29.0523 38.0815 28.5986C35.2926 28.1448 32.7562 26.7157 30.9259 24.5667C29.0955 22.4176 28.0905 19.6888 28.0905 16.868C28.0905 14.0472 29.0955 11.3184 30.9259 9.16937C32.7562 7.02034 35.2926 5.5912 38.0815 5.13746C40.8704 4.68373 43.7301 5.23497 46.1493 6.69263C48.5685 8.1503 50.3894 10.4194 51.2867 13.0942C50.6805 12.5959 49.9632 12.2504 49.1952 12.0869C48.4273 11.9233 47.6312 11.9465 46.8741 12.1545C46.117 12.3625 45.4211 12.7492 44.8451 13.2819C44.2691 13.8147 43.8299 14.4779 43.5645 15.2156C43.299 15.9534 43.2152 16.744 43.3201 17.5208C43.425 18.2977 43.7154 19.038 44.167 19.6793C44.6185 20.3206 45.2179 20.8441 45.9146 21.2056C46.6114 21.5672 47.385 21.7562 48.1702 21.7567V21.7644Z" fill="white"/>
<path d="M39.9961 44.6106C32.6896 44.6106 25.8258 42.7186 20.6614 39.2774C20.488 39.1607 20.3393 39.011 20.2239 38.837C20.1084 38.6629 20.0284 38.4678 19.9885 38.2629C19.9486 38.0579 19.9495 37.8472 19.9912 37.6426C20.0328 37.438 20.1145 37.2436 20.2314 37.0705C20.3483 36.8974 20.4983 36.749 20.6727 36.6338C20.847 36.5185 21.0425 36.4387 21.2478 36.3989C21.453 36.359 21.6642 36.3599 21.8691 36.4015C22.0741 36.4431 22.2688 36.5246 22.4422 36.6413C27.08 39.732 33.3154 41.4336 39.9961 41.4336C46.6767 41.4336 52.9173 39.7422 57.5703 36.6667C57.9222 36.4344 58.3521 36.351 58.7655 36.4351C59.1789 36.5192 59.542 36.7637 59.7747 37.115C60.0075 37.4662 60.091 37.8954 60.0068 38.3081C59.9226 38.7208 59.6776 39.0832 59.3257 39.3155C54.1537 42.7364 47.2899 44.6106 39.9961 44.6106Z" fill="white"/>
<path d="M12.8067 47.0651C5.41627 47.0651 0 52.571 0 60.1161C0 67.6613 5.21529 73.1646 12.8067 73.1646C17.0197 73.1646 20.3956 71.3869 22.5835 68.446C22.5555 68.192 22.5377 68.0473 22.5122 67.8035L20.7798 66.2645L20.0903 66.3381C19.2259 67.4387 18.1193 68.3259 16.8563 68.931C15.5932 69.5361 14.2076 69.8429 12.8067 69.8275C7.55581 69.8275 3.77791 65.7896 3.77791 60.0831C3.77791 54.4401 7.55581 50.3361 12.8067 50.3361C14.2213 50.3217 15.6203 50.6329 16.8949 51.2455C18.1695 51.8582 19.2856 52.7558 20.1565 53.8687L20.8383 53.95L22.6191 52.4592C22.6496 52.2053 22.67 52.058 22.7005 51.8091C20.5126 48.8835 17.0146 47.0651 12.8067 47.0651Z" fill="white"/>
<path d="M33.8714 54.8744C28.59 54.8744 24.7129 58.7473 24.7129 64.0169C24.7129 69.2866 28.5595 73.1595 33.8714 73.1595C39.1834 73.1595 43.0682 69.2561 43.0682 64.0169C43.0682 58.7778 39.1554 54.8744 33.8714 54.8744ZM33.8714 69.9291C30.6634 69.9291 28.356 67.4251 28.356 63.9865C28.356 60.5479 30.6634 58.0463 33.8714 58.0463C37.0795 58.0463 39.3895 60.5148 39.3895 63.9865C39.3895 67.4581 37.1151 69.9291 33.8714 69.9291Z" fill="white"/>
<path d="M53.6183 54.9506C51.9367 54.9506 50.0796 56.002 49.2604 57.3302V55.8496L48.7847 55.3747H46.1261L45.6504 55.8496V72.1564L46.1261 72.6313H48.7847L49.2604 72.1564V63.8494C49.2604 60.1924 50.7868 58.0464 53.252 58.0464C55.3254 58.0464 56.4499 59.5473 56.4499 61.6831V72.1564L56.9256 72.6313H59.5892L60.0649 72.1564V61.7441C60.0599 57.6451 57.9788 54.9506 53.6183 54.9506Z" fill="white"/>
<path d="M68.7476 55.4103V54.0745C68.7476 51.7381 70.2206 50.7273 72.3271 50.7273H72.5815L73.0572 50.2549V47.8372L72.5637 47.3649H72.0549C67.875 47.3649 65.0995 49.8689 65.0995 54.0084V55.4103H62.5045L62.0288 55.8827V58.0718L62.5045 58.5467H65.0918V72.1564L65.5676 72.6313H68.2617L68.7349 72.1564V58.5467H75.7183V72.1564L76.194 72.6313H78.8526L79.3283 72.1564V55.8827L78.8526 55.4103H68.7476Z" fill="white"/>
<path d="M77.5246 51.5476C78.0134 51.5466 78.491 51.4009 78.897 51.1291C79.303 50.8572 79.6191 50.4714 79.8055 50.0202C79.9919 49.5691 80.0401 49.0729 79.9442 48.5944C79.8482 48.1159 79.6123 47.6765 79.2662 47.3318C78.9202 46.9871 78.4796 46.7525 78 46.6577C77.5205 46.5628 77.0236 46.612 76.572 46.799C76.1205 46.986 75.7345 47.3024 75.4631 47.7082C75.1916 48.114 75.0467 48.5911 75.0467 49.0791C75.045 49.4042 75.1079 49.7263 75.2319 50.0269C75.3559 50.3275 75.5385 50.6005 75.769 50.8301C75.9995 51.0598 76.2733 51.2414 76.5747 51.3646C76.8761 51.4877 77.1989 51.5499 77.5246 51.5476Z" fill="white"/>
</svg>

export const iconLogoNameBlue = <svg width="80" height="27" viewBox="0 0 80 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8079 0.455322C5.42951 0.455322 0 5.97135 0 13.5304C0 21.0895 5.21579 26.603 12.8079 26.603C17.0213 26.603 20.3976 24.822 22.5857 21.8757C22.5577 21.6213 22.5399 21.4762 22.5144 21.232L20.7817 19.6902L20.0922 19.7639C19.2278 20.8666 18.1211 21.7554 16.8579 22.3616C15.5947 22.9678 14.209 23.2752 12.8079 23.2598C7.55653 23.2598 3.77826 19.2144 3.77826 13.4974C3.77826 7.84395 7.55653 3.73237 12.8079 3.73237C14.2227 3.71791 15.6218 4.02967 16.8965 4.64345C18.1713 5.25722 19.2875 6.15649 20.1584 7.27147L20.8403 7.35289L22.6213 5.85939C22.6518 5.60496 22.6722 5.4574 22.7027 5.20806C20.5146 2.27703 17.0162 0.455322 12.8079 0.455322Z" fill="#5056FF"/>
<path d="M33.8746 8.27905C28.5926 8.27905 24.7151 12.1591 24.7151 17.4385C24.7151 22.7179 28.5621 26.5979 33.8746 26.5979C39.1871 26.5979 43.0722 22.6874 43.0722 17.4385C43.0722 12.1896 39.1591 8.27905 33.8746 8.27905ZM33.8746 23.3616C30.6662 23.3616 28.3586 20.8529 28.3586 17.408C28.3586 13.963 30.6662 11.4569 33.8746 11.4569C37.0829 11.4569 39.3931 13.9299 39.3931 17.408C39.3931 20.886 37.1186 23.3616 33.8746 23.3616Z" fill="#5056FF"/>
<path d="M53.6234 8.35547C51.9417 8.35547 50.0843 9.4088 49.2651 10.7395V9.25614L48.7893 8.78036H46.1305L45.6547 9.25614V25.593L46.1305 26.0688H48.7893L49.2651 25.593V17.2707C49.2651 13.6069 50.7916 11.457 53.257 11.457C55.3306 11.457 56.4552 12.9606 56.4552 15.1004V25.593L56.931 26.0688H59.5949L60.0707 25.593V15.1614C60.0656 11.055 57.9843 8.35547 53.6234 8.35547Z" fill="#5056FF"/>
<path d="M68.7543 8.81591V7.47761C68.7543 5.13686 70.2274 4.12424 72.3341 4.12424H72.5885L73.0643 3.651V1.22883L72.5885 0.753052H72.0797C67.8994 0.753052 65.1236 3.26173 65.1236 7.40892V8.81336H62.5361L62.0603 9.28659V11.4798L62.5361 11.9556H65.1236V25.5904L65.5994 26.0662H68.2938L68.767 25.5904V11.9581H75.7511V25.593L76.2269 26.0687H78.8857L79.3614 25.593V9.28914L78.8857 8.81591H68.7543Z" fill="#5056FF"/>
<path d="M77.5321 4.94609C78.021 4.94509 78.4986 4.79919 78.9046 4.52684C79.3106 4.25449 79.6268 3.86791 79.8132 3.41593C79.9996 2.96395 80.0479 2.46686 79.9519 1.98747C79.8559 1.50808 79.62 1.0679 79.2739 0.722544C78.9278 0.377191 78.4871 0.142163 78.0076 0.0471514C77.528 -0.0478602 77.031 0.00140235 76.5794 0.18873C76.1278 0.376059 75.7419 0.693042 75.4704 1.09962C75.1988 1.5062 75.0539 1.98414 75.0539 2.47305C75.0522 2.79872 75.1152 3.12148 75.2392 3.42263C75.3632 3.72377 75.5458 3.9973 75.7763 4.22734C76.0068 4.45739 76.2807 4.63938 76.5821 4.76276C76.8835 4.88614 77.2064 4.94846 77.5321 4.94609Z" fill="#5056FF"/>
</svg>



