import axios, { CancelTokenSource } from 'axios';
import { createContext, useState, ReactNode, useContext, useEffect } from 'react';

import { useAuth } from '../useAuth';
import { useSession } from '../useSession';
import { useToast } from '../useToast';



export type UsersType = {
  usersTotal: number,
  isConfiTotal: number,
  active30DaysTotal: number,
  customerId: string,
  name: string,
  cpf: string,
  phoneNumber: string,
  email: string,
  lastTimeLogged: string | null,
  isConfi: boolean,
  isSelected?: boolean,
}

export interface CustomerProps {
  usersTotal: number,
  isConfiTotal: number,
  active30DaysTotal: number,
  customerId: string,
  name: string,
  cpf: string,
  phoneNumber: string,
  email: string,
  lastTimeLogged: string | null,
  isConfi: boolean
};
export interface CardsFilter {
  name: string,
  value: string
}

export const initCardFilter = [
  {
    name: "Total usuários",
    value: "0"
  },
  {
    name: "Usuários verificados",
    value: "0"
  },
  {
    name: "Ativos 30 dias",
    value: "0"
  },
]

type TableContextData = {
  activeCard: CardsFilter;
  cardsTable: CardsFilter[] | undefined;
  cityToFilter: string;
  currentPage: number;
  customer: CustomerProps[] | undefined;
  daysToFilter: string;
  isSearch:boolean;
  isLoadingContent: boolean;
  maxPageNumberLimit: number;
  minPageNumberLimit: number;
  nameToSearch: string;
  paginate: number;
  selectedUsers: UsersType[];
  showResultsForName: boolean;
  totalPages: number[];
  userType: string;
  hiddenTable: boolean, 
  calculatePages: (totalCustomers: number) => void;
  changeNameToSearch: (value: string) => void;
  filterCustomersTable: (name?: string) => void;
  handleInitbtn: () => void;
  handlePrevbtn: () => void;
  handleNextbtn: () => void;
  handleLastbtn: () => void;
  handleSubmit: () => void;
  clearFilters: () => void;
  requestWithoutFilter: () => void;
  selectCurrentPage: (event: any) => void;
  setShowResultsForName: (value: boolean) => void;
  selectOptionsToFilter: (filter: string, value: string) => void;
  selectUsers: (user: UsersType) => void;
  setSelectedUsers: (user: UsersType[]) => void;
  selectActiveCard: (card: CardsFilter) => void;
  setDaysToFilter: (value: string) => void;
  setCityToFilter: (value: string) => void;
  setUserType: (value: string) => void;
  setNameToSearch: (value: string) => void;
}

export const TableContext = createContext({} as TableContextData);

type TableContextProviderProps = {
  children: ReactNode;
}

export function TableContextProvider({ children }: TableContextProviderProps) {
  const [nameToSearch, setNameToSearch] = useState("");
  const [customer, setCustomer] = useState<CustomerProps[]>();
  const [cardsTable, setCardsTable] = useState(initCardFilter);
  const [daysToFilter, setDaysToFilter] = useState("");
  const [cityToFilter, setCityToFilter] = useState("");
  const [userType, setUserType] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<UsersType[]>([]);
  const [activeCard, setActiveCard] = useState<CardsFilter>({ name: "", value: "" });
  const [isSearch, setIsSearch] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isFilteredByHeader, setIsFilteredByHeader] = useState(false);
  const [showResultsForName, setShowResultsForName] = useState(false);
  const {setToast} = useToast();
  const [hiddenTable, setHiddenTable] = useState(true);  
  const [totalPages, setTotalPages] = useState<number[]>([]);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const {connectAPI} = useAuth();
  const { getSession } = useSession();
  
  const filterCustomersTable = async (name?: string) => {        
    setIsLoadingContent(true);
    setShowResultsForName(true);
    setIsSearch(true);
    setHiddenTable(false)

    let isConfi = userType === "Verificados" ? true : userType === "Não verificados" ? false : null;
    let city = cityToFilter.split(',')[0] || "";   
    let uf = cityToFilter.split(',')[1]?.replace(' ', '') || "";
    let loggedDay = Number(daysToFilter.split(' ')[0]) || 0;
    let keyword = name !== undefined ? name : nameToSearch;


    const response = await connectAPI({
      url: `customers?${isConfi !== null ? `isConfi=${isConfi}&` : ''}${city !== null ? `City=${city}&` : ''}${uf !== null ? `UF=${uf}&` : ''}${loggedDay !== null ? `LastLoggedDay=${loggedDay}&` : ''}${keyword !== null ? `KeyWord=${keyword}` : ''}`,
      type: "get",
      messageError: "Não há resultados para o nome informado"
    })

    if(response.connect){
      setCardsTable(initCardFilter);
      if(response.data.properties !== null && response.data.customers!== null){
        setCustomer(response.data.customers);
        setCardsTable(response.data.properties);

        response.data.properties.filter((properti: CardsFilter) => 
          properti.name === "Total usuários" && calculatePages(Number(properti.value)));

        setIsFilteredByHeader(true);
        setIsSearch(true);

      }else{
        setCustomer([])
      }
    }
    setIsLoadingContent(false);
  }

  const handleSubmit = async () => {
    setIsLoadingContent(true);

    const session = getSession();
    const cancelRequestTable = axios.CancelToken.source();
    const config = {
      headers: {Authorization: `Bearer ${session}`},
      cancelToken: cancelRequestTable.token
    };

    let isConfi = userType === "Verificados" ? true : userType === "Não verificados" ? false : null;
    let city = cityToFilter.split(',')[0] || "";   
    let uf = cityToFilter.split(',')[1]?.replace(' ', '') || "";
    let loggedDay = Number(daysToFilter.split(' ')[0]) || 0;
    let keyword = nameToSearch || null;
    
    try {
      if(!isFilteredByHeader){
        const response = await connectAPI({
          url: `customers?${currentPage !== null ? `PageNumber=${currentPage}&`: `PageNumber=${1}&`}${paginate !== null ? `RowsPerPage=${paginate}&` : `RowsPerPage=${10}&`}${isConfi !== null ? `isConfi=${isConfi}&` : ''}${city !== null ? `City=${city}&` : ''}${uf !== null ? `UF=${uf}&` : ''}${loggedDay !== null ? `LastLoggedDay=${loggedDay}&` : ''}${keyword !== null ? `KeyWord=${keyword}` : ''}`, 
          type: "get", 
          config,
          messageError: "Falha ao efetuar listagem"
        });
        
        if(response.data.properties !== null && response.data.customers!== null){
          setCardsTable(response.data.properties);
          setCustomer(response.data.customers);      
          response.data.properties.filter((property: CardsFilter) => 
          property.name === "Total usuários" && calculatePages(Number(property.value)));          
          
        }else{
          setToast({
            message: "Não há resultados para o parâmetro informado!", 
            isActive: true, 
            type: 'error',
            timeToRemoveToast: 3000
          })

          setCustomer([])
          setCardsTable(initCardFilter);
          setIsLoadingContent(false);
        }
        setIsFilteredByHeader(true);
      }else{
        const response = await connectAPI({
          url:`customers?${currentPage !== null ? `PageNumber=${currentPage}&`: `PageNumber=${1}&`}${paginate !== null ? `RowsPerPage=${paginate}&` : `RowsPerPage=${10}&`}${isConfi !== null ? `isConfi=${isConfi}&` : ''}${city !== null ? `City=${city}&` : ''}${uf !== null ? `UF=${uf}&` : ''}${loggedDay !== null ? `LastLoggedDay=${loggedDay}&` : ''}${keyword !== null ? `KeyWord=${keyword}` : ''}`, 
          type: "get",
          config,
          messageError: "Falha ao efetuar listagem"
        });
        
        if(response.data.properties !== null && response.data.customers!== null){
          setCustomer(response.data.customers);
          setCardsTable(response.data.properties);          
      
          response.data.properties.filter((properti: CardsFilter) => 
          properti.name === "Total usuários" && calculatePages(Number(properti.value)));

          setIsFilteredByHeader(true);
          setIsSearch(true);

        }else{

          setToast({
            message: "Não há resultados para o parâmetro informado!", 
            isActive: true, 
            type: 'error',
            timeToRemoveToast: 3000
          })

          setCustomer([])
          setIsLoadingContent(false);
        }
      }
    } catch (error) {
      console.log(error);
    }finally{
      setIsLoadingContent(false);
    }
  }

  const clearFilters = () => {
    changeNameToSearch("");
    setDaysToFilter("");
    setCityToFilter("");
    setUserType("");
  }

  const requestWithoutFilter = async () => {     
    const response = await connectAPI({
      url:`customers?PageNumber=${currentPage}&RowsPerPage=${paginate}`,  
      type: "get",
      messageError: "Falha ao efetuar listagem"
    });

    if(response.connect){
      setCardsTable(response.data.properties);
      setCustomer(response.data.customers);

      response.data.properties.filter((property: CardsFilter) => 
        property.name === "Total usuários" && calculatePages(Number(property.value)));

      clearFilters();
      setIsFilteredByHeader(false);
      setIsSearch(false);
    }
  }

  const selectOptionsToFilter = (filter: string, value: string) => {
    switch (filter) {
      case "days":
        setDaysToFilter(value);
        break;
      case "city":
        setCityToFilter(value);
        break;
      case "userType":
        setUserType(value);
        break;
      case "itensPerPage": {
        setCurrentPage(1);
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
        setPaginate(Number(value.split(" ")?.shift()));
      }
    }
  }

  const selectActiveCard = (card: CardsFilter) => {
    setActiveCard(card);
  }

  const selectUsers = (user: UsersType) => {
    const alreadyFiltered = selectedUsers.some((userFilter: UsersType) => userFilter.customerId === user.customerId);
    if (alreadyFiltered) {
      const differentUsers = selectedUsers.filter((userFilter: UsersType) => userFilter.customerId !== user.customerId);
      setSelectedUsers(differentUsers);
    } else {
      setSelectedUsers([...selectedUsers, { ...user, isSelected: true }]);
    }
  }

  const selectCurrentPage = (event: any) => {
    setCurrentPage(Number(event.target.value));
  }

  const calculatePages = (totalCustomers: number) => {
    const pages = [];
    for (let i = 1; i <= Math.ceil(totalCustomers / paginate); i++) {
      pages.push(i);
    }

    setTotalPages(pages);
  };

  const changeNameToSearch = (value: string) => {
    setNameToSearch(value);
  };

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage-1) === minPageNumberLimit) {
      setMaxPageNumberLimit(minPageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleLastbtn = () => {
    setCurrentPage(totalPages[totalPages.length - 1]);
    
    setMaxPageNumberLimit(totalPages[totalPages.length - 1]);
    setMinPageNumberLimit(totalPages[totalPages.length - 1] - ((totalPages[totalPages.length - 1] % pageNumberLimit)));
  };

  const handleInitbtn = () => {
    setCurrentPage(1);

    if (currentPage > 5) {
      setMaxPageNumberLimit(5);
      setMinPageNumberLimit(0);
    }
  };

  const checkHasFilter = () => {
    ((nameToSearch === "" || !showResultsForName)
    && daysToFilter === "" 
    && cityToFilter === "" 
    && userType === "") ? setHiddenTable(true) : setHiddenTable(false)
  }

  useEffect(() => {
    checkHasFilter();
  }, [nameToSearch, daysToFilter, cityToFilter, userType])

  useEffect(() => {

  }, [customer, showResultsForName]);

  return (
    <TableContext.Provider
      value={{
        clearFilters,
        changeNameToSearch,
        nameToSearch,
        selectOptionsToFilter,
        selectUsers,
        selectActiveCard,
        activeCard,
        paginate,
        daysToFilter,
        cityToFilter,
        calculatePages,
        handleInitbtn,
        handleNextbtn,
        handlePrevbtn,
        handleLastbtn,
        totalPages,
        selectCurrentPage,
        minPageNumberLimit,
        maxPageNumberLimit,
        currentPage,
        handleSubmit,
        cardsTable,
        customer,
        filterCustomersTable,
        selectedUsers,
        userType,
        isSearch,
        isLoadingContent,
        requestWithoutFilter,
        setDaysToFilter,
        setCityToFilter,
        setUserType,
        setNameToSearch,
        showResultsForName,
        setShowResultsForName,
        setSelectedUsers,
        hiddenTable, 
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

export const useTable = () => useContext(TableContext)