import styled, { keyframes } from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontWeights,
  fontSizes
} from '../../styles/theme';

interface BtnProps {
  block: boolean;
  iconSide: string;
  onlyIcon: boolean;
  spaceBetween: boolean;
  typeBtn: "default" | "outline" | "blue" | "danger" | "warning" | "success";
}

const colorsProps = {
  //PRIMARY
  blue: {
    color_bg_disable: `${colors.neutral_medium}`,
    color_bg_initial: `${colors.primary}`,
    color_bg_hover: `${colors.primary_hover}`,
    color_bg_click: `${colors.primary_click}`,
    color_bg_focus: `${colors.primary}`,

    color_font_disable: `${colors.white}`,
    color_font_initial: `${colors.white}`,
    color_font_hover: `${colors.white}`,

    color_border_disable: `${colors.neutral_medium}`,
    color_border_initial: `${colors.primary}`,
    color_border_hover: `${colors.primary_hover}`,
    color_border_click: `${colors.primary_click}`,
    color_border_focus: `${colors.support_one}`, 
  },
  // SECONDARY
  outline: {
    color_bg_disable: `${colors.transparent}`,
    color_bg_initial: `${colors.transparent}`,
    color_bg_hover: `${colors.primary_hover}`,
    color_bg_click: `${colors.primary_click}`,
    color_bg_focus: `${colors.transparent}`,

    color_font_disable: `${colors.neutral_medium}`,
    color_font_initial: `${colors.neutral_darkest}`,
    color_font_hover: `${colors.white}`,

    color_border_disable: `${colors.neutral_medium}`,
    color_border_initial: `${colors.primary}`,
    color_border_hover: `${colors.primary_hover}`,
    color_border_click: `${colors.primary_click}`,
    color_border_focus: `${colors.support_one}`,
  },
  // TERTIARY
  default: {
    color_bg_disable: `${colors.transparent}`,
    color_bg_initial: `${colors.transparent}`,
    color_bg_hover: `${colors.neutral_regular}`,
    color_bg_click: `${colors.neutral_medium}`,
    color_bg_focus: `${colors.transparent}`,

    color_font_disable: `${colors.neutral_darker}`,
    color_font_initial: `${colors.neutral_darkest}`,
    color_font_hover: `${colors.neutral_darkest}`,

    color_border_disable: `${colors.transparent}`,
    color_border_initial: `${colors.transparent}`,
    color_border_hover: `${colors.neutral_regular}`,
    color_border_click: `${colors.neutral_medium}`,
    color_border_focus: `${colors.support_one}`,
  },
  success: {
    color_bg_disable: `${colors.neutral_medium}`,
    color_bg_initial: `${colors.secondary_one}`,
    color_bg_hover: `${colors.secondary_one_hover}`,
    color_bg_click: `${colors.secondary_one_click}`,
    color_bg_focus: `${colors.secondary_one}`,

    color_font_disable: `${colors.white}`,
    color_font_initial: `${colors.white}`,
    color_font_hover: `${colors.white}`,

    color_border_disable: `${colors.neutral_medium}`,
    color_border_initial: `${colors.secondary_one}`,
    color_border_hover: `${colors.secondary_one_hover}`,
    color_border_click: `${colors.secondary_one_click}`,
    color_border_focus: `${colors.support_two}`,
  },
  warning: {
    color_bg_disable: `${colors.neutral_medium}`,
    color_bg_initial: `${colors.secondary_three}`,
    color_bg_hover: `${colors.secondary_three_hover}`,
    color_bg_click: `${colors.secondary_three_click}`,
    color_bg_focus: `${colors.secondary_three}`,

    color_font_disable: `${colors.white}`,
    color_font_initial: `${colors.white}`,
    color_font_hover: `${colors.white}`,

    color_border_disable: `${colors.neutral_medium}`,
    color_border_initial: `${colors.secondary_three}`,
    color_border_hover: `${colors.secondary_three_hover}`,
    color_border_click: `${colors.secondary_three_click}`,
    color_border_focus: `${colors.support_three}`,
  },
  danger: {
    color_bg_disable: `${colors.neutral_medium}`,
    color_bg_initial: `${colors.secondary_four}`,
    color_bg_hover: `${colors.secondary_four_hover}`,
    color_bg_click: `${colors.secondary_four_click}`,
    color_bg_focus: `${colors.secondary_four}`,

    color_font_disable: `${colors.white}`,
    color_font_initial: `${colors.white}`,
    color_font_hover: `${colors.white}`,

    color_border_disable: `${colors.neutral_medium}`,
    color_border_initial: `${colors.secondary_four}`,
    color_border_hover: `${colors.secondary_four_hover}`,
    color_border_click: `${colors.secondary_four_click}`,
    color_border_focus: `${colors.support_four}`,
  }
}

const loading = keyframes`  
  0%{
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 5px);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0px);
  }
`;

export const BtnDefault = styled.button<BtnProps>`
  display: flex;
  justify-content:  ${props => props.spaceBetween ? "space-between;" : "center;"};
  align-items: center;
  min-width: ${props => props.onlyIcon ? "auto;" : "64px;"};
  width: ${props => props.block ? "100%" : "auto"};
  min-height: 40px;
  padding: 0px ${spacings.sl};
  background: ${(props) => colorsProps[props.typeBtn].color_bg_initial};
  border-radius: ${borders.md};
  border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_border_initial};
  outline: transparent;
  font-weight: ${fontWeights.w700};
  font-size: ${fontSizes.sm};
  transition: all 0.2s linear;
  color: ${(props) => colorsProps[props.typeBtn].color_font_initial};

  span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: ${borders.md};
    ${props => props.iconSide === "left" ? `margin-left: ${spacings.sl}` : `margin-right: ${spacings.sl}`};
    ${props => props.onlyIcon ? "margin: 0;" : ""};
    pointer-events: none;
  }

  /* State medium */
  &.medium{
    min-height: 45px;
    padding: 0 ${spacings.sm};
  }
/* State large */
  &.large{
    min-height: 53px;
    padding: 0 ${spacings.md};
  }

  /* State normal */
  &:hover{
    background: ${(props) => colorsProps[props.typeBtn].color_bg_hover};
    border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_border_hover};
    color: ${(props) => colorsProps[props.typeBtn].color_font_hover};
  }


  &:focus:not(:hover){
    background: ${(props) => colorsProps[props.typeBtn].color_bg_focus};
    border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_border_focus};
  }

  &:active{
    border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_border_click};
    color: ${(props) => colorsProps[props.typeBtn].color_font_hover};
    background: ${(props) => colorsProps[props.typeBtn].color_bg_click};
    ${(props) => colorsProps[props.typeBtn].color_bg_click};
    
  }

  &.load, &.load:disabled{
    background: ${(props) => props.typeBtn === "outline" ? "transparent" : colorsProps[props.typeBtn].color_bg_hover};
    border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_bg_hover};

    .ball{
      background-color: ${(props) => props.typeBtn === "outline" ? `${colors.primary}` : `${colors.white}`};
      opacity: 1;
    }
  }

  /* State disable */
  &:disabled{
    background: ${(props) => props.typeBtn === "outline" ? "transparent" : colorsProps[props.typeBtn].color_bg_disable};
    border: ${borders.xs} solid ${(props) => colorsProps[props.typeBtn].color_border_disable};
    color: ${(props) => colorsProps[props.typeBtn].color_font_disable};

    &:hover{
      cursor: not-allowed;
    }
  }
  
  /* Animation load */
  div{
    cursor: wait !important;
    pointer-events: none !important;

    .ball{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 0;
      border-radius: ${borders.lg};
      background-color: ${colors.white};
      opacity: 0.7;
      
      animation: ${loading} 0.8s 0.1s linear infinite;

      &:last-child{
        margin-right: 0px;
      }
    }

    .ball:nth-last-child(1) {
      animation-delay: 0.1s;
    }
    .ball:nth-last-child(2) {
      animation-delay: 0.2s;
    }
    .ball:nth-last-child(3) {
      animation-delay: 0.3s;
    }
  }
`