import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAlert } from '../../../hooks/useAlert';
import { useAuth } from '../../../hooks/useAuth';

import { Card } from '../../../components/Card';
import { Menu } from '../../../components/Menu';
import { Title } from '../../../components/Title';
import { InputFormField } from '../../../components/InputFormField';
import { TableSimple } from '../../../components/TableSimple';
import { Button } from '../../../components/Button';
import { Loading } from '../../../components/Loading';
import { CheckBox } from '../../../components/CheckBox';
import { Tooltip } from '../../../components/Tooltip';
import { GoToBack } from '../../../components/GoToBack';
import { Footer } from '../../../components/Footer';
import { InputDatePicker } from '../../../components/InputDatePicker';
import { Badge } from '../../../components/Badge';
import { Header } from '../../../components/Header';
import { PlaceHolderNotExists } from '../../../components/PlaceholderNotExists';

import { formattingDate } from '../../../utils/formattingDate';
import { formattingOnlyNumber, formattingOnlyNumberCurrency } from '../../../utils/formattingOnlyNumber';
import { checkChange } from '../../../utils/checkChange';
import { isValidatedInput, ValidateInputProps } from '../../../utils/validateInput';
import { iconArrowRight,  iconPlus } from '../../../assets/icons';
import { CardContent, HeaderContainer } from './styles';
import { menuListItems } from '../../../assets/menu/menuList';

interface BallotBoxeProps {
  ballotboxId: string,
  drawn: boolean,
  period?: string,
  serie: string,
  startDate: string,
  endDate: string,
  cefLotteryDate: string,
  mccLotteryDate: string,
  coupons: number
}

interface ParamsProps {
  id: string;
}

const initLotteryDetails = {
  lotteryPlanId: "1",
  description: "Plano",
  startDate: "01/01/2000",
  endDate: "01/01/2000",
  couponQuantity: "0",

  invoiceOrderId: "",
  invoiceNumber: "0",
  quantity: "0",
  totalValue: "0",
  invoiceDate: "01/01/2000",
}

const headerTable = ["Série", "Sorteada?", "Período", "Sorteio CEF", "Sorteio MCC", "Cupons participantes", ""];

export const LotteryDetails: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { push } = useHistory();
  const { connectAPI, cancelRequest } = useAuth();
  const { openAlert, closeAlert, alert, resetTypeContinue} = useAlert();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ changeInputs, setChangeInputs ] = useState({});
  const [ inputsError, setInputsError ] = useState<any>({});
  const [ submiteButton, setSubmiteButton ] = useState(true);
  const [ changeFooter, setChangeFooter ] = useState<any>([])
  const [ fieldIsEditable, setFieldIsEditable ] = useState(false);
  const [ inputsValues, setInputsValues ] = useState(initLotteryDetails);
  const [ lotteryBallotsData, setLotteryBallotsData ] = useState<BallotBoxeProps[]>();

  const convertDateToShowInput = (dateValue: any) => {
    var date = new Date(dateValue),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].reverse().join("/");
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> | any, dateName?: any) => {
    if (!!dateName) {
      setInputsValues({
        ...inputsValues,
        [dateName]: String(event)
      })
    } else {
      const targetName = event.target.name;
      let targetValue = event.target.value;

      if (targetName === "couponQuantity" || targetName === "quantity" || targetName === "totalValue") {
        targetValue = formattingOnlyNumber(targetValue)
      }

      setInputsValues({
        ...inputsValues,
        [targetName]: targetValue
      })
    }
  }

  const getLotteryDetailsData = async () => {
    setIsLoading(true);

    const response = await connectAPI({
      url: `/lotterys/plans/${id}`, 
      type: "get", 
      messageError: "Erro ao carregar dados do plano"
    });

    if(response.connect){
      const lotteryPlan = response.data.lotteryPlan;
      const lotteryInvoice = response.data.invoiceOrder;
      setFieldIsEditable(new Date(lotteryPlan.endDate) >= new Date());
  
      setInputsValues({
        description: lotteryPlan.description,
        startDate: String(new Date(lotteryPlan.startDate)),
        endDate: String(new Date(lotteryPlan.endDate)),
        couponQuantity: formattingOnlyNumber(lotteryPlan.couponQuantity) || lotteryPlan.couponQuantity,
        lotteryPlanId: lotteryPlan.lotteryPlanId,
        invoiceNumber: lotteryInvoice.invoiceNumber,
        quantity: formattingOnlyNumber(lotteryInvoice.quantity) || lotteryInvoice.quantity,
        totalValue: formattingOnlyNumberCurrency(lotteryInvoice.totalValue) || lotteryInvoice.totalValue,
        invoiceDate: String(new Date(lotteryInvoice.invoiceDate)),
        invoiceOrderId: lotteryInvoice.invoiceOrderId,
      })
  
      setChangeInputs({
        description: lotteryPlan.description,
        startDate: String(new Date(lotteryPlan.startDate)),
        endDate: String(new Date(lotteryPlan.endDate)),
        couponQuantity: formattingOnlyNumber(lotteryPlan.couponQuantity) || lotteryPlan.couponQuantity,
        lotteryPlanId: lotteryPlan.lotteryPlanId,
        invoiceNumber: lotteryInvoice.invoiceNumber,
        quantity: formattingOnlyNumber(lotteryInvoice.quantity) || lotteryInvoice.quantity,
        totalValue: formattingOnlyNumber(lotteryInvoice.totalValue) || lotteryInvoice.totalValue,
        invoiceDate: String(new Date(lotteryInvoice.invoiceDate)),
        invoiceOrderId: lotteryInvoice.invoiceOrderId,
      })
  
      const lotteryBallots = response.data.ballotboxes;
  
      if (lotteryBallots) {
        let arrayBallots = lotteryBallots.map((item: BallotBoxeProps) => ({
          serie: item.serie,
          drawn: checkBoxDrawnToTable(item.drawn),
          period: formattingDate(item.startDate) + ' - ' + formattingDate(item.endDate),
          cefLotteryDate: formattingDate(item.cefLotteryDate),
          mccLotteryDate: formattingDate(item.mccLotteryDate),
          coupons: formattingOnlyNumber(item.coupons),
          buttons: buttonsToTable(item, lotteryPlan.description),
        }))
        
        setLotteryBallotsData(arrayBallots.sort((a: any, b: any) => a.serie - b.serie));
      }
    }

    setIsLoading(false);
  }
  
  const buttonsToTable = ({ ...props }: BallotBoxeProps, description: string) => {
    return (
      <div className="tbody-flex">
        <Tooltip text="Ir para detalhes">
          <Button
            label=""
            size="small"
            typeBtn="default"
            onlyIcon={true}
            icon={iconArrowRight}
            onClick={() => push(`/lottery/ballot-details/${props.ballotboxId}`)}
          />
        </Tooltip>
      </div>
    )
  }

  const checkBoxDrawnToTable = (drawn: boolean) => {
    return (
      <CheckBox
        label={drawn ? "Sim" : "Não"}
        name="drawn"
        value=""
        checked={true}
        readOnly={true}
        checkedError={!drawn}
      />
    )
  }

  const validadeErrorInputs = () => {
    const initValues: ValidateInputProps[] = [
      {
        name: "description",
        valueInput: inputsValues.description,
        validateOptions: ['minCharacter'],
        min: 3
      },
      {
        name: "couponQuantity",
        valueInput: inputsValues.couponQuantity,
        validateOptions: ['minCharacter'],
        min: 1
      },
      {
        name: "invoiceNumber",
        valueInput: inputsValues.invoiceNumber,
        validateOptions: ['minCharacter'],
        min: 1
      },
      {
        name: "quantity",
        valueInput: inputsValues.quantity,
        validateOptions: ['minCharacter'],
        min: 1
      },
      {
        name: "totalValue",
        valueInput: inputsValues.totalValue,
        validateOptions: ['minCharacter'],
        min: 1
      },
    ]

    const { validatedObject, hasError } = isValidatedInput(initValues);

    setSubmiteButton(hasError);
    setInputsError(validatedObject);
  }

  const getProps = (props: string) => (
    {
      "description": "Descrição",
      "startDate": "Data Inicial",
      "endDate": "Data Final",
      "couponQuantity": "Qtd. Cupons",
      "invoiceNumber": "Nº Pedido",
      "quantity": "Quantidade",
      "totalValue": "Valor Total",
      "invoiceDate": "Data do Pedido",
    }
  )[props];

  const handleSubmit = async () => {
    const data = {
      "lotteryPlan": {
        "description": inputsValues.description,
        "startDate": new Date(inputsValues.startDate),
        "endDate": new Date(inputsValues.endDate),
        "couponQuantity": Number(inputsValues.couponQuantity.replace(".",""))
      },
      "invoiceOrder": {
        "invoiceNumber": inputsValues.invoiceNumber,
        "quantity": Number(inputsValues.quantity.replace(/\D+/g, '')),
        "totalValue": Number(inputsValues.totalValue.replace(/\D+/g, '')),
        "invoiceDate": new Date(inputsValues.invoiceDate)
      }
    }
    
    const response = await connectAPI({
      url: `lotterys/plans/${id}`, 
      body: data, 
      type: "put", 
      messageError: "Falha ao atualizar sorteio", 
      messageSuccess: "Sorteio atualizado com sucesso"
    });

    if (response.connect){
      setChangeInputs({ ...inputsValues })
      setChangeFooter([]);
    }
    closeAlert();
  }

  const hiddenFooter = () => {
    closeAlert();
    setInputsValues({ ...inputsValues, ...changeInputs })
    setChangeFooter([]);
  }

  useEffect(() => {
    getLotteryDetailsData();

    return () => {
      setLotteryBallotsData([])
      cancelRequest.cancel("cancel");
    }
  }, [])

  useEffect(() => {
    const changed = checkChange(inputsValues, changeInputs);
    setChangeFooter(changed);
    validadeErrorInputs();
  }, [inputsValues])

  useEffect(() => {
    if(alert.typeContinue === "save"){
      handleSubmit();
      resetTypeContinue();
    } 
    alert.typeContinue === "discard" && hiddenFooter();
  }, [alert])

  if (isLoading) {
    return (
      <Menu links={menuListItems}>
        <Header>
          <HeaderContainer>
            <div>
              <GoToBack path="/lottery" />
            </div>
            <div className="header-text">
              <Title text={inputsValues.description ? inputsValues.description : "Detalhes"} elementHTML="h1" className="btn-text" />
            </div>
          </HeaderContainer>
        </ Header>

        <Loading />
      </Menu>
    )
  }

  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path="/lottery" />
          </div>

          <div className="header-text">
            <Title text={inputsValues.description} elementHTML="h1" className="btn-text" />
          </div>
        </HeaderContainer>
      </ Header>

      <Card title="Dados básicos">
        <CardContent>
          <InputFormField
            name="description"
            label="Descrição"
            value={inputsValues.description}
            error={inputsError.description ? !inputsError.description.isValidated : false}
            mensageError="mínimo 3 digitos"
            backGround="white"
            marginBotton="0"
            title={inputsValues.description}
            onChange={handleChange}
            readOnly={!fieldIsEditable}
          />

          <InputDatePicker
            name="startDate"
            label="Data Inicial"
            marginBotton="0"
            selected={new Date(inputsValues.startDate) || null}
            value={convertDateToShowInput(inputsValues.startDate)}
            onChange={(event) => handleChange(event, "startDate")}
            readOnly={!fieldIsEditable}
          />

          <InputDatePicker
            name="endDate"
            label="Data Final"
            marginBotton="0"
            selected={new Date(inputsValues.endDate) || null}
            value={convertDateToShowInput(inputsValues.endDate)}
            onChange={(event) => handleChange(event, "endDate")}
            readOnly={!fieldIsEditable}
          />

          <InputFormField
            name="couponQuantity"
            label="Qtd. Cupons"
            value={inputsValues.couponQuantity}
            error={inputsError.couponQuantity ? !inputsError.couponQuantity.isValidated : false}
            mensageError="mínimo 1 digito"
            backGround="white"
            marginBotton="0"
            onChange={handleChange}
            readOnly={!fieldIsEditable}
          />
        </CardContent>
      </Card>

      <Card title="Invoices" >
        {inputsValues ? (
          <CardContent>
            <InputFormField
              name="invoiceNumber"
              label="Nº Pedido"
              typeInput="number"
              value={inputsValues.invoiceNumber}
              error={inputsError.invoiceNumber ? !inputsError.invoiceNumber.isValidated : false}
              mensageError="mínimo 1 digito"
              backGround="white"
              marginBotton="0"
              onChange={handleChange}
              readOnly={!fieldIsEditable}
            />

            <InputFormField
              name="quantity"
              label="Quantidade"
              value={formattingOnlyNumber(inputsValues.quantity)}
              error={inputsError.quantity ? !inputsError.quantity.isValidated : false}
              mensageError="mínimo 1 digito"
              backGround="white"
              marginBotton="0"
              onChange={handleChange}
              readOnly={!fieldIsEditable}
            />

            <InputFormField
              name="totalValue"
              label="Total"
              value={formattingOnlyNumberCurrency(inputsValues.totalValue)}
              error={inputsError.totalValue ? !inputsError.totalValue.isValidated : false}
              mensageError="mínimo 1 digito"
              backGround="white"
              marginBotton="0"
              onChange={handleChange}
              readOnly={!fieldIsEditable}
            />

            <InputDatePicker
              name="invoiceDate"
              label="Data Pedido"
              selected={new Date(inputsValues.invoiceDate) || null}
              value={convertDateToShowInput(inputsValues.invoiceDate)}
              marginBotton="0"
              onChange={(event) => handleChange(event, "invoiceDate")}
              readOnly={!fieldIsEditable}
            />
          </CardContent>
        ) : (
          <PlaceHolderNotExists title="Não existe uma invoice cadastrada" />
        )}
      </Card>

      <Card title="Urnas" 
        hasButton={(new Date(inputsValues.endDate) > new Date())} 
        labelButton="Adicionar novo urna" 
        iconButton={iconPlus}
        onClick={() => push(`/lottery/register-new-ballot/${inputsValues.lotteryPlanId}`)}
      >
        {lotteryBallotsData ? (
          <TableSimple
            headerTable={headerTable}
            bodyTable={lotteryBallotsData}
            listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
            quantityButtonsOnPaginate={5}
          />
        ) : (
          <PlaceHolderNotExists title="Não existem urnas cadastradas" />
        )}
      </Card>

      <Footer isVisible={changeFooter.length > 0 ? true : false}>

        <Title elementHTML="strong" text="Campos atualizados:" className="tag" />

        {changeFooter.map((item: string) => (
          item ?
            <Badge value={getProps(item) || ""} key={item} /> : null
        ))}

        <Button 
          label="Cancelar" 
          typeBtn="outline"
          onClick={() => openAlert(false)} 
        />
        
        <Button 
          label="Atualizar dados" 
          typeBtn="blue" 
          disable={submiteButton} 
          onClick={() => openAlert(true)} 
        />
      </Footer>
    </Menu>
  );
}