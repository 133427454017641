import React from "react"
import { GlobalStyle } from './styles';

type classText = "title" | "subtitle" | "subtitle-medium" | "subtitle-small" | "btn-text" | "hiperlink" | "paragraph" | "overline" | "paragraph-small" | "tag";
export interface TitleProps {
  elementHTML: any;
  className?: classText;
  text: string;
}

export const Title: React.FC<TitleProps> = ({children, elementHTML, className = "title", text, ...props }) => {
  const attr = {
    className,
    ...props
  }
  const newTag = React.createElement(`${elementHTML}`, attr, `${text}`);
  return (
    <>
      <GlobalStyle />
      {newTag}
      {children}
    </>

  )
}