import iconUsersSelected from '../menu/users-selected.svg';
import iconUsersNotSelected from '../menu/users-not-selected.svg';
import iconLotterySelected from '../menu/lottery-selected.svg';
import iconLotteryNotSelected from '../menu/lottery-not-selected.svg';
import iconSellersSelected from '../menu/sellers-selected.svg';
import iconSellersNotSelected from '../menu/sellers-not-selected.svg';
import iconPermissionsSelected from '../menu/permissions-selected.svg';
import iconPermissionsNotSelected from '../menu/permissions-not-selected.svg';
import iconAccountSelected from '../menu/account-selected.svg';
import iconAccountNotSelected from '../menu/account-not-selected.svg';

export interface MenuItemProps {
  icon: string;
  iconSelected: string;
  label: string;
  path: string;
  position: "top" | "bottom";
}

export const menuListItems: MenuItemProps[] = [
  {
    icon: iconUsersNotSelected,
    iconSelected: iconUsersSelected,
    label: "Usuários",
    path: "/home",
    position: "top"
  },
  {
    icon: iconLotteryNotSelected,
    iconSelected: iconLotterySelected,
    label: "Sorteios",
    path: "/lottery",
    position: "top"
  },
  {
    icon: iconSellersNotSelected,
    iconSelected: iconSellersSelected,
    label: "Lojista",
    path: "/sellers",
    position: "top"
  },
  {
    icon: iconPermissionsNotSelected,
    iconSelected: iconPermissionsSelected,
    label: "Permissões",
    path: "/permissions",
    position: "bottom"
  },
  {
    icon: iconAccountNotSelected,
    iconSelected: iconAccountSelected,
    label: "Minha conta",
    path: "/account",
    position: "bottom"
  },
]