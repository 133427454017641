import styled, { keyframes } from "styled-components";
import {
  colors,
  fontSizes, 
  fontWeights, 
  spacings,
  borders
} from '../../styles/theme';

const fadeIn = keyframes`
  from {
    transform: scale(0);
  },
  to{
    transform: scale(1);
  }
`

export const Container = styled.div`
  min-height: calc(100vh - 200px);
`

export const TableSearchUsers = styled.div`
  display: flex;
  width: 50%;
  
  button{
    margin-left: ${fontSizes.xl};
  }
`

export const TableFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacings.ls};
  padding: 0px ${spacings.ls} ${spacings.ls} 0px;
  border-bottom: ${borders.xs} solid ${colors.neutral_light};

  strong{
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.sl};
    color: ${colors.neutral_darkest};
    margin-right: ${spacings.md};
  }

  .btn-export{
    display: inline-flex;
    align-items: center;
    max-width: auto;
    height: 40px;
    padding: ${spacings.sl};
    border-radius: ${borders.md};
    border: ${borders.xs} solid ${colors.neutral_light};
    font-weight: ${fontWeights.w500};
    font-size: ${fontSizes.sm};
    color: ${colors.neutral_darkest};
    background-color: ${colors.white};
    animation: ${fadeIn} 0.2s linear;

    span{
      margin-left: ${spacings.xs}
    }

  }

  .data-result{
    display: inline-flex;
    margin-left: ${spacings.xx};
  }

  button{
    border: ${borders.xs} solid ${colors.primary};
    display: inline-flex;
    color: ${colors.neutral_darkest};

    svg{
      transform: rotate(180deg);
    }

    &:hover {
      background-color: ${colors.neutral_regular};
      color: ${colors.neutral_darkest};
    }
  }
`

export const ContainerTable = styled.div`
border: ${borders.xs} solid ${colors.neutral_regular};
border-radius: ${borders.sm};
overflow: hidden;

span{
  margin-right: ${spacings.md};
  font-weight: ${fontWeights.w700};
  font-size: ${fontSizes.sl};
  color: ${colors.neutral_darkest};
}
`

export const ContainerLoading = styled.div`
  padding: ${spacings.lg} 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: ${spacings.md};
    width: auto;
    height: 45px;
  }

  /* ENDLESS ROTATE */
  .rotate{
    animation: rotate 3.5s linear infinite; 
  }
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }


  /* SPINNER JUST FOR DEMO */
  .spinner{
    display:inline-block; 
    width: 70px; 
    height: 70px;
    border-radius: 50%;
    border: ${borders.sm} dashed ${colors.primary_click};
    border-spacing: 100px;
  }
`

export const TableContent = styled.table`
  border-collapse: collapse!important;
  width: 100%;

  thead{
    background: ${colors.neutral_light};
    tr{
      th{
        min-width: ${spacings.ls};
        height: 48px;
        padding-left: ${spacings.md};
        text-align: left;

        button{
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          border: none;
          font-weight: ${fontWeights.w700};
          font-size: ${fontSizes.sl};
          color: ${colors.neutral_darkest};
          background-color: transparent;

          svg{
            margin-left: ${spacings.sl};
          }
        }

        input{
          margin-top: ${spacings.sl};
          border: ${borders.xs} solid  #${colors.neutral_medium};
          outline: none;
          width: 100%;
          padding: ${spacings.xs};
          color: ${colors.neutral_darkest};
          font-weight: ${fontWeights.w500};
          font-size: ${fontSizes.sm};
          background: ${colors.white};
          border-radius: ${borders.md};
        }
        
      }
    }
  }

  tbody{

    tr{
      background: ${colors.neutral_light};

      td{
        height: 56px;
        padding-left: ${spacings.md};
        text-align: left;
        font-weight: ${fontWeights.w500};
        font-size: ${fontSizes.sm};
        color: ${colors.neutral_darkest};
      }

      &:nth-child(2n+1){
        background: ${colors.white};
      }
    }
  }
`

export const TablePagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacings.ls};
  margin-bottom: ${spacings.ls};

  .total-itens{
    strong{
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sl};
      color: ${colors.neutral_darkest};
      margin-right: ${spacings.md};
    }
  }

  .button-paginate{
    margin: 0 ${spacings.xs};
    padding: ${spacings.sl};
    font-style: normal;
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.sm};
    color: ${colors.neutral_darkest};
    border: ${borders.xs} solid ${colors.neutral_regular};
    border-radius: ${borders.sm};
    background-color: transparent;
    transition: 0.2s;

    &:last-child{
      margin-right: 0;
    }

    &:hover, &.isActive{
      color: ${colors.white};
      border: ${borders.xs} solid ${colors.primary};
      background-color: ${colors.primary};

      svg{
        path{
          stroke: ${colors.white};
        }        
      }
    }
  }

  .paginate{
    display: flex;
    
    .arrow-left{
      svg{
        transform: rotate(-90deg);
      }
    }

    .arrow-right{
      svg{
        transform: rotate(90deg);
      }
    }

    .paginate-dot {
      display: flex;
      align-self: center;
      color: ${colors.neutral_regular};

      strong{
        font-size: ${fontSizes.ml};
        text-align: center;
        margin-bottom: calc(50%);

        &:nth-child(2){
          margin: 0 3px;
        }
      }
    }
  }
`

export const TableCards = styled.div`
  display: flex;
  
  .card{
    margin-right:  ${spacings.lg};
    padding:  ${spacings.md}  ${spacings.ls};
    border: ${borders.xs} solid ${colors.neutral_regular};
    border-radius: ${borders.lg};
    margin-bottom: ${spacings.ls};
    background-color: transparent;
    transition: all 0.2s linear;
    min-width: 150px;

    &.isActive{
      border: ${borders.xs} solid ${colors.primary};
    }

    &:last-child{
      margin-right: 0;
    }

    span{
      font-style: normal;
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.xs};
      color: ${colors.neutral_darker};
    }

    h2{
      font-family: DM Sans;
      font-style: normal;
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.xl};
      color: ${colors.neutral_darkest};
    }
  }

`;