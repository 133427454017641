import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { BaseRoutes } from './BaseRoutes';

import { Login } from '../pages/Login';
import { ForgotPassword } from '../pages/ForgotPassword';
import { Home } from '../pages/Home';
import { CustomerDetails } from '../pages/CustomerDatails';

import { PrivateRoute } from './PrivateRoute';
import { Lottery } from '../pages/Lottery';
import { LotteryDetails } from '../pages/Lottery/LotteryDetails';
import { BallotDetails } from '../pages/Lottery/BallotDetails';
import { RegisterNewPlan } from '../pages/Lottery/RegisterNewPlan';
import { WinnerDetails } from '../pages/Lottery/WinnerDetails';
import { RegisterNewPrize } from '../pages/Lottery/RegisterNewPrize';
import { Permissions } from '../pages/Permissions';
import { NewUser } from 'pages/Permissions/NewUser';
import { NewRole } from 'pages/Permissions/NewRole';
import { UserDetails } from 'pages/Permissions/UserDetails';
import { RoleDetails } from 'pages/Permissions/RoleDetails';

export const Routes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path={BaseRoutes.login} component={Login} />
        <Route exact path={BaseRoutes.fogotPassword} component={ForgotPassword} />
        <PrivateRoute exact path={BaseRoutes.home} component={Home} />
        <PrivateRoute path={BaseRoutes.customerDetails} component={CustomerDetails} />
        <PrivateRoute exact path={BaseRoutes.lottery} component={Lottery} />
        <PrivateRoute exact path={BaseRoutes.lotteryDetails} component={LotteryDetails} />
        <PrivateRoute exact path={BaseRoutes.BallotDetails} component={BallotDetails} />
        <PrivateRoute exact path={BaseRoutes.RegisterNewBallot} component={RegisterNewPlan}/>
        <PrivateRoute exact path={BaseRoutes.RegisterNewPlan} component={RegisterNewPlan} />
        <PrivateRoute exact path={BaseRoutes.EditPlan} component={RegisterNewPlan} />
        <PrivateRoute exact path={BaseRoutes.WinnerDetails} component={WinnerDetails} />
        <PrivateRoute exact path={BaseRoutes.RegisterNewPrize} component={RegisterNewPrize} />
        <PrivateRoute exact path={BaseRoutes.PrizeDetails} component={RegisterNewPrize} />
        <PrivateRoute exact path={BaseRoutes.permissions} component={Permissions} />
        <PrivateRoute exact path={BaseRoutes.newUser} component={NewUser} />
        <PrivateRoute exact path={BaseRoutes.newRole} component={NewRole} />
        <PrivateRoute exact path={BaseRoutes.userDetails} component={UserDetails} />
        <PrivateRoute exact path={BaseRoutes.roleDetails} component={RoleDetails} />
        <Route component={Login} />
      </Switch>
    </HashRouter>
  );
};