import React from 'react';
import { useHistory } from 'react-router-dom';
import { iconArrowLeft } from '../../assets/icons';
import { Title } from '../Title';

import { Container } from './styles';

interface tabNavItens {
  title?: string;
  path: string; 
}

export const GoToBack: React.FC<tabNavItens> = ({title = "Voltar", path}) => {
  const {push} = useHistory();
  return (
    <Container>
      <button onClick={() => push(path)}>
        {iconArrowLeft}
        <span>{title}</span>
      </button>
    </Container>
  );
}