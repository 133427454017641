import React, { ReactNode } from 'react'

import { TabNavContent } from './styles'

interface tabPainelProps {
  children?: ReactNode
  isSelected: string;
  tabName: string
}

export const Tab: React.FC<tabPainelProps> = ({ children, isSelected, tabName }) => {

  if (isSelected === tabName) {
    return (
      <TabNavContent>
        {children}
      </TabNavContent>
    )
  }

  return null;

}