import React, { ReactNode, useEffect, useState } from 'react';
import { useTable } from '../../hooks/useTable';
import { InputFormField } from '../InputFormField';

import { SelectCuston } from './styles';

export interface SelectProps {
  name: string,
  optionsValue: string[],
  filter: string;
  search?: boolean;
  labelInput?: string,
  minWidthOptions?: number,
  appearWhere?: string,
  onChange?: any,
  children?: ReactNode;
}

 export const SelectTableFilter: React.FC<SelectProps> = ({ name = "select name", children = "", optionsValue, filter, search = false, onChange, labelInput = "Buscar", minWidthOptions = 100, appearWhere = "top" }) => {
  const { selectOptionsToFilter, daysToFilter, cityToFilter, userType } = useTable();

  const [nameSelect, setNameSelect] = useState(name);
  const [changeBorderColor, setChangeBorderColor] = useState(false);
  const [clickSelect, setClickSelect] = useState(false);  
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();

  const debounceFN = (fn: any, value: string) => {
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      fn(value);
    }, 1000)

    setDebounce(timer);
  }

  const handleIsOpen = (value: any) => {
    if(value.name === "search"){
      return;
    }else{
      setClickSelect(!clickSelect);
    }
  }
  
  const selectFieldToFilter = (event: any) => {
    const option = event.target.dataset.options;
    if (option) {
      selectOptionsToFilter(filter, option);
      setNameSelect(option);
      setChangeBorderColor(true);
    }else{
      setNameSelect("");
    }
  }

  useEffect(() => {

    if(filter === 'days' && daysToFilter === ""){
      setNameSelect("Último Login");      
      setChangeBorderColor(false);
    }
    
    if(filter === 'city' && cityToFilter === ""){
      setNameSelect("Localidade");
      setChangeBorderColor(false);
    }
    
    if(filter === 'userType' && userType === ""){
      setNameSelect("Usuários verificados");
      setChangeBorderColor(false);
    }

    setClickSelect(false);
  }, [daysToFilter, cityToFilter, userType])

  return (
    <SelectCuston changeBorderColor={changeBorderColor} selected={clickSelect} minWidthOptions={minWidthOptions} appearWhere={appearWhere} onClick={(event) => handleIsOpen(event.target)}>

      <div className="wrapper">
        <div className="select" >
          <strong>{nameSelect}</strong>  
        </div>
          {children}
      </div>

      <div className="select-options">
        <div className="select-options-content">
          {search &&
            <div className="select-options-input">
              <InputFormField 
                autoComplete="off"
                backGround="white" 
                marginBotton="10"
                icon={true} 
                name="search" 
                label={labelInput} 
                onChange={(event: any) => debounceFN(onChange, event)}
              />
            </div>
          }

          {optionsValue.map(option => (
            <div
              onClick={selectFieldToFilter}
              key={option}
              data-options={option}
              className={nameSelect === option ? "select-options-item isActive" : "select-options-item"}
            >
              <span></span>
              <strong>{option}</strong>
            </div>
          ))}
        </div>
      </div>
    </SelectCuston>
  )
}