import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSession } from 'hooks/useSession'
import { api } from 'services/api'
import { useHistory, useParams } from 'react-router'
import { checkChangeDeep } from 'utils/checkChange'
import { CheckBoxChangeEvent } from 'components/CheckBox'
import { useAlert } from 'hooks/useAlert'
import { useToast } from 'hooks/useToast'
import { BaseRoutes } from 'routes/BaseRoutes'

type Definition = "CREATE" | "DELETE" | "READ" | "UPDATE" 

interface ParamsProps {
  id: string;
}

interface RoleProps {
  roleId: string,
  roleName: string,
  definitions: Definition[]
}

const buildRole:RoleProps = {
  roleId: '',
  roleName: '',
  definitions: [],
}

const changeNameTag = (props: string) => (
  {
    "roleName": "nome",
    "definitions": "permissões"
  }
)[props];

export const useRoleDetails = () => {
  const [role, setRole] = useState(buildRole);
  const [initRole, setInitRole] = useState(buildRole);
  const [changeFooter, setChangeFooter] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const history = useHistory();
  const session = useSession();
  const { id } = useParams<ParamsProps>();
  const { setToast } = useToast();
  const { openAlert, closeAlert, alert } = useAlert();

  const push = (route: string): void => {
    history.push(route);
  }

  /* HOOKS */
  useEffect(() => {
    getRole(id);
  }, [])

  useEffect(() => {
    const changed = checkChangeDeep(role, initRole);
    const translatedChange = changed.map((change) => {
      return changeNameTag(change) ?? '';
    })
    checkFormValid();
    setChangeFooter(translatedChange);
  }, [role])

  useEffect(() => {
    alert.typeContinue === "save" && submitAlertHandler();
    alert.typeContinue === "discard" && discartAlertHandler();
  }, [alert])

  /* METHODS */
  const checkFormValid = () => {
    if (role.roleName.length > 0 && role.definitions.length > 0) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }

  const handleInputFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRole({
      ...role,
      roleName: value,
    })
  }

  const handleCheckBoxChange = (e: CheckBoxChangeEvent) => {
    const enabled:boolean = e.checked;
    const definition:Definition = e.value;
    let definitions = [...role.definitions];

    if (enabled) {
      if (!definitions.includes(definition))
        definitions.push(definition);
    } else {
      const index = definitions.indexOf(definition, 0);
      if (index > -1) {
        definitions.splice(index, 1);
      }
    }
    definitions.sort((a, b) => a > b ? 1 : -1);
    setRole({
      ...role,
      definitions: definitions,
    });
  }

  const discardHandler = () => {
    openAlert(false);
  }

  const submitHandler = () => {
    openAlert(true);
  }

  const discartAlertHandler = () => {
    console.log('ROLE', role)
    console.log('INIT ROLE', initRole)

    setRole(initRole);
    setChangeFooter([]);
    closeAlert();
  }

  const submitAlertHandler = () => {
    updateRole();
  }

  /* API CALLS */
  const getRole = async(id: string) => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    api.get(`users/roles/${id}`, config).then((response) => {
      let role:RoleProps = response.data;
      role.definitions.sort((a, b) => a > b ? 1 : -1);
      setInitRole(role);
      setRole(role);
    }).catch((error) => {
      console.error('ERRO', error);
      setError(true);
    }).finally(() => {
      setLoading(false);
    })
  }

  const updateRole = async() => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    // setLoading(true);
    api.put(`users/roles/${id}`, config).then((_) => {
      setToast({
        message: `Permissão alterada com sucesso`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      });
      push(BaseRoutes.permissions);
    }).catch((error) => {
      console.error('API ERROR', error.response)
      const errorResponse = error.response.data;
      setToast({
        message: `Erro ${errorResponse.StatusCode}: ${errorResponse.Message}`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      });
    }).finally(() => {
      closeAlert();
      // setLoading(false);
    })
  }

  return {
    loading,
    error,
    role,
    validForm,
    changeFooter,
    handleInputFieldChange,
    handleCheckBoxChange,
    discardHandler,
    submitHandler,
  }
}