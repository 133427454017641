import React from "react"

import { HeaderContainer } from './styles';

export const Header: React.FC = ({ children }) => {
  return (
    <HeaderContainer>
      {children}
    </HeaderContainer>
  )
}