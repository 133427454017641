import { GoToBack } from "components/GoToBack";
import { Header } from "components/Header";
import { HeaderContainer } from "./styles";
import { Menu } from "components/Menu";
import { Title } from "components/Title";
import { useUserDetails } from "./useUserDetails"
import { Loading } from "components/Loading";
import { PlaceHolderNotExists } from "components/PlaceholderNotExists";
import { UserForm } from "../shared/userForm";
import { Footer } from "components/Footer";
import { Badge } from "components/Badge";
import { Button } from "components/Button";
import { menuListItems } from '../../../assets/menu/menuList';

export const UserDetails:React.FC = () => {
  const {
    loading,
    hasError,
    inputsValues,
    companyOptions,
    applicationOptions,
    roleOptions,
    editable,
    changeFooter,
    validForm,
    handleInputChange,
    handleCompanySelectChange,
    handlePermissionSelectChange,
    addPermission,
    removePermission,
    discardHandler,
    submitHandler
  } = useUserDetails();
  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path="/permissions" />
          </div>
          <div className="header-text">
            <Title text="Dados do usuário" elementHTML="h1" className="btn-text" />
          </div>
        </HeaderContainer>
      </Header>
      { 
        loading
          ? <Loading/>
          : hasError
            ? <PlaceHolderNotExists title="Falha ao buscar opçòes."/>
            : <UserForm
                  inputsValues={inputsValues}
                  companyOptions={companyOptions}
                  applicationOptions={applicationOptions}
                  roleOptions={roleOptions}
                  inputHandler={handleInputChange}
                  companyHandler={handleCompanySelectChange}
                  permissionHandler={handlePermissionSelectChange}
                  addPermission={addPermission}
                  removePermission={removePermission}
                  editable={editable}
                />
      }
      <Footer isVisible={changeFooter.length > 0}>
        <Title elementHTML="strong" text="Campos atualizados:" className="tag" />
        {changeFooter.map((item: string) => (item ? <Badge value={item} key={item} /> : null))}
        <Button label="Descartar" size="medium" typeBtn="outline" onClick={discardHandler} />
        <Button label="Atualizar dados" size="medium" typeBtn="blue" disable={!validForm} onClick={submitHandler} />
      </Footer>
    </Menu>
  )
}