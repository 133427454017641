import React from "react";
import { GoToBack } from "components/GoToBack";
import { Header } from "components/Header";
import { Title } from "components/Title";
import { Menu } from '../../../components/Menu';
import { useNewUser } from "./useNewUser";
import { HeaderContainer, Footer } from './styles';
import { Loading } from "components/Loading";
import { PlaceHolderNotExists } from "components/PlaceholderNotExists";
import { Button } from "components/Button";
import { UserForm } from "../shared/userForm";
import { menuListItems } from '../../../assets/menu/menuList';

export const NewUser: React.FC = () => {
  const {
    loading,
    hasError,
    validForm,
    submitting,
    handleInputChange,
    handleCompanySelectChange,
    handlePermissionSelectChange,
    handleOnSubmit,
    addPermission,
    removeRole,
    companyOptions,
    applicationOptions,
    roleOptions,
    inputsValues
  } = useNewUser();
  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path="/permissions" />
          </div>
          <div className="header-text">
            <Title text="Cadastrar novo usuário" elementHTML="h1" className="btn-text" />
          </div>
        </HeaderContainer>
      </Header>
      { 
        loading
          ? <Loading/>
          : hasError
              ? <PlaceHolderNotExists title="Falha ao buscar opçòes."/>
              : <div>
                  <UserForm
                      inputsValues={inputsValues}
                      companyOptions={companyOptions}
                      applicationOptions={applicationOptions}
                      roleOptions={roleOptions}
                      inputHandler={handleInputChange}
                      companyHandler={handleCompanySelectChange}
                      permissionHandler={handlePermissionSelectChange}
                      addPermission={addPermission}
                      removePermission={removeRole}
                    />
                  <Footer>
                    <Button 
                    label="Cadastrar usuário" 
                    typeBtn="blue"
                    load={submitting}
                    disabled={!validForm}
                    onClick={handleOnSubmit} 
                  />
                  </Footer>
                </div>
      }
    </Menu>
  );
}