import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../../../hooks/useAuth';

import { TableSimple } from '../../../../components/TableSimple';
import { Button } from '../../../../components/Button';
import { Card } from '../../../../components/Card';
import { Loading } from '../../../../components/Loading';
import { PlaceHolderNotExists } from '../../../../components/PlaceholderNotExists';

import { convertDateToShowInput, formattingDate } from '../../../../utils/formattingDate';
import { iconRedirectWhite } from '../../../../assets/icons';

const initTableHeadCupons = ["Gerado em", "Número", "Tipo Cupom", "Série", "Sorteio", ""];

interface CouponsProps {
  ballotBoxId: string,
  code: string,
  couponType: string,
  serie: number,
  createdDate: string,
  drawDate: string
}

interface ParamsURLProps {
  id: string;
  description: string;
}

export const Coupons: React.FC = () => {
  const { id } = useParams<ParamsURLProps>();
  const { connectAPI, cancelRequest } = useAuth();
  const { push } = useHistory();
  const [ couponData, setCouponData ] = useState<CouponsProps[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const onLoadingDataCupom = async () => {
    setIsLoading(true);

    const response = await connectAPI({
      url: `coupons/${id}/customers`, 
      type: "get", 
      messageError: "Falha ao listar cupons"
    });

    const data = response.connect ? response.data : [];
    setCouponData(data);
    setIsLoading(false);
  }

  const buttonTable = ({ ...props }: CouponsProps) => {
    return (
      <Button 
        label="Ir para o sorteio" 
        size="small" 
        typeBtn="blue"
        icon={iconRedirectWhite} 
        onClick={() => push(`/lottery/ballot-details/${props.ballotBoxId}`)} 
      />
    )
  }

  const newData = () => {
    const newArray: any = [];

    couponData.map(item => {
      newArray.push({
        createdDate: formattingDate(item.createdDate, true),
        code: item.code,
        couponType: item.couponType,
        serie: item.serie,
        drawDate: convertDateToShowInput(item.drawDate),
        tdButton: buttonTable(item)
      })

      return null
    })
    return newArray;
  }

  useEffect(() => {
    onLoadingDataCupom();

    return () => {
      setCouponData([])
      cancelRequest.cancel("cancel")
    };
  }, [])

  if (isLoading) {
    return (
      <Card title="Pedidos">
        <Loading />
      </Card>
    )
  }

  if (!isLoading && couponData.length) {
    return (
      <Card title="Cupons">
        <TableSimple
          headerTable={initTableHeadCupons}
          bodyTable={newData()}
          listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
          quantityButtonsOnPaginate={5}
        />
      </Card>
    );
  }

  return (
    <Card title="Cupons">
      <PlaceHolderNotExists title="Usuário não possui cupons" />
    </Card>
  )

}
