import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface LinkProps {
  urlImg: any,
  urlImgHover: any,
  path: string,
  name: string,
  isActive: boolean,
}

const ItemMenu: React.FC<LinkProps> = ({name, isActive, urlImg, urlImgHover, path}) => {
  const [currentImage, setCurrentImage] = useState(urlImg);

  return (
    <li 
      key={name} 
      className={`${isActive ? "isActive" : ""}`}
      onMouseOver={!isActive ? () => {setCurrentImage(urlImgHover)} : () => {}}
      onMouseLeave={!isActive ? () => {setCurrentImage(urlImg)} : () => {}}
    >
      <Link to={path} >
        <img 
          src={currentImage}  
          alt="imagem" 
        />
        <span>
          {name}
        </span>
      </Link>
    </li>
  );
}

export default ItemMenu;