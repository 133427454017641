import styled from 'styled-components';
import {
  colors,
  spacings,
  borders,
  fontWeights,
  fontSizes
} from '../../../styles/theme'

export const HeaderContainer = styled.header`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    left: 88px;
    width: calc(100% - 88px);
    height: 115px;
    border-bottom: 1px solid ${colors.neutral_regular};
    background: ${colors.white};

    .header-text{
      display: flex;
      padding-bottom: 0;
      
      h1{
        padding-bottom: ${spacings.sl};
        display: inline;
        border-bottom: ${borders.sm} solid ${colors.primary};
      }

      h2{
        margin-right: ${spacings.xs};
      }
    }
`

export const CardContent = styled.div`

  display: flex;
  margin-bottom: ${spacings.lg};

  > div{
    width: 280px;
    margin-right: ${spacings.lg};

    a{
      text-decoration: none;
    }
    
    span{
      font-size: ${fontSizes.xs};
      font-weight: ${fontWeights.w700};
    }
  }
`

export const CustumerDetailsCard = styled.div`

  .card-body-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
    margin-bottom: ${spacings.xl};
    
    &:last-child{
      margin-bottom: ${spacings.ls};
    }
  }

  .card-body-input{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
  }

  .verify-identity{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacings.md};
    border-radius: ${borders.md};
    background: ${colors.neutral_light};

    h3{
      margin-right: ${spacings.md};
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
    }

    span{
      position: relative;
      width:24px;
      height: 24px;
      background: ${colors.secondary_one};
      border: ${borders.xs} solid ${colors.secondary_one};
      border-radius: ${borders.sm};

      &:after { 
        content: '';
        position: absolute;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 8px;
        border: solid ${colors.white};
        border-width: 0 ${borders.xs} ${borders.xs} 0;
        transform: rotate(45deg);}
    }
  }
`
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;

  div{
    h3{
      margin-bottom: ${spacings.sl};
    }
  }
`