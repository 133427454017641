import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import { useAlert } from '../../../hooks/useAlert';

import { GoToBack } from '../../../components/GoToBack';
import { Header } from '../../../components/Header';
import { Menu } from '../../../components/Menu';
import { Title } from '../../../components/Title';
import { Card } from '../../../components/Card';
import { InputFormField } from '../../../components/InputFormField';
import { Button } from '../../../components/Button';
import { BreadCrumb } from '../../../components/BreadCrumb';
import { Loading } from '../../../components/Loading';

import { convertDateToShowInput } from '../../../utils/formattingDate';
import { maskCEP, maskPhone } from '../../../utils/masks';
import { iconEmailWhite, iconMessageWhite, iconTicketWhite } from '../../../assets/icons';
import { CardContent, CardFooter, CustumerDetailsCard, HeaderContainer } from './styles';
import { menuListItems } from '../../../assets/menu/menuList';
import { formattingCPF } from '../../../utils/formattingCPF';
import { useToast } from '../../../hooks/useToast';

interface WinnerProps {
  id: string;
  description: string;
  ballotId: string;
}

interface CouponProps {
  code: string,
  origin: string,
  createDate: string,
  ballotBoxCouponCount: 0
}

interface CustomerProps {
  customerId: string;
  name: string,
  email: string,
  cpf: string,
  phoneNumber: string,
  verifiedDate: string,
}

interface StatusProps {
  whatsApp: string | null,
  email: string | null,
  winner: string | null,
}

interface AddressProps {
  zipcode: string,
  address: string,
  district: string,
  addressNumber: string,
  addressComplement: string,
  city: string,
  uf: string
}

interface InputsEncryptedProps {
  email: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
  phoneNumber: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
  cpf: {
    initialValue: string,
    currentStateEncrypted: boolean,
    isLoading: boolean,
  },
}

type FieldDataDecriptedProps = "email" | "cpf" | "phoneNumber";

const initFieldsEncryptedIsChanged = {
  email: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
  phoneNumber: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
  cpf: {
    initialValue: "",
    currentStateEncrypted: true, 
    isLoading: false, 
  },
}

export const WinnerDetails: React.FC = () => {
  const { connectAPI, cancelRequest } = useAuth();
  const { setToast } = useToast();
  const { openAlert, closeAlert, alert, setAlertPosition, setTextAlertInterface } = useAlert();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingEmail, setIsLoadingEmail ] = useState(false);
  const { id, description, ballotId } = useParams<WinnerProps>();
  const [ couponData, setCouponData ] = useState<CouponProps>();
  const [ customerData, setCustomerData ] = useState<CustomerProps>();
  const [ addressData, setAddressData ] = useState<AddressProps>();
  const [ statusData, setStatusData ] = useState<StatusProps>();
  const [ contactWithWinner, setContactWithWinner ] = useState([""]);
  const [ fieldsEncryptedIsChanged, setFieldsEncryptedIsChanged ] = useState<InputsEncryptedProps>(initFieldsEncryptedIsChanged);  
  const [ isLoadingRequestInputEncrypted, setIsLoadingRequestInputEncrypted ] = useState(false);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();

  const debounceFN = (fn: any, value: string) => {
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      fn(value);
    }, 600)

    setDebounce(timer);
  }

  const sendEmailToWinner = async () => {
    setIsLoadingEmail(true)
 
    const response = await connectAPI({
      url: `lotterys/ballotboxes/${ballotId}/email`, 
      body: {couponId: id}, 
      type: "post",
      messageError: "Falha ao enviar email"
    });

    if(response.connect){
      getWinnerData();
    }
    setIsLoadingEmail(false)
  }

  const getWinnerData = async () => {   
    const response = await connectAPI({
      url: `coupons/${id}`, 
      type: "get",
      messageError: "Falha ao carregar dados do ganhador"
    });
    
    if(response.connect){
      const data = response.data;
      setCouponData(data.coupon);
      setCustomerData(data.customer);
      setAddressData(data.address);
      setStatusData({
        whatsApp: data.status.whatsApp ? 
        convertDateToShowInput(data.status.whatsApp)
        : data.status.whatsApp,
        email: data.status.email ? 
          convertDateToShowInput(data.status.email) 
          : data.status.email,
        winner: data.status.winner ? 
          convertDateToShowInput(data.status.winner)
          : data.status.winner,
      });

      setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
        email: {...initFieldsEncryptedIsChanged.email, initialValue: data.customer.email},
        phoneNumber: {...initFieldsEncryptedIsChanged.phoneNumber, initialValue: data.customer.phoneNumber},
        cpf: {...initFieldsEncryptedIsChanged.cpf, initialValue: data.customer.cpf},
      })
    }
  }

  const loadDataDecripted = async (field: FieldDataDecriptedProps) => {
    if(!isLoadingRequestInputEncrypted){
      if(fieldsEncryptedIsChanged[field].currentStateEncrypted){
        setIsLoadingRequestInputEncrypted(true);
        setFieldsEncryptedIsChanged({
          ...fieldsEncryptedIsChanged,
          [field]: {
            ...fieldsEncryptedIsChanged[field], 
            isLoading: true
          }
        })
        
        const response = await connectAPI({
          url: `/customers/${customerData?.customerId}/unmask?field=${field}`,
          type: "get",
          messageError: "Falha ao exibir dado."
        })

        if(response.connect){
          setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
            [field]: {
              ...fieldsEncryptedIsChanged[field], 
              currentStateEncrypted: false,
              isLoading: false,
            }
          })
          customerData && setCustomerData({
            ...customerData,
            [field]: response.data[field]
          })
        }else{
          setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
            [field]: {
              ...fieldsEncryptedIsChanged[field], 
              isLoading: false,
            }
          })
        }
        setIsLoadingRequestInputEncrypted(false);
      }else{
        setFieldsEncryptedIsChanged({...fieldsEncryptedIsChanged,
          [field]: {
            ...fieldsEncryptedIsChanged[field], 
            currentStateEncrypted: true,
            isLoading: false,
          }
        })

        customerData &&  setCustomerData({
          ...customerData,
          [field]: fieldsEncryptedIsChanged[field].initialValue
        })
      }
    }else{
      setToast({
        message: `Favor aguardar a requisição em execução.`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }
  }

  const setSelectedWinner = async () => {
    setIsLoading(true);
    
    const response = await connectAPI({
      url: `lotterys/ballotboxes/${ballotId}/winner`, 
      body: {couponId: id}, 
      type: "post",
      messageError: "Falha ao salvar seller como ganhador"
    });

    if(response.connect){
      setContactWithWinner([...contactWithWinner, "winner"]);
    }      
    setIsLoading(false);
  }

  const contactWinnerByWhastApp = () => {
    const link = document.createElement('a');
    link.href = `https://api.whatsapp.com/send?phone=${customerData?.phoneNumber}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();

    if(!statusData?.whatsApp){
      setContactByWhatsApp();
      getWinnerData();
    }
  }

  const setContactByWhatsApp = async () => {
    try {
      await connectAPI({
        url: `coupons/${id}/whatsapp`, 
        body: {}, 
        type: "put",
        messageError: "Falha ao registrar contato com o ganhador",
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getConfirmation = () => {
    setSelectedWinner();
    closeAlert();
  }

  const localAlert = () => {
    setAlertPosition("center");
    setTextAlertInterface({
      title: "Marcar como vencedor?",
      description: "Atenção! Essa ação não pode ser revertida.",
      closeAlertText: "Voltar",
      continueAlertText: "Sim",      
      closeAlertTypeBtn: "outline",
      continueAlertTypeBtn: "blue"
    })
    openAlert(true);
  }

  useEffect(() => {
    alert.typeContinue === "save" && getConfirmation();
  }, [alert])

  useEffect(() => {
    getWinnerData();

    return () => {
      setCouponData(undefined);
      setCustomerData(undefined);
      setAddressData(undefined);
      setStatusData(undefined);
      cancelRequest.cancel("cancel");
    }
  }, [])

  useEffect(() => {

  }, [couponData, customerData ,addressData, statusData, fieldsEncryptedIsChanged])

  if(couponData && customerData){
    return (
      <Menu links={menuListItems}> 
        <Header>
          <HeaderContainer>
            <div>
              <GoToBack path={`/lottery/ballot-details/${ballotId}`}/>
            </div>
    
            <div className="header-text">
              <BreadCrumb itens={[description, "Detalhes da Urna", customerData.name]} />
            </div>
          </ HeaderContainer>
        </Header>
  
        <Card title="Sorteio">
          <CardContent>
            <div>
              <Button
                name="whatsapp" 
                label="Entrar em contato - Whatsapp" 
                icon={iconMessageWhite} 
                disabled={!!statusData?.whatsApp}
                typeBtn="blue"
                block={true}
                onClick={contactWinnerByWhastApp}
                spaceBetween
              />
              <span>
                {statusData?.whatsApp ? `Contato feito em ${statusData?.whatsApp}` : "Contato não foi feito"}
              </span>
            </div>
            <div>
              <Button 
                name="email"
                label="Enviar email de confirmação" 
                icon={iconEmailWhite}
                typeBtn="blue" 
                disabled={!statusData?.whatsApp || !!statusData?.email}
                onClick={sendEmailToWinner}
                block={true}
                load={isLoadingEmail}
                spaceBetween={!isLoadingEmail}
              />
              <span>
                {statusData?.email ? `Email enviado em ${statusData?.email}` : ""}
              </span>
            </div>
            <div>
              <Button 
                name="winner"
                label="Marcar como vencedor" 
                icon={iconTicketWhite} 
                disable={(!statusData?.email && !statusData?.winner) || !!statusData?.winner}
                typeBtn="blue"               
                onClick={localAlert}
                load={isLoading}
                block={true}
                spaceBetween={!isLoading}
              />
              <span>
                {statusData?.winner ? `Marcado como vencedor em ${statusData?.winner}` : ""}
              </span>
            </div>
          </CardContent>
  
          <CardFooter>
            <div>
              <Title text="Cupom sorteado" elementHTML="h3" className="subtitle-small" />
              <Title text={couponData.code} elementHTML="p" className="paragraph" />
            </div>
            <div>
              <Title text="Origem" elementHTML="h3" className="subtitle-small" />
              <Title text={couponData.origin} elementHTML="p" className="paragraph" />
            </div>
            <div>
              <Title text="Gerado em:" elementHTML="h3" className="subtitle-small" />
              <Title text={convertDateToShowInput(couponData.createDate)} elementHTML="p" className="paragraph" />
            </div>
            <div>
              <Title text="Cupons nesta urna:" elementHTML="h3" className="subtitle-small" />
              <Title text={String(couponData.ballotBoxCouponCount)} elementHTML="p" className="paragraph" />
            </div>
          </CardFooter>
        </Card>
  
        <Card title="Dados pessoais" >
          <CustumerDetailsCard>
            <div className="card-body">
              <div className="card-body-wrapper">
                <InputFormField
                  name="name"
                  label="Nome completo"
                  value={customerData.name}
                  backGround="white"
                  block={true}
                  disabled
                  marginBotton="0"
                  onChange={() => { }}
                />
  
                <InputFormField
                  name="email"
                  label="E-mail"
                  value={customerData.email}
                  backGround="white"
                  block={true}
                  disabled
                  mensagem="Verificado em 26/07/2021"
                  marginBotton="0"
                  onChange={() => { }}
                  animation={fieldsEncryptedIsChanged.email.isLoading}
                  isEncrypted={true}
                  currentStateEncrypted={fieldsEncryptedIsChanged.email.currentStateEncrypted}
                  onClick={() => debounceFN(loadDataDecripted,"email")}
                />
              </div>
  
              <div className="card-body-wrapper">
                <div className="card-body-input">
                  <InputFormField
                    name="cpf"
                    label="CPF"
                    value={formattingCPF(customerData ? customerData.cpf : "")}
                    backGround="white"
                    block={true}
                    disabled
                    marginBotton="0"
                    onChange={() => { }}
                    animation={fieldsEncryptedIsChanged.cpf.isLoading}
                    isEncrypted={true}
                    currentStateEncrypted={fieldsEncryptedIsChanged.cpf.currentStateEncrypted}
                    onClick={() => debounceFN(loadDataDecripted,"cpf")}
                  />
  
                  <InputFormField
                    name="phoneNumber"
                    label="Telefone"
                    value={customerData.phoneNumber}
                    backGround="white"
                    block={true}
                    disabled
                    mensagem="Verificado em 26/07/2021"
                    marginBotton="0"
                    onChange={() => { }}
                    mask={!fieldsEncryptedIsChanged.phoneNumber.currentStateEncrypted && maskPhone}
                    animation={fieldsEncryptedIsChanged.phoneNumber.isLoading}
                    isEncrypted={true}
                    currentStateEncrypted={fieldsEncryptedIsChanged.phoneNumber.currentStateEncrypted}
                    onClick={() => debounceFN(loadDataDecripted,"phoneNumber")}
                  />
                </div>
  
                <div className="verify-identity">
                  <h3>{ customerData.verifiedDate ? `Identidade verificada em ${convertDateToShowInput(customerData.verifiedDate)}` : "Identidade não verificada"}</h3>
                  {customerData.verifiedDate && <span></span>}
                </div>
              </div>
  
            </div>
          </CustumerDetailsCard>
        </Card>
  
        <Card title="Endereço">
          <CustumerDetailsCard>
            <div className="card-body">
  
              <div className="card-body-wrapper">
                <InputFormField
                  name="zipcode"
                  label="CEP"
                  value={addressData?.zipcode || ""}
                  typeInput="text"
                  backGround="white"
                  block={true}
                  mask={maskCEP}
                  icon={true}
                  disabled
                  animation={false}
                  marginBotton="0"
                  onChange={() => { }}
                />
  
                <InputFormField
                  name="uf"
                  label="Estado"
                  value={addressData?.uf || ""}
                  typeInput="text"
                  backGround="white"
                  block={true}
                  disabled
                  marginBotton="0"
                  onChange={() => { }}
                />
  
                <InputFormField
                  name="city"
                  label="Cidade"
                  value={addressData?.city || ""}
                  typeInput="text"
                  backGround="white"
                  block={true}
                  disabled
                  marginBotton="0"
                  onChange={() => { }}
                />
  
                <InputFormField
                  name="neighborhood"
                  label="Bairro"
                  value={addressData?.district || ""}
                  typeInput="text"
                  backGround="white"
                  block={true}
                  disabled
                  marginBotton="0"
                  onChange={() => { }}
                />
              </div>
  
              <div className="card-body-wrapper">
                <InputFormField
                  name="address"
                  label="Logradouro"
                  value={addressData?.address || ""}
                  typeInput="text"
                  backGround="white"
                  block={true}
                  disabled
                  marginBotton="0"
                  onChange={() => { }}
                />
  
                <div className="card-body-input">
                  <InputFormField
                    name="addressNumber"
                    label="Número"
                    value={addressData?.addressNumber || ""}
                    typeInput="text"
                    backGround="white"
                    block={true}
                    disabled
                    marginBotton="0"
                    onChange={() => { }}
                  />
  
                  <InputFormField
                    name="addressComplement"
                    label="Complemento"
                    value={addressData?.addressComplement || ""}
                    typeInput="text"
                    backGround="white"
                    block={true}
                    disabled
                    marginBotton="0"
                    onChange={() => { }}
                  />
                </div>
              </div>
  
            </div>
          </CustumerDetailsCard>
        </Card>
  
      </Menu>
    );
  }

  return (
    <Menu links={menuListItems}> 
      <Header>
        <HeaderContainer>      
          <div>
            <GoToBack path={`/lottery/ballot-details/${ballotId}`}/>
          </div>

          <div className="header-text">
            <BreadCrumb itens={[description, "Detalhes da Urna"]} />
          </div>
        </ HeaderContainer>
      </Header>
      <Loading/>
    </Menu>
  )
}