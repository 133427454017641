import styled, { keyframes, css } from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontSizes,
  fontWeights,
  shadows
} from '../../styles/theme'

interface SelectProps {
  selected: boolean,
  changeBorderColor: boolean,
  minWidthOptions?: number,
  appearWhere?: string,
}

const fade = keyframes`
  from {
    opacity: 0;
    transform: scale(0)
  },
  to{
    opacity: 1;
    transform: scale(1)
  }
`

export const SelectCuston = styled.div<SelectProps>`
  display: inline-flex;
  position: relative;
  margin-right: ${spacings.md};
  cursor: pointer;

.wrapper{
  display: inline-flex;
  align-items: center;
  max-width: auto;
  height: 40px;
  border-radius: ${borders.md};
  padding: ${spacings.sl};
  background-color: ${props => props.selected ? `${colors.neutral_regular}` : `${colors.white}`};
  transition: all 0.2s linear;  
  border: ${borders.xs} solid ${props => props.changeBorderColor ? `${colors.primary}` : `${colors.neutral_regular}`};

  &:hover{
    border: ${borders.xs} solid ${colors.primary};
  }

  .select {
    pointer-events: none;
    transition: all 0.2s linear;

    strong{
      font-family: DM Sans;
      font-style: normal;
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
    }
  }

  button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: ${spacings.sl};
    background: ${colors.neutral_regular};
    border-radius: ${borders.sm};
    border: none;
    
    svg{
      transition: all 0.2s linear;
      ${(props) => props.selected && css`
        animation: arrowEffectButton 5s linear infinite;
        transform: ${props.appearWhere === "top" ? css`rotate(0deg)` : css`rotate(180deg)`};
      `};
    }
  
    &:hover {
      filter: brightness(0.9);
    }
  }
}


.select-options{
  ${props => props.selected ? "display: block;" : "display: none;"}
  position: absolute;
  ${props => props.appearWhere === "top" ? "top: 45px;" : "bottom: 45px;"}
  left: 0px;
  width: 100%;
  min-width: ${props => props.minWidthOptions}%;
  width: auto;
  height: auto;
  max-height: 200px;
  border-radius: ${borders.lg};
  background: ${colors.white};
  overflow: auto;
  box-shadow: ${shadows.sm};
  animation: ${fade} 0.2s linear;

  .select-options-content{
    display: flex;
    flex-direction: column;

      .select-options-input{
        margin-top: ${spacings.md};
        margin: ${spacings.md} ${spacings.sm} 0px ${spacings.md};
      }
    }

    .select-options-item{
      display: flex;
      align-items: center;
      padding: ${spacings.sm};

      &.isActive{
        span{
          background: ${colors.primary};
        }
      }

      span{
        display: inline-block;
        height: 15px;
        width: 15px;
        margin-right: ${spacings.sm};
        border-radius: 50%;
        border: ${borders.xs} solid ${colors.primary};
        pointer-events: none;
      }
      
      strong{
        font-family: DM Sans;
        font-weight: ${fontWeights.w700};
        font-size: ${fontSizes.sm};
        color: ${colors.neutral_darkest};
        white-space: nowrap;
        pointer-events: none;
      }

      &:hover{
        background-color: ${colors.neutral_light};
      }
    }
}
`

export const Content = styled.div`

`