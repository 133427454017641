import styled from "styled-components";
import { colors, borders, spacings, fontWeights, fontSizes } from "../../styles/theme";

export const TabNavHeader = styled.div` 
    display: flex;
    position: absolute;
    bottom: 0;

    li{
      flex: 1;
      list-style: none;
      margin-right: ${spacings.ls};

      &:last-child{
        margin-right: 0;
      }

      button{
        width: 100%;
        white-space: nowrap;
        font-weight: ${fontWeights.w700};
        font-size: ${fontSizes.md};
        color: ${colors.neutral_darker};
        border: none;
        background-color: transparent;
        border-bottom: ${borders.sm} solid transparent;
        padding-bottom: ${spacings.sm};

        &.isActive{
          color: ${colors.neutral_darkest};
          border-bottom: ${borders.sm} solid  ${colors.primary};
        }
      }
    }
`

export const TabNavBtn = styled.div` 
    display: flex;
    align-items: center;
`