export type ValueInputTypes =
  'email' | 
  'characterSpecial' | 
  'characterNumber' | 
  'characterUpper' | 
  'spaceEmpty' | 
  'minCharacter' | 
  'maxCharacter' | 
  'zipcode' 

export interface ValidateInputProps {
  name: string,
  valueInput: string,
  validateOptions: ValueInputTypes[],
  min?: number,
  max?: number
}

interface ValidatedProps {
  hasError: boolean,
  validatedObject: ValidatedObjectProps
}

interface ValidatedObjectProps {
  [input: string]: {

    /**
     * name of the field
     */
    name: string,

    /**
     * if the field is validated or not
     */
    isValidated: boolean,

    /**
     * array of failed validations
     */
    typeError: ValueInputTypes[],

    /**
     * value tested
     */
    valueInput: string,
    valueLength: number
  }
}

const regexValidation = (itemValidation: ValueInputTypes[], value: string, min?: number, max?: number): [boolean[], ValueInputTypes[]] => {
  var isError: boolean[] = [];
  var typeError: ValueInputTypes[] = [];
  itemValidation.forEach((item, index) => {
    const regexItem = getRegexOption(item, min, max);
    let isRegexValidate = regexItem ? RegExp(regexItem).test(value) : true;

    if (!isRegexValidate) {
      typeError.push(itemValidation[index])
    }

    isError.push(isRegexValidate);
  })

  return [isError, typeError];
}

const getRegexOption = (props: ValueInputTypes, min?: number, max?: number) => (
  {
    "email": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "characterSpecial": /[^a-zA-Z0-9]+/,
    "characterNumber": /^(?=.*?[0-9]).{1,}$/,
    "characterUpper": /^(?=.*?[A-Z]).{1,}$/,
    "spaceEmpty": /^\S+$/,
    "minCharacter": `^.{${min},}$`,
    "maxCharacter": `^.{0,${max}}$`,
    "zipcode": `[0-9]{5}-[0-9]{3}`,
  }
)[props] || null;

/**
 * 
 * @ValidatedProps 
 * {
 *   hasError: boolean,
 *   validatedObject: ValidatedObjectProps
 * }
 * @ValidatedObjectProps
 * [input: string]: {
    name: string,
    isValidated: boolean,
    typeError: ValueInputTypes[],
    valueInput: string,
    valueLength: number
  }
 */
export const isValidatedInput = (validateInputs: ValidateInputProps[]): ValidatedProps => {
  const objValidate:ValidatedObjectProps[] = validateInputs.map(item => {
    const isRegexValidation = regexValidation(item.validateOptions, item.valueInput, item.min, item.max);
    const valueLngth = item.valueInput ? item.valueInput.length : 0
    const valueItem = item.valueInput ? item.valueInput : ""
    const [isError, typeError] = isRegexValidation;

    return {
      [item.name]: {
        name: item.name,
        valueInput: valueItem,
        valueLength: valueLngth,
        isValidated: !isError.some((item: boolean) => item === false),
        typeError: typeError
      }
    }    
  })
  const assingObj = Object.assign({}, ...objValidate);
  
  let arrError = []
  for (var [key] of Object.entries(assingObj)) {
    arrError.push(assingObj[key].isValidated)
  }

  return {
    hasError: arrError.some((bool: any) => bool === false),
    validatedObject: assingObj,
  };
}