import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Title } from '../../components/Title';
import { Select } from '../Select';

import { Container, ContainerTable, ItemsNavigation, ItemsPerPage, TablePagination } from './styles';

interface TableProps {
  children?: ReactNode
  title?: string,
  headerTable: string[],
  bodyTable: any[],
  listRowsPerPage?: string[],
  quantityButtonsOnPaginate?: number,
  showPagination?: boolean,
}

const svgArrowChevron = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 6L5.7035 1.59811C5.83111 1.4673 6.0638 1.4673 6.19141 1.59811L10.5 6" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const TableSimple: React.FC<TableProps> = ({
  headerTable,
  bodyTable,
  title,
  listRowsPerPage,
  quantityButtonsOnPaginate,
  showPagination = true,
}) => {

  const [totalPages, setTotalPages] = useState<any[]>([]);  
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(quantityButtonsOnPaginate || 5);
  const [pageNumberLimit, setpageNumberLimit] = useState(quantityButtonsOnPaginate || 5);
  const [dataList, setDataList] = useState(bodyTable);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1);

  const debounceFN = (fn: any, value?: string) => {    
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      value ? fn(value) : fn();
    }, 700)

    setDebounce(timer);
  }

  const getRandom = useCallback(() => {
    const randon = Math.floor(Math.random() * (50000000 - 1)) + 1;
    return randon;
  }, [])

  const quantitityPages = () => {    
    const calcTotalPages = Math.ceil(bodyTable.length / rowsPerPage);

    const array = [];

    if (calcTotalPages > 0) {
      for (let i = 1; i <= calcTotalPages; i++) {
        array.push(i);
      }
      setTotalPages(array);
    }
  }

  function handleNextPage() {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage-1) === minPageNumberLimit) {
      setMaxPageNumberLimit(minPageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  const handleGoToLastPage = () => {
    setCurrentPage(totalPages[totalPages.length - 1]);    
    
    setMaxPageNumberLimit(totalPages[totalPages.length - 1]);
    setMinPageNumberLimit(totalPages[totalPages.length - 1] - ((totalPages[totalPages.length - 1] % pageNumberLimit)));
  }

  const handleGoToInitialPage = () => {
    setCurrentPage(1);

    if (currentPage > 5) {
      setMaxPageNumberLimit(5);
      setMinPageNumberLimit(0);
    }
  }

  const renderPageNumbers = totalPages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <button
          type="button"
          className={`button-paginate ${currentPage === number ? 'isActive' : ''}`}
          key={number}
          onClick={(event: any) => debounceFN(selectCurrentPage, event)}
          value={number}
        >
          {number}
        </button>
      )
    } else {
      return null;
    }
  });

  const Row = ({ record }: any) => {
    const keys = Object.keys(record);

    return (
      <tr key={getRandom()}>
        {
          keys.map((key) => {
            return <td key={getRandom()}>{record[key]}</td>
          })
        }
      </tr>
    )
  }

  function selectCurrentPage(event: any) {
    setCurrentPage(Number(event.target.value));    
  }

  const changeParamsToRenderTable = () => {
    const list = bodyTable.slice((currentPage === 1 ? currentPage-1 : (currentPage-1)*rowsPerPage), currentPage*rowsPerPage);
    setDataList(list);
  }

  const changeSelect = (value: number) => {
    setCurrentPage(1);
    setRowsPerPage(value);
  }

  useEffect(() => {    
    const initialItemsPerPage = listRowsPerPage ? Number(listRowsPerPage[0].split(" ", 1)) : 10;
    setRowsPerPage(initialItemsPerPage)
  }, [])

  useEffect(() => { 
    
  }, [dataList]);
  
  useEffect(() => {
    changeParamsToRenderTable(); 
    quantitityPages();
  }, [rowsPerPage, currentPage]);

  return (
    <Container >
      {title && <div className="card-header">
        <Title elementHTML="h1" text={title} className="subtitle-medium" />
      </div>}

      <ContainerTable >
        <table>
          <thead>
            <tr>
              {headerTable.map(headItem => (
                <th key={headItem}>
                  {headItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              dataList.map((record, index) => (
                <Row key={getRandom()} record={record} index={index} />
              ))
            }
          </tbody>
        </table>
      </ContainerTable>

      {!showPagination ? ("") : ( listRowsPerPage && listRowsPerPage[0] && 
        <TablePagination>
        
        <ItemsPerPage>
          <div className="total-itens">
            <strong>Exibindo:</strong>

            {bodyTable.length <= Number(listRowsPerPage[0].split(" ", 1)) ? (
              <span>{dataList.length} de {dataList.length}</span>
            ) : (
              <Select 
                name={!!listRowsPerPage[0] ? listRowsPerPage[0] : "5"}
                value={listRowsPerPage[0]} 
                appearWhere="bottom" 
                optionsValue={listRowsPerPage} 
                optionsDescription={listRowsPerPage} 
                onChange={(e:any) => changeSelect(e.valueId.split(" ")[0])}
                size="small"
              />
            )}

          </div>
        </ItemsPerPage>

        <ItemsNavigation>
          <div className="paginate">
            
            {currentPage > 5 && (
              <>
                <button 
                  type="button"
                  className={`button-paginate ${currentPage === 1 ? 'isActive' : ''}`}
                  onClick={() =>debounceFN(handleGoToInitialPage)}
                  disabled={currentPage === 1}
                  value={1}
                >
                  Primeira
                </button>

                <span className="paginate-dot">
                  <strong>.</strong>
                  <strong>.</strong>
                  <strong>.</strong>
                </span>
              </>
            )}

            {currentPage !== 1 && (
              <button
                type="button"
                className="button-paginate arrow-left"
                onClick={() =>debounceFN(handlePreviousPage)}
                style={currentPage === totalPages[0] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                disabled={currentPage === totalPages[0] ? true : false}
              >
                {svgArrowChevron}
              </button>
            )}

            {renderPageNumbers}

            {currentPage !== totalPages[totalPages.length-1] && (
              <button
                type="button"
                className="button-paginate arrow-right"
                onClick={() =>debounceFN(handleNextPage)}
                style={currentPage === totalPages[totalPages.length - 1] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                disabled={currentPage === totalPages[totalPages.length - 1] ? true : false}
              >
                {svgArrowChevron}
              </button>
            )}

            {currentPage < (totalPages[totalPages.length - 1] - 5) && (
              <>
                <span className="paginate-dot">
                  <strong>.</strong>
                  <strong>.</strong>
                  <strong>.</strong>
                </span>

                <button 
                  type="button"
                  className={`button-paginate button-paginate-last ${currentPage === totalPages[totalPages.length-1] ? 'isActive' : ''}`}
                  onClick={() =>debounceFN(handleGoToLastPage)}
                  style={currentPage === totalPages[totalPages.length - 1] ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                  disabled={currentPage === totalPages[totalPages.length - 1] ? true : false}
                  value={totalPages[totalPages.length-1]}
                >
                  Última
                </button>
              </>
            )}
            
          </div>
        </ItemsNavigation>

      </TablePagination>
      )}
    </Container>
  )
}
