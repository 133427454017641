import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSession } from '../hooks/useSession';

interface PrivateRouteProps extends RouteProps { }

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...res }) => {
  const {getSession} = useSession();

  const session = getSession();

  return session ? <Route {...res} />
  : <Redirect to={{ pathname: '/', state: { from: res.location } }} />
}
  