import { colors as variableColors } from './variables'
/**
 * @Description <br/>
 * Colors:  variableColors._Define a cor e tonalidade._
 * _Sua nomenclatura informa por sua cor e nível de tonalidade, partindo de 100 (mais claro) a 900 (mais escuro), algumas cores podem não ter a cartela de tonalidades completa._
 * 
 * @Available <br/>
 * _white | gray | blue | green | yellow | orange | red | purple | mint | beige | pink._
 * 
 * @Example <br/>
 * _Use colors.blue300 para color = "#4045CC"._
 */

export const colors = {  
  //PRIMARY COLORS
  primary:  variableColors.blue200,
  primary_dark:  variableColors.blue700,
  primary_hover:  variableColors.blue300,
  primary_hover_dark:  variableColors.blue800,
  primary_click:  variableColors.blue400,
  primary_click_dark:  variableColors.blue900,

  //SECONDARY COLORS
  secondary_one:  variableColors.green300,
  secondary_one_light:  variableColors.green200,
  secondary_one_hover:  variableColors.green400,
  secondary_one_click:  variableColors.green500,

  secondary_two:  variableColors.yellow300,
  secondary_two_light:  variableColors.yellow200,
  secondary_two_hover:  variableColors.yellow400,
  secondary_two_click:  variableColors.yellow500,

  secondary_three:  variableColors.orange400,
  secondary_three_light:  variableColors.orange200,
  secondary_three_hover:  variableColors.orange500,
  secondary_three_click:  variableColors.orange700,

  secondary_four:  variableColors.red400,
  secondary_four_light:  variableColors.red200,
  secondary_four_hover:  variableColors.red500,
  secondary_four_click:  variableColors.red600,

  //SUPPORT COLORS
    /**
   * @color #CACEFF
   */
  support_one:  variableColors.purple200,
  support_one_light:  variableColors.purple100,     //    ${colors.}

  support_two:  variableColors.mint200,
  support_two_light:  variableColors.mint100,

  support_three:  variableColors.beige200,
  support_three_light:  variableColors.beige100,

  support_four:  variableColors.pink200,
  support_four_light:  variableColors.pink100,

  //NEUTRAL COLORS
  white:  variableColors.white100,
  neutral_light:  variableColors.gray100,
  neutral_regular:  variableColors.gray200,
  neutral_medium:  variableColors.gray300,
  neutral_darker:  variableColors.gray400,
  neutral_darkest:  variableColors.gray700,
  transparent: variableColors.transparent,

  //SHADOWS COLORS
  shadow_light:  variableColors.grayOpacity05,
  shadow_regular:  variableColors.grayOpacity08,
  shadow_medium:  variableColors.grayOpacity12,
}
