import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "../../../../hooks/useAuth";

import { Badge } from "../../../../components/Badge";
import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Loading } from "../../../../components/Loading";
import { TableSimple } from "../../../../components/TableSimple";
import { Tooltip } from "../../../../components/Tooltip";
import { PlaceHolderNotExists } from "../../../../components/PlaceholderNotExists";

import { copyToClipboard } from "../../../../utils/copyToClipboard";
import { formattingCurrency } from "../../../../utils/formattingCurrency";
import { formattingDate } from "../../../../utils/formattingDate";
import { iconCopyWhite, iconEmailWhite } from "../../../../assets/icons";

interface RequestProps {
  transactionId: string,
  amount: number,
  logo: string,
  nickName: string,
  surveyUrl: string,
  couponCode: string,
  transactionItens: string[],
  transactionStatus: string,
  transactionDate: string,
}

interface ParamsURLProps {
  id: string;
}

const initTableHeadHistoric = ["Data pedido", "Status", "Loja", "Itens", "Valor total", "Cupom", ""];

export const Requests: React.FC = () => {
  const { id } = useParams<ParamsURLProps>();
  const { connectAPI, cancelRequest } = useAuth();
  const [ requestsData, setRequestsData ] = useState<RequestProps[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const onLoadingDataRequest = async () => {
    setIsLoading(true);

    const response = await connectAPI({
      url: `transaction?customerId=${id}`,
      type: "get", 
      messageError: "Falha ao listar transações"
    });

    const {data} = response.connect ? response.data : [];
    setRequestsData(data);
    setIsLoading(false);    
  }

  const handleClickReesend = async (event: any) => {
    const transactionId = event.target.value;

    await connectAPI({
      url: `transaction/${transactionId}/send-email`, 
      body: {}, 
      type: "post", 
      messageError: "Falha ao enviar email",
      messageSuccess: "E-mail enviado com sucesso!",
    });
  }

  const rowItemsTable = ({...props}: RequestProps) => {
    const newArray: any = [];
    const limitItems = 2;

    if(props.transactionItens && props.transactionItens.length <= 0) return

    props.transactionItens.map((item, index) => (  
      index < limitItems && (newArray.push(<div>{item}</div>))
    ))

    if(props.transactionItens.length > limitItems) {
      const overItem = props.transactionItens.length-limitItems;
      newArray.push(<Badge 
        fontSize={14} 
        fontWeight={500}
        margin="8px 0 0 0" 
        key={props.transactionItens.length} 
        value={String(`+${overItem} ${overItem > 1 ? "itens" : "item"}`)}
      />)
    }

    return newArray;
  }

  const storeTable = ({ ...props}: RequestProps) => {
      return (
        <div className="tbody-flex">
          <figure>
            <img src={props.logo} alt="" />
          </figure>
          <span>{props.nickName}</span>
        </div>
      )
  } 

  const buttonTable = ({ ...props }: RequestProps) => {
    return (
      <div className="tbody-flex">
        {props.surveyUrl ?
          <>
            <Tooltip text="Copiar link pesquisa">
              <Button 
                label=""
                typeBtn="blue" 
                value={props.surveyUrl} 
                size="small" 
                icon={iconCopyWhite} 
                onlyIcon={true} 
                onClick={copyToClipboard} 
              />
            </Tooltip>
          </>
          : null
        }

        <Tooltip text="Enviar email de pesquisa">
          <Button 
            label="" 
            value={props.transactionId} 
            typeBtn="blue"
            size="small" 
            icon={iconEmailWhite}
            onlyIcon={true} 
            onClick={handleClickReesend} 
          />
        </Tooltip>
      </div>
    )
  }

  const newData = () => {
    const newArray: any = [];

    requestsData.map(item => {
      newArray.push({
        transactionDate: formattingDate(item.transactionDate, true),
        transactionStatus: item.transactionStatus,
        nickName: storeTable(item),
        transactionItens: rowItemsTable(item),
        amount: formattingCurrency(item.amount),
        couponCode: item.couponCode ? item.couponCode : "Sem Cupon",
        tdButton: buttonTable(item)
      })

      return null
    })
    return newArray;
  }

  useEffect(() => {
    onLoadingDataRequest();

    return () => {
      setRequestsData([])
      cancelRequest.cancel("cancel")
    };
  }, [])

  if (isLoading) {
    return (
      <Card title="Pedidos">
        <Loading />
      </Card>
    )
  }

  if (!isLoading && requestsData){
    return (
      <Card title="Pedidos">
        <TableSimple
          headerTable={initTableHeadHistoric}
          bodyTable={newData()}          
          listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
          quantityButtonsOnPaginate={5}
        />
      </Card>
    )
  }

  return (
    <Card title="Pedidos">
      <PlaceHolderNotExists title="Usuário não possui pedidos" />
    </Card>
  )
}