export function formattingOnlyNumber(value: number | string): string {
  let clearWords = String(value).replace(/\D+/g, '');
  let transformValue = clearWords.replace(/(.)(?=(\d{3})+$)/g, '$1.');

  return transformValue === undefined || !transformValue ? "" : transformValue;
}

export function formattingOnlyNumberCurrency(value: number | string): string {
  let clearWords = Number(String(value).replace(/\D+/g, ''));
  let transformValue = (clearWords/100).toLocaleString('pt-BR', {style: "currency", currency: "BRL"});
  
  return transformValue === undefined || !transformValue ? "" : transformValue;

}