import React from 'react';
import { ListContainer } from './styles';

interface BreadCrumbProps {
  itens: string[],
  itemActive?: string
}

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ itens, itemActive }) => {

  if (!itemActive) {
    itemActive = itens[itens.length - 1];
  }

  return (
    <ListContainer>
      {itens.map(item => (
        <li key={item+1} className={item === itemActive ? "isActive" : ""}>
          {item} {item === itemActive ? "" : <span>/</span>}
        </li>
      ))}
    </ListContainer>
  );
}