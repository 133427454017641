import { forwardRef, useEffect, useState } from 'react';

import { Label, Box, Mark } from './styles';

export interface Props {
  label: string;
  name: string;
  value: any;
  checkedError?: boolean,
  defaultChecked?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  onChange?: (event: CheckBoxChangeEvent) => void;
}

export interface ILabel {
  color?: string;
  checkedError?: boolean,
  readOnly?: boolean
}

export interface IBox {
  ref: any;
}

export interface CheckBoxChangeEvent {
  checked: boolean,
  value: any,
}

export const CheckBox = forwardRef(
  ({ label, name, value, checkedError = false, readOnly = false, onChange, checked, ...props }: Props, ref) => {
    const [localChecked, setChecked] = useState(checked)

    useEffect(() => {
      onChange !== undefined && onChange({checked: localChecked ?? false, value: value});
    }, [localChecked])

    const onClick = (event: any): void => {
      setChecked(!localChecked);
    }

    return (
      <Label
        onClick={onClick}
        readOnly={readOnly}
        htmlFor={name}
        checkedError={checkedError}
      >
        {label}

        <Box {...props} checked={checked} ref={ref} name={name} value={value} onChange={()=>{}} />
        <Mark checkedError={checkedError} />
      </Label>
    );
  }
);
