import styled from 'styled-components';
import {
  colors,
  spacings,
  borders,
  fontWeights
} from '../../../styles/theme'

export const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    left: 88px;
    width: calc(100% - 88px);
    height: 115px;
    border-bottom: 1px solid ${colors.neutral_regular};
    background: ${colors.white};

    .header-text{
      display: flex;
      
      h1{
        padding-bottom: ${spacings.sl};
        display: inline;
        border-bottom: ${spacings.xs} solid ${colors.primary};
      }

      h2{
        margin-right: ${spacings.xs};
      }
    }
`

export const CardContent = styled.div`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 32px;

  & + div {
    margin-top: ${spacings.lg};
  }

  .sortable{
    display: flex;
    align-items: center;

    p{
      margin-right: ${spacings.md};
    }
  }

  .download-participants{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacings.md};
    border-radius: ${borders.md};
    background: ${colors.neutral_light};
    border: none;

    h3{
      margin-right: ${spacings.md};
    }
  }

`
export const TicketContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  z-index: -5;

  .ticket-ballot{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    p{
      margin-bottom: ${spacings.sl};      
      font-weight: ${fontWeights.w700};
    }

    span{
      margin-bottom: ${spacings.sl};      
      color: ${colors.neutral_darkest};
    }
  }
`

export const WinnersContainer = styled.div`
  display: flex;

  .winners-left-cards{
    margin-right: ${spacings.lg};
    min-width: 298px;

    .federal-lottery-drawn-number{
      p{
        color: ${colors.neutral_darker};
      }
    }

    .federal-lottery-contest{
      p{
        margin-top: ${spacings.ls};
        color: ${colors.neutral_darker};
      }

      strong{
        display: block;
        margin-top: ${spacings.xs};
      }
    }

    .federal-lottery-list-awards{
      ul{
        li{
          list-style: none;

          p{
            color: ${colors.neutral_darker};
          }

          &:first-child{
            margin-top: ${spacings.ls};
          }

          &+li{
            margin-top: ${spacings.md};
          }
        }
      }
    }

    .card-content-buttons{
      button{
        &+button{
          margin-top: ${spacings.md};
        }
      }
    }

  }

  > div:last-child{
    flex: 1;
  }
  
`

export const FooterContainer = styled.div`
  align-items: center;
  bottom: 0;
  
  footer{
    display: flex;
    align-items: center;
    margin-bottom: ${spacings.ls};
    
    button{
      margin-right: ${spacings.md};
    }

  }
`