import React, { useState } from 'react';
import { PersonalData } from './PersonalData';
import { Notifications } from './Notifications';
import { Requests } from './Historic/Resquests';
import { Coupons } from './Historic/Coupons';
import { TabNav } from '../../components/TabNav';
import { Tab } from '../../components/Tab';
import { Menu } from '../../components/Menu';
import { menuListItems } from '../../assets/menu/menuList';

import link_user from '../../assets/imgs/img_menu/menu_link_user_selected.svg'
import link_user_not_selected from '../../assets/imgs/img_menu/menu_link_user_not_selected.svg'
import link_lottery from '../../assets/imgs/img_menu/menu_link_lottery_selected.svg'
import link_lottery_not_selected from '../../assets/imgs/img_menu/menu_link_lottery_not_selected.svg'
import link_settings from '../../assets/imgs/img_menu/menu_link_settings_selected.svg'
import link_settings_not_selected from '../../assets/imgs/img_menu/menu_link_settings_not_selected.svg'
import link_account from '../../assets/imgs/img_menu/menu_link_account_selected.svg'
import link_account_not_selected from '../../assets/imgs/img_menu/menu_link_account_not_selected.svg'

const linkMenu = [
  {
    url_img: link_user,
    url_img_hover: link_user_not_selected,
    path: "/home",
    name: "Usuários",
    isActive: true,
  },
  {
    url_img: link_lottery_not_selected,
    url_img_hover: link_lottery,
    path: "/lottery",
    name: "Sorteios",
    isActive: false,
  },
  {
    url_img: link_settings_not_selected,
    url_img_hover: link_settings,
    path: "/permissions",
    name: "Permissões",
    isActive: false,
  },
  {
    url_img: link_account_not_selected,
    url_img_hover: link_account,
    path: "/login",
    name: "Minha conta",
    isActive: false,
  }
];

const tabs = ['Dados pessoais', 'Notificações', 'Histórico'];

export const CustomerDetails: React.FC = () => {
  const [selected, setSelected] = useState(tabs[0]);

  return (
    <Menu links={menuListItems}>
      <TabNav tabs={tabs} selected={selected} setSelected={setSelected}>

        <Tab isSelected={selected} tabName="Dados pessoais">
          <PersonalData />
        </Tab>

        <Tab isSelected={selected} tabName="Notificações">
          <Notifications />
        </Tab>

        <Tab isSelected={selected} tabName="Histórico">
          <Requests />
          <Coupons />
        </Tab>

      </TabNav>
    </Menu>
  )
}