import React, { InputHTMLAttributes } from 'react';
import { InputContainer } from './styles';
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css"
import { iconCalendar, iconX } from '../../assets/icons';

registerLocale("pt", ptBR);

export interface InputFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  value?: string,
  selected: Date | null,
  marginBotton?: string,
  backGround?: string,
  name: string,
  error?: boolean,
  mensageError?: string,
  mensagem?: string,
  block?: boolean,
  icon?: boolean,
  iconImg?: JSX.Element,
  animation?: boolean,
  mask?: any,
  isClearDate?: boolean,
  onChange: (text: any) => void;
  onClickClearDate?: () => void;
}

export const InputDatePicker: React.FC<InputFormFieldProps> = ({
  label = "Meu input",
  value,
  backGround = "#fff",
  selected = null,
  marginBotton = "40",
  name,
  error = false,
  mensageError = "",
  mensagem = "",
  block = false,
  icon = false,
  iconImg,
  animation = false,
  mask = "",
  isClearDate = false,
  onChange,
  onClickClearDate,
  ...props }) => {

  return (
    <InputContainer
      isError={error}
      block={block}
      marginBotton={marginBotton}
      icon={icon}
      animation={animation}
      backGround={backGround}
      {...props}
    >
      <DatePicker
        locale="pt"
        className="datapicker"
        calendarClassName="calendar"
        dateFormat="dd/MM/yyyy"
        selected={selected}
        onChange={onChange}
        closeOnScroll={true}
        readOnly={props.readOnly ? props.readOnly : false}
        customInput={
          <div className={`formField-input`}>
            <input
              autoComplete="off"
              readOnly={props.readOnly ? props.readOnly : false}
              value={value}
              name={name}
              aria-label={name}
              placeholder=" "
              onChange={onChange}
              {...props}
            />

            <label htmlFor={name}>{label}</label>

            {value && isClearDate ? (
              <div>
                <button onClick={onClickClearDate}>
                  {iconX}
                </button>
              </div>
            ) : (
              <div>
                {iconCalendar}
              </div>
            )}

          </div>
        }
      />

      {error ? <span>{mensageError}</span> : null}
      {!error && mensagem.length > 0 ? <span>{mensagem}</span> : null}

    </InputContainer>
  )
}