import styled, { keyframes } from 'styled-components';
import { shadows } from '../../styles/template/shadows';
import {
  colors,
  spacings,
  fontSizes,
  fontWeights,
  borders
} from '../../styles/theme'

interface InputContainerProps {
  isError: boolean;
  block: boolean,
  marginBotton: string,
  icon: boolean,
  animation: boolean,
  backGround: string,
}

const loading = keyframes`  
   from { transform: rotate(0deg) translateX(2.5px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(2.5px) rotate(-360deg); }
`;

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width:100%;
  height: 100%;
  max-width: ${props => props.block ? "100%" : "330px"};
  margin-bottom: ${props => props.marginBotton}px;
  font-family: 'DM Sans', sans-serif;

  .formField-input{
    position: relative;
    width: 100%;
    height: 100%;

    

    input {
      border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}` : `${borders.xs} solid ${colors.neutral_medium}`};
      outline: none;
      width: 100%;
      padding: ${spacings.md} ${spacings.xl} ${spacings.md} ${spacings.md};
      color: ${colors.neutral_darkest};
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      background: ${props => props.backGround};
      box-shadow: 0 0 0 30px ${props => props.backGround} inset;
      -webkit-box-shadow: 0 0 0 30px ${props => props.backGround} inset;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -webkit-appearance: none;
      border-radius: ${borders.lg};

      &:not(:placeholder-shown){
        border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}`: `${borders.xs} solid ${colors.primary}`};
      }
      
      &:read-only {
        border: ${borders.xs} solid  ${colors.neutral_medium};
        color: ${colors.neutral_darker}
      }

      &:focus:not(:read-only) {
        border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}`: `${borders.xs} solid ${colors.primary}`};
      }

      &::placeholder {
        color: transparent;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        font-size: ${fontSizes.sl};
        top: 0%;
        color: ${colors.neutral_darkest};
      }
    }

    label {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 15px;
      padding: 0px ${spacings.xs};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
      font-weight: ${fontWeights.w700};
      background: ${props => props.backGround};
      transform: translateY(-50%);
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
    }

    div{
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 10px;
      padding: ${spacings.sl};
      transform: translateY(-50%);
      cursor: pointer;

      svg{
        animation: ${props => props.animation ? loading : ""} 1.5s linear infinite;
      }
    }
  }

  span{
    position: absolute;
    width: 100%;
    padding-top: ${spacings.xs};
    padding-left: ${spacings.md};
    color: ${props => props.isError ? colors.secondary_four : props => props.backGround};
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.xs};
  }

  .calendar{
    margin: -5px 0;
    padding: 0.25rem;
    background: ${colors.white};    
    font-family: DM Sans;
    box-shadow: ${shadows.lg};
    border: none;

    .react-datepicker__navigation--previous{
      margin: 0.7rem;

      span{
        position: absolute;
        right: 50%;
        top: 20%;
      }
    }

    .react-datepicker__navigation--next{
      margin: 0.7rem;

      span{
        position: absolute;
        left: 50%;
        top: 20%;
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month{
    margin: 0;
  }
  
  .react-datepicker__current-month{
    border-radius: ${borders.md};
    background: ${colors.neutral_light};
    padding: ${spacings.sm};
    margin: 0 ${spacings.sl};

    color: ${colors.neutral_darkest};
    font-size: ${fontSizes.sl};
    font-weight: ${fontWeights.w700};
  }

  .react-datepicker__day--outside-month{
    color: ${colors.neutral_medium} !important;
  }

  .react-datepicker__header{
    background: ${colors.white};
    border: none;
  }
  
  .react-datepicker__day-name{
    color: ${colors.neutral_darker};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.w500};
  }
  
  .react-datepicker__day{
    color: ${colors.neutral_darkest};
    font-size: ${fontSizes.sl};
    font-weight: ${fontWeights.w700};
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
    background: ${colors.primary} !important;
    color: ${colors.white};
    font-weight: ${fontWeights.w700};
    border-radius: ${borders.md};
  }
`