import React from 'react'

import { BadgeContainer } from './styles';

interface BadgeProps {
  value: string
  fontSize?: number
  fontWeight?: string | number
  margin?: string 
}

export const Badge: React.FC<BadgeProps> = ({value, fontSize = 10, fontWeight = "bold", margin = "0px"}) => {
  return (
    <BadgeContainer margin={margin} fontSize={fontSize} fontWeight={fontWeight} className="btn-tag">{value}</BadgeContainer>
  )
}