import styled, { keyframes } from "styled-components";
import {
  colors,
  spacings,
  borders
} from '../../styles/theme'

const scaleIn = keyframes`  
  0%{
    transform: scale(0);
  }
  25%{
    transform: scale(1.1);
  }
  50%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
`;

export const AlertContainer = styled.div`

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  #center {
    position:absolute; 
    top:40%; 
    left:40%; 
    transform:translate(-50%, -50%);
    
    bottom: auto;
    right: auto;
  }

  .alert-popup{
    position: absolute;
    bottom: 32px;
    right: 32px;
    min-width: 400px;
    padding: ${spacings.ls};
    border-radius: ${borders.lg};
    background: ${colors.white};
    animation: ${scaleIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .alert-popup-mensage{
      text-align: center;
      margin-bottom: ${spacings.ls};

      h3{
        margin-bottom: ${spacings.sl};
      }
    }

    .alert-popup-btn{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-gap: 10px;
    }
  }

`;