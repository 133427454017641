import styled from 'styled-components';
import {
  colors,
  spacings,
  borders,
  fontWeights,
  fontSizes
} from '../../styles/theme';

export const ListContainer = styled.ul`

  display: flex;

  li{
    list-style: none;
    padding-bottom: ${spacings.sl};

    font-style: normal;
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.md};

    color: ${colors.neutral_darker};

    &:last-child{
      margin-right: 0;
    }

    span{
      margin: 0px ${spacings.ls};
    }

    &.isActive{
      color: ${colors.neutral_darkest};
      border-bottom: ${borders.sm} solid ${colors.primary};
    }
  }
`