import { useContext } from "react";

import { createContext, ReactNode, useState, useEffect, Dispatch, SetStateAction } from "react";

import {ToastContainer} from './styles'

type TypeToastProps = "success" | "error" | "warning";

interface ToastProps {
  message: string;
  type: TypeToastProps; 
  isActive: boolean;
  timeToRemoveToast: number;
}

interface ToastProviderProps {
  children: ReactNode
}

interface ToastContextProps {
  setToast: Dispatch<SetStateAction<ToastProps>>;
}
  
const iconSuccess = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" rx="4" fill="#3FC44A" />
  <path d="M4.5 8.5L7.05587 10.8665C7.13589 10.9406 7.26051 10.937 7.33603 10.8583L12 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
</svg>

const iconError = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" rx="4" fill="#F52929" />
  <path d="M5.87868 5.87871L8 8.00003M10.1213 10.1213L8 8.00003M8 8.00003L10.1213 5.87871M8 8.00003L5.87868 10.1213" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

const initialToast: ToastProps = {
  message: "INITIAL TOAST",
  type: 'success',
  isActive: false,
  timeToRemoveToast: 3000
}

export const ToastContext = createContext({} as ToastContextProps);

export const ToastProvider = ({children}: ToastProviderProps) => {
  const [ toast, setToast ] = useState(initialToast);
  const {message, type, isActive, timeToRemoveToast} = toast;

  useEffect(() => {
    isActive &&
        setTimeout(() => {
            setToast({...toast, isActive: false});
        }, timeToRemoveToast)
  }, [toast])
  
  return (
    <ToastContext.Provider value={{setToast}}>
      {isActive &&
        <ToastContainer type={type} timeForHidden={timeToRemoveToast}>
          <div className="toast-content">
            <div className="toast-content-icon">
              {type === "error" ? iconError : iconSuccess}
            </div>

            <h5>{message}</h5>
          </div>

          <div className="toast-content-timer"></div>
          <span></span>
        </ToastContainer> 
      }
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext);