import styled from "styled-components";
import {
  colors,
  spacings,
  fontSizes,
  fontWeights,
  borders
} from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  
  --ReactInputVerificationCode-itemWidth: 3.8rem;
  --ReactInputVerificationCode-itemHeight: 3.2rem;

  .ReactInputVerificationCode__container{
    width: 100%;
    margin-bottom: ${spacings.lg};

    .ReactInputVerificationCode__item{
      border: ${borders.xs} solid  ${colors.neutral_medium};
      outline: none;
      color: ${colors.neutral_darkest};
      font-weight: ${fontWeights.w700};
      box-shadow: none;
      font-size: ${fontSizes.md};
      border-radius: ${borders.lg};

      &:not(:empty){
        border: ${borders.xs} solid ${colors.primary};
      }
    }
    .is-active{
      border: ${borders.xs} solid ${colors.primary};
    }
  }
`