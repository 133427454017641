import { ReactNode, useState } from "react";
import { TooltipContainer } from "./styles";

type PositionType  = "top" | "bottom" | "left" | "right";

interface TooltipProps {
    children: ReactNode,
    text: string,
    position?: PositionType,
}

export const Tooltip: React.FC<TooltipProps> = ({ children, text, position = "bottom", ...rest }) => {
    const [show, setShow] = useState(false)
    return (
        <TooltipContainer position={position}>
            <div className={show ? 'tooltip-box visible' : 'tooltip-box'} aria-describedby={text}>
                {text}
                <span className="tooltip-arrow" />
            </div>
            <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                {...rest}
            >
                {children}
            </div>
        </TooltipContainer>
    );
};