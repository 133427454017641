import styled, { keyframes } from "styled-components";
import {
  spacings,
  colors
} from '../../styles/theme'

interface ContainerProps {
  index: number;
}

const fadeLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  },
  to{
    opacity: 1;
    transform: translateX(0px);
  }
`

export const Container = styled.section<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
  min-height: 100vh;

  .content, .login-img{
    display: grid;
    place-items: center;
    width: 100%;
  }

  .content {
    animation: ${fadeLeft} 0.3s linear;
    .form-content{
      position: relative;
      width: 100%;
      max-width: 330px;
      height: 100%;
      margin: 0px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .form-button{
        margin-top: 20px;
        margin-bottom: 100px;
      }

      .form-header{
        margin-bottom: 40px;

        h1{
          margin-bottom: 16px;
        }

        figure{
          margin-bottom: 20px;
        }
      }

      .form-body{
        ${props => props.index === 2 ? "display: flex; justify-content: space-between;" : ""}
        ${props => props.index === 4 ? "text-align: center;" : ""}

        img{
          margin-bottom: ${spacings.xl};
        }

        .form-body-check{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-top: ${spacings.md};
          margin-bottom: ${spacings.xl};

          div{
            white-space: nowrap;
          }

          label{
            margin-bottom: ${spacings.md};

            &:nth-child(1){
              width: calc(100% - 144px);
            }
            &:nth-child(3){
              width: calc(100% - 144px);
            }

            &:nth-child(3), &:nth-child(4){
              justify-self: end;
              margin-bottom: 0;
            }
          }
        }
      }

      .form-footer{
        ${props => props.index === 4 ? "margin-top: 40px; text-align: center;" : ""}
        
        .paragraph, button:first-child{
          margin-bottom: ${spacings.md};
        }

      }
    }
  }

  .login-img{
    background: ${colors.support_one_light};

    figure{
      margin: 0 20px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 768px){
    .login-img{
      display: none;
    }
  }

`