import styled from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontSizes,
  fontWeights,
} from '../../../styles/theme'

interface FooterProps {
    isFooter: boolean;
}

export const Container = styled.div`
  &.isActive:last-child{
    margin-bottom: 100px;
  }
`

export const CustumerDetailsCard = styled.div`

  .card-body-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
    margin-bottom: ${spacings.xl};
    
    &:last-child{
      margin-bottom: ${spacings.ls};
    }
  }

  .card-body-input{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: ${spacings.lg};
  }

  .verify-identity{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacings.md};
    border-radius: ${borders.md};
    background: ${colors.neutral_light};

    h3{
      margin-right: ${spacings.md};
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
    }

    span{
      position: relative;
      width:24px;
      height: 24px;
      background: ${colors.secondary_one};
      border: ${borders.xs} solid ${colors.secondary_one};
      border-radius: ${borders.sm};

      &:after { 
        content: '';
        position: absolute;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 8px;
        border: solid ${colors.white};
        border-width: 0 ${borders.xs} ${borders.xs} 0;
        transform: rotate(45deg);
      }
    }
  }
`

export const Footer = styled.footer<FooterProps>`

  position: fixed;
  width: 100%;
  bottom: ${props => props.isFooter ? "0" : "-100%"};
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spacings.md} ${spacings.lg};
  border-top: ${borders.xs} solid ${colors.neutral_regular};
  background: ${colors.white};
  transition: all 0.6s linear;

  .btn-tag{
    padding: ${spacings.sl};
    font-weight: bold;
    font-size: ${fontSizes.xs};
    color: ${colors.neutral_darkest};
    background: ${colors.neutral_light};
    border-radius: ${borders.md};
    margin-right: ${spacings.sl};
    margin-left: ${spacings.sl};

    &:last-child{
      margin-right: ${spacings.lg};
    }
  }

  button{
    margin-left: ${spacings.lg};
    min-width: 150px;
  }
`;

