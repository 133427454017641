import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseRoutes } from "../../routes/BaseRoutes";

import { useSession } from "../../hooks/useSession";
import { useAuth } from "../../hooks/useAuth";

import { Separator } from "../../components/Separator";
import { RouteLink } from "../../components/Link";
import { InputFormField } from "../../components/InputFormField";
import { Title } from "../../components/Title";
import { Button } from "../../components/Button";

import { isValidatedInput } from "../../utils/validateInput";
import login_img from '../../assets/imgs/img_login/login_img.png'
import { iconLogoBlue } from "../../assets/icons/logo";
import { Container } from "./styles";

interface LoginProps {
  email: {
    value: string;
    error: boolean;
  };
  password: {
    value: string;
    error: boolean;
  };
}

const initialValues = {
  email: {
    value: "",
    error: false,
  },
  password: {
    value: "",
    error: false,
  },
};

const InitButtonValues = {
  disable: true,
  loading: false,
};


export const Login: React.FC = () => {
  const { push } = useHistory();
  const { signIn } = useSession();
  const { connectAPI } = useAuth();
  const [ inputsValues, setInputsValues ] = useState<LoginProps>(initialValues);
  const [ submitButton, setSubmitButton ] = useState(InitButtonValues);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const targetRequired = event.target.required;

    setInputsValues({
      ...inputsValues,
      [targetName]: {
        value: targetValue,
        error: false,
      },
    });

    onValidateForm(targetName, targetValue, targetRequired);

  };

  const onValidateForm = (targetName: string, targetValue: string, targetRequired: boolean) => {
    const isValidateInputs = isValidatedInput([
      {
        name: "email",
        valueInput: targetName === "email" ? targetValue : inputsValues.email.value,
        validateOptions: ['email'],
      },
      {
        name: "password",
        valueInput: targetName === "password" ? targetValue : inputsValues.password.value,
        validateOptions: ['minCharacter'],
        min: 1,
      },
    ]);

    const { validatedObject, hasError } = isValidateInputs
    setInputsValues({
      ...inputsValues,
      [targetName]: {
        value: validatedObject[targetName].valueInput,
        error: !validatedObject[targetName].isValidated,
      },
    });
    setSubmitButton({
      ...submitButton,
      disable: hasError,
    })

    return isValidateInputs
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!submitButton.disable) {
      setSubmitButton({ ...submitButton, loading: true });

      const data = {
        email: inputsValues.email.value,
        password: inputsValues.password.value,
      };

      const response = await connectAPI({
        url: "authentication", 
        body: data, 
        config: {}, 
        type: "post",
        messageError: "Credenciais Inválidas"
      });
      

      if(response.connect) {
        signIn(response.data.token);
        push("/home");
      }
    }
    setSubmitButton({ ...submitButton, loading: false });
  };

  useEffect(() => {
    return () => {
      setInputsValues(initialValues)
    }
  }, [])

  return (
    <Container >
      <form onSubmit={handleSubmit}>
        <div className="form-content">
          <div className="form-header">
              {iconLogoBlue}
            <figure>
            </figure>
            <Title
              elementHTML="h1"
              className="subtitle-medium"
              text="Backoffice Confi"
            />
          </div>

          <div className="form-body">
            <InputFormField
              name="email"
              value={inputsValues.email.value}
              typeInput="text"
              label="Usuário"
              error={inputsValues.email.error}
              mensageError="Ops, usuário invalido"
              onChange={handleChange}
              autoFocus={true}
            />

            <InputFormField
              name="password"
              value={inputsValues.password.value}
              typeInput="password"
              label="Senha"
              error={inputsValues.password.error}
              mensageError="Ops, senha invalida"
              required
              onChange={handleChange}
            />

            <Button
              type="submit"
              size="large"
              block={true}
              typeBtn="blue"
              label="Login"
              disable={submitButton.disable}
              load={submitButton.loading}
            />
          </div>

          <Separator />

          <div className="form-footer">
            <RouteLink
              text="Esqueci minha senha"
              icon={false}
              route={BaseRoutes.fogotPassword}
            />
          </div>
        </div>
      </form>

      <div className="login-img">
        <figure >
          <img src={login_img} alt="logo marca compre e confie" />
        </figure>
      </div>
    </Container>
  );
};