import styled from "styled-components";
import {
  colors,
  spacings,
  borders
} from '../../styles/theme'

export const Container = styled.div`
  background: ${colors.white};
  padding: ${spacings.ls};
  border-radius: ${borders.lg};
`

