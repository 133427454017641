import { CheckBox, CheckBoxChangeEvent } from "components/CheckBox";
import { InputFormField } from "components/InputFormField";
import { ChangeEvent } from "react";
import { RoleFormContent } from "./styles";

interface RoleFormParams {
  role: any,
  handleInputFieldChange: (e: ChangeEvent<HTMLInputElement>) => void,
  handleCheckBoxChange: (e: CheckBoxChangeEvent) => void,
}

export const RoleForm:React.FC<RoleFormParams> = ({
  role,
  handleInputFieldChange,
  handleCheckBoxChange,
}) => {
  return (
    <RoleFormContent>
      <InputFormField
        name="permission"
        label="Nome da permissão"
        onChange={handleInputFieldChange}
        value={role.roleName}
        marginBotton="0"
      />
      <div>
        <CheckBox
          name="create"
          label="Create"
          value="CREATE"
          readOnly={false}
          onChange={handleCheckBoxChange}
          checked={role.definitions.includes('CREATE')}
        />
      </div>
      <div>
        <CheckBox
          name="read"
          label="Read"
          value="READ"
          readOnly={false}
          onChange={handleCheckBoxChange}
          checked={role.definitions.includes('READ')}
        />
      </div> 
      <div>
        <CheckBox
          name="update"
          label="Update"
          value="UPDATE"
          readOnly={false}
          onChange={handleCheckBoxChange}
          checked={role.definitions.includes('UPDATE')}
        />
      </div> 
      <div>
        <CheckBox
          name="delete"
          label="Delete"
          value="DELETE"
          readOnly={false}
          onChange={handleCheckBoxChange}
          checked={role.definitions.includes('DELETE')}
        />
      </div>
    </RoleFormContent>
  )
}