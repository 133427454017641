import React from 'react';
import { Router } from 'react-router-dom';

import { SessionProvider } from './hooks/useSession';
import { TableContextProvider } from './hooks/useTable';
import { ToastProvider } from './hooks/useToast';
import { AlertProvider } from './hooks/useAlert';

import { Routes } from './routes/index';
import history from './utils/history';

import { GlobalStyle } from "./styles/global";
import { AuthProvider } from './hooks/useAuth';

export const App: React.FC = () => {
  return (
    <SessionProvider>
      <ToastProvider>
        <AuthProvider>
          <AlertProvider>
          <GlobalStyle />
            <TableContextProvider>
              <Router history={history}>
                <Routes />
              </Router>
            </TableContextProvider>
          </AlertProvider>
        </AuthProvider>
      </ToastProvider>        
    </SessionProvider>
  );
}

export default App;
