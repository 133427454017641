import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';

interface LinkProps {
  text: string;
  icon?: boolean;
  route: string;
  children?: ReactNode
}

export const RouteLink: React.FC<LinkProps> = ({ text = "link", icon = false, route, children }) => {

  let classIcon = "";

  if (icon) {
    classIcon = "active"
  }

  return (
    <Container>
      <Link to={route} className={`router-link ${classIcon}`} >
        {icon ?
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 5L11 5" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M6 9L1.10901 5.26355C0.963662 5.15013 0.963662 4.94329 1.10901 4.82986L6 1" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
          </svg> : null}
        {children}
        {text}
      </Link>
    </Container>
  )
}