import styled from "styled-components";
import {
    colors,
    spacings
} from '../../styles/theme'

export const HeaderContainer = styled.header`
    position: fixed;
    display: flex;
    align-items: center;
    top: 0px;
    left: 88px;
    width: calc(100% - 0px);
    height: 118px;
    padding-left: ${spacings.lg};
    border-bottom: 1px solid ${colors.neutral_light};
    background: ${colors.white};
`