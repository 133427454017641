import styled from 'styled-components'
import {
  colors,
  spacings,
  fontSizes,
  fontWeights,
} from '../../styles/theme'

export const Container = styled.span`

  .router-link {
    font-family: DM Sans;
    font-style: normal;
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.sm};
    text-decoration-line: underline;
    color: ${colors.neutral_darkest};
  }

  .router-link svg {
    margin-right: ${spacings.sm};
  }

  .router-link.active {
    font-size: ${fontSizes.md};
    text-decoration: none;
  }
`
