import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../hooks/useAuth';

import { Menu } from '../../../components/Menu';
import { Title } from '../../../components/Title';
import { Card } from '../../../components/Card';
import { InputFormField } from '../../../components/InputFormField';
import { Button } from '../../../components/Button';
import { CheckBox } from '../../../components/CheckBox';
import { useHistory, useParams } from "react-router-dom";
import { BreadCrumb } from '../../../components/BreadCrumb';
import { StepByStep } from '../../../components/StepByStep';
import { GoToBack } from '../../../components/GoToBack';
import { useToast } from '../../../hooks/useToast';
import { Select } from '../../../components/Select';
import { InputDatePicker } from '../../../components/InputDatePicker';
import { Loading } from '../../../components/Loading';
import { Header } from '../../../components/Header';

import { convertDateToSendPOST, convertDateToShowInput } from '../../../utils/formattingDate';
import { formattingOnlyNumber, formattingOnlyNumberCurrency } from '../../../utils/formattingOnlyNumber';
import "react-datepicker/dist/react-datepicker.css"
import { CardContent, Footer, HeaderContainer } from './styles';
import { menuListItems } from '../../../assets/menu/menuList';

interface CreateNewBallotToExistingPlan{
  id: string;
  description: string;
}

interface BallotBoxTypesSelect {
  description: string[];
}

interface BallotBoxPrizesSelect {
  prizeId: string;
  description: string;
}

interface BallotBoxSuggestionsSelect {
  prizeSuggestionId: string;
  suggestion: string;
}

const initPlanStepOne = {
  description: "",
  startDate: null,
  endDate: null,
  couponQuantity: "",
  invoiceNumber: "",
  quantity: "",
  totalValue: "",
  invoiceDate: null
}

const initBallotStepTwo = {
  serie: "",
  startDate: null,
  endDate: null,
  ballotBoxType: "",
  cefLotteryDate: null,
  mccLotteryDate: null,
  capacity: "",
  prizeId: "",
  prizeSuggestionId: "",
}

export const RegisterNewPlan: React.FC = () => {
  const { id, description } = useParams<CreateNewBallotToExistingPlan>();
  const { push } = useHistory();
  const { setToast } = useToast();
  const { connectAPI, connectAllAPI, cancelRequest } = useAuth();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ currentIndex, setCurrentIndex ] = useState(1);
  const [ isLoadingPage, setIsLoadingPage ] = useState(false);
  const [ selectBallotBoxType, setSelectBallotBoxType ] = useState([]);
  const [ selectBallotBoxPrize, setSelectBallotBoxPrize ] = useState([]);
  const [ selectBallotBoxPrizeId, setSelectBallotBoxPrizeId ] = useState([]);
  const [ inputsValuesPlan, setInputsValuesPlan ] = useState(initPlanStepOne);
  const [ inputsValuesBallot, setInputsValuesBallot ] = useState(initBallotStepTwo);
  const [ selectBallotBoxSuggestions, setSelectBallotBoxSuggestions ] = useState([]);
  const [ selectBallotBoxSuggestionsId, setSelectBallotBoxSuggestionsId ] = useState([]);
  const [ debounce, setDebounce ] = useState<NodeJS.Timeout | any>();

  const debounceFN = (fn: any, value: string) => {
    clearTimeout(debounce);

    const timer = setTimeout(() => {
      fn(value);
    }, 1000)

    setDebounce(timer);
  }

  const onLoadTypeAndPrizeSelects = async () => {
    setIsLoadingPage(true);

    const response = await connectAllAPI([
      {url: "lotterys/ballotboxes/type", type: "get", messageError: "Falha ao carregar Id Tipo Urna"},
      {url: "lotterys/prizes", type:"get", messageError: "Falha ao listar prêmios"},
    ])

    if(response[0].connect){
      const data = response[0].data;
      const arraySelect: any = [];

      data.map((item:BallotBoxTypesSelect) => (
        arraySelect.push(item.description)
      ))
      setSelectBallotBoxType(arraySelect);
    }

    if(response[1].connect){
      const data = response[1].data
      const arraySelect: any = [];
      const arraySelectId: any = [];

      data.map((item:BallotBoxPrizesSelect) => (
        arraySelect.push(formattingOnlyNumberCurrency(item.description)),
        arraySelectId.push(item.prizeId)
      )) 

      setSelectBallotBoxPrize(arraySelect);
      setSelectBallotBoxPrizeId(arraySelectId);
    }
    setIsLoadingPage(false)
  }

  const emptyFields = (inputsValues: any) => {
    let arrayEmpty = [];
    for(let [key, value] of Object.entries(inputsValues)){
      if(key !== "prizeId" && (value === "" || value === 0 || value === null)){
        arrayEmpty.push(key)
      }
    }
    return arrayEmpty;
  }

  const handleSelectPrizeSuggestion = async (id: number) => {
    const arraySelect: any = [];
    const arraySelectId: any = [];

    const response = await connectAPI({
      url: `lotterys/prizes/${id}`,       
      type: "get", 
      messageError: "Falha ao carregar lista de sugestões de prêmio"
    });

    if(response.connect){
      const data = response.data;
      data.prizeSuggestion && data.prizeSuggestion.map((item:BallotBoxSuggestionsSelect) => (
        arraySelect.push(item.suggestion),
        arraySelectId.push(item.prizeSuggestionId)
      ))

      setSelectBallotBoxSuggestions(arraySelect);
      setSelectBallotBoxSuggestionsId(arraySelectId);
    }
  }
  
  const handleSubmitPlan = async () => {   
    setIsLoading(true);
    const plan = {
      lotteryPlan: {
        description: inputsValuesPlan.description,
        startDate: convertDateToSendPOST(inputsValuesPlan.startDate),
        endDate: convertDateToSendPOST(inputsValuesPlan.endDate),
        couponQuantity: inputsValuesPlan.couponQuantity.replace(/\D+/g, ''),
      },
      invoiceOrder: {
        invoiceNumber: inputsValuesPlan.invoiceNumber,
        quantity: inputsValuesPlan.quantity.replace(/\D+/g, ''),
        totalValue: inputsValuesPlan.totalValue.replace(/\D+/g, ''),
        invoiceDate: convertDateToSendPOST(inputsValuesPlan.invoiceDate),
      }
    }

    const response = await connectAPI({
      url: "lotterys/plans", 
      body: plan,
      type: "post",
      messageError: "Falha ao salvar plano",
    });

    if(response.connect){
      const data = response.data
      handleSubmitBallot(data.lotteryPlanId)
    }
  }

  const handleSubmitBallot = async (id: number) => {
    setIsLoading(true);
    const ballot = {
      serie: inputsValuesBallot.serie,
      startDate: convertDateToSendPOST(inputsValuesBallot.startDate),
      endDate: convertDateToSendPOST(inputsValuesBallot.endDate),
      ballotBoxType: inputsValuesBallot.ballotBoxType,
      cefLotteryDate: convertDateToSendPOST(inputsValuesBallot.cefLotteryDate),
      mccLotteryDate: convertDateToSendPOST(inputsValuesBallot.mccLotteryDate),
      capacity: inputsValuesBallot.capacity.replace(/\D+/g, ''),
      prizeSuggestionId: inputsValuesBallot.prizeSuggestionId
    }

    const response = await connectAPI({
      url: `lotterys/plans/${id}/ballotboxes`, 
      body: ballot, 
      type: "post",
      messageError: "Falha ao salvar urna",
      messageSuccess: !id ? "Plano salvo com sucesso" : "Urna salva com sucesso"
    });

    if(response.connect){
      push(`/lottery/lottery-details/${id}`);
    }
    setIsLoading(false);       
  }

  const onChangePlan = (event: any, dateName?: string ) => {
    
    if(!!dateName){
      setInputsValuesPlan(({...inputsValuesPlan, [dateName]: event}));      
    }else{
      const targetName = event.target.name;
      const targetValue = event.target.value;

      setInputsValuesPlan(({...inputsValuesPlan, [targetName]: targetValue})); 
    }
  }

  const onChangeBallot = (event: any, dataName?: string) => {
    if(!!dataName){
      setInputsValuesBallot(({...inputsValuesBallot, [dataName]: event}));
    }else{
      const targetName = event.target.name;
      const targetValue = event.target.value;

      setInputsValuesBallot(({...inputsValuesBallot, [targetName]: targetValue})); 
    }
  }

  const nextStep = (number: any) => {
    setCurrentIndex(number)
  }

  const validateStepOne = () => {
    if(currentIndex === 1){
      if(emptyFields(inputsValuesPlan).length){
        setToast({
          message: `Favor preencher o(s) campo(s) abaixo:\n ${String(emptyFields(inputsValuesPlan))}.`,
          isActive: true,
          type: 'error',
          timeToRemoveToast: 3000
        }) 
        return 
      }else{
        nextStep(2);
      }      
    }
    
    if (currentIndex === 2) {
      if(emptyFields(inputsValuesBallot).length){
        setToast({
          message: `Favor preencher o(s) campo(s) abaixo:\n ${String(emptyFields(inputsValuesBallot))}.`,
          isActive: true,
          type: 'error',
          timeToRemoveToast: 3000
        }) 
        return 
      }
      
      if(!id){
        handleSubmitPlan();
      }else{
        handleSubmitBallot(Number(id));
      }
    }
  }

  useEffect(() => {
    Number(inputsValuesBallot.prizeId) > 0 && handleSelectPrizeSuggestion(Number(inputsValuesBallot.prizeId));
  }, [inputsValuesBallot])
  
  useEffect(() => {
    onLoadTypeAndPrizeSelects();

    if(id){
      setCurrentIndex(2)
    }

    return () => {      
      setSelectBallotBoxType([]); 
      setSelectBallotBoxPrize([]);
      setSelectBallotBoxPrizeId([]); 
      cancelRequest.cancel("cancel");
    }
  }, [])

  return (
    <StepByStep currentIndex={currentIndex}>
      
      <Menu links={menuListItems}>
        <Header>
          <HeaderContainer>
            <div className="header-btn">
              <GoToBack path="/lottery"/>
            </div>

            <div className="header-text">
              <BreadCrumb itens={["Cadastrar novo plano de sorteio"]} itemActive="Cadastrar novo plano de sorteio" />
            </div>
          </ HeaderContainer>
        </Header>

        <Card title="Dados básicos">
          <CardContent>
            <InputFormField
              name="description"
              label="Descrição"
              value={inputsValuesPlan.description}
              backGround="white"
              marginBotton="0"
              title={inputsValuesPlan.description}
              onChange={onChangePlan}
            />

            <InputDatePicker
              name="startDate"
              label="Data Inicial"
              selected={inputsValuesPlan.startDate || null}
              value={inputsValuesPlan.startDate ? convertDateToShowInput(inputsValuesPlan.startDate) : ""}
              marginBotton="0"
              onChange={(e:any) => onChangePlan(e, "startDate")}
              onClickClearDate={() => onChangePlan("", "startDate")}
            />

            <InputDatePicker
              name="endDate"
              label="Data Final"
              selected={inputsValuesPlan.endDate || null}
              value={inputsValuesPlan.endDate ? convertDateToShowInput(inputsValuesPlan.endDate) : ""}
              marginBotton="0"
              onChange={(e:any) => onChangePlan(e, "endDate")}
              onClickClearDate={() => onChangePlan("", "endDate")}
            />

            <InputFormField
              name="couponQuantity"
              label="Qtd. Cupons"
              value={String(formattingOnlyNumber(inputsValuesPlan.couponQuantity))}
              backGround="white"
              marginBotton="0"
              onChange={onChangePlan}
            />
          </CardContent>
        </Card>

        <Card title="Invoices" >
          <CardContent>
            <InputFormField
              name="invoiceNumber"
              label="Nº Pedido"
              value={inputsValuesPlan.invoiceNumber}
              backGround="white"
              marginBotton="0"
              type="number"
              onChange={onChangePlan}
            />

            <InputFormField
              name="quantity"
              label="Quantidade"
              value={String(formattingOnlyNumber(inputsValuesPlan.quantity))}
              backGround="white"
              marginBotton="0"
              onChange={onChangePlan}
            />

            <InputFormField
              name="totalValue"
              label="Total"
              value={inputsValuesPlan.totalValue ? formattingOnlyNumberCurrency(String(inputsValuesPlan.totalValue)) : ""}
              backGround="white"
              marginBotton="0"
              onChange={onChangePlan}
            />

            <InputDatePicker
              name="invoiceDate"
              label="Data Pedido"
              selected={inputsValuesPlan.invoiceDate || null}
              value={inputsValuesPlan.invoiceDate ? convertDateToShowInput(inputsValuesPlan.invoiceDate) : ""}
              marginBotton="0"
              onChange={(e:any) => onChangePlan(e, "invoiceDate")}              
              onClickClearDate={() => onChangePlan("", "invoiceDate")}
            />
          </CardContent>
        </Card>

        <Footer>
          <Button 
            label="Seguir para cadastro de Urna" 
            typeBtn="blue" 
            disabled={!!emptyFields(inputsValuesPlan).length} 
            onClick={validateStepOne} 
          />
        </Footer>
      </Menu>

      <Menu links={menuListItems}>
        <Header>
          <HeaderContainer>
            <div className="header-btn">
              <GoToBack path="/lottery"/>
            </div>

            <div className="header-text">
              <BreadCrumb itens={[`${description ? description : "Cadastrar novo plano de sorteio"}`, "Cadastrar nova urna"]} itemActive="Cadastrar nova urna" />
            </div>
          </ HeaderContainer>
        </Header>

        {isLoadingPage ? <Loading/> : (
        <>
          <Card title="Dados da Urna">
            <CardContent>
              <div className="sortable">
                <Title text="Sorteada?" elementHTML="p" className="overline" />
                <CheckBox 
                  label="Não" 
                  name="soteio" 
                  value={false} 
                  checkedError={true} 
                  checked={true} 
                  readOnly={true}
                />
              </div>
              
              <InputFormField
                name="serie"
                label="Série"
                value={inputsValuesBallot.serie}
                backGround="white"
                marginBotton="0"
                onChange={onChangeBallot}
              />

              <InputDatePicker
                name="startDate"
                label="Data Inicial"
                selected={inputsValuesBallot.startDate || null}
                value={inputsValuesBallot.startDate ? convertDateToShowInput(inputsValuesBallot.startDate) : ""}
                marginBotton="0"
                onChange={(e:any) => onChangeBallot(e, "startDate")}
                onClickClearDate={() => onChangeBallot("", "startDate")}
              />

              <InputDatePicker
                name="endDate"
                label="Data final"
                selected={inputsValuesBallot.endDate || null}
                value={inputsValuesBallot.endDate ? convertDateToShowInput(inputsValuesBallot.endDate) : ""}
                marginBotton="0"
                onChange={(e:any) => onChangeBallot(e, "endDate")}
                onClickClearDate={() => onChangeBallot("", "endDate")}
              />

              <Select 
                name="Id Tipo Urna"
                appearWhere="top"
                value={inputsValuesBallot.ballotBoxType}
                optionsValue={(selectBallotBoxType)}
                optionsDescription={(selectBallotBoxType)}
                onChange={(e: any) => onChangeBallot(e["valueId"], "ballotBoxType")}
              />
            </CardContent>
          </Card>

          <Card title="Dados Sorteio" >
            <CardContent>
              <InputDatePicker
                name="cefLotteryDate"
                label="Sorteio CEF"
                selected={inputsValuesBallot.cefLotteryDate || null}
                value={inputsValuesBallot.cefLotteryDate ? convertDateToShowInput(inputsValuesBallot.cefLotteryDate) : ""}
                marginBotton="0"
                onChange={(e:any) => onChangeBallot(e, "cefLotteryDate")}
                onClickClearDate={() => onChangeBallot("", "cefLotteryDate")}
              />

              <InputDatePicker
                name="mccLotteryDate"
                label="Sorteio MCC"
                selected={inputsValuesBallot.mccLotteryDate || null}
                value={inputsValuesBallot.mccLotteryDate ? convertDateToShowInput(inputsValuesBallot.mccLotteryDate) : ""}
                marginBotton="0"
                onChange={(e:any) => onChangeBallot(e, "mccLotteryDate")}
                onClickClearDate={() => onChangeBallot("", "mccLotteryDate")}
              />

              <InputFormField
                name="capacity"
                label="Capacidade"
                value={formattingOnlyNumber(String(inputsValuesBallot.capacity))}
                backGround="white"
                block={true}
                marginBotton="0"
                onChange={onChangeBallot}
              />

            </CardContent>

            <CardContent>
              <Select 
                block={true}
                name="Prêmio" 
                appearWhere="top" 
                value={inputsValuesBallot.prizeId}
                optionsValue={selectBallotBoxPrizeId}
                optionsDescription={selectBallotBoxPrize}
                onChange={(e: any) => onChangeBallot(e["valueId"], "prizeId")}
              />
              
              <Select 
                name="Sugestão de prêmio" 
                appearWhere="top"
                value={inputsValuesBallot.prizeSuggestionId}
                optionsValue={selectBallotBoxSuggestionsId}
                optionsDescription={selectBallotBoxSuggestions}
                onChange={(e: any) => onChangeBallot(e["valueId"], "prizeSuggestionId")}
              />
            </CardContent>
          </Card>
        </>
        )}
        <Footer>
          <Button 
            label="Cadastrar nova urna" 
            typeBtn="blue" 
            load={isLoading}
            disabled={!!emptyFields(inputsValuesBallot).length} 
            onClick={(e:any) => debounceFN(validateStepOne, e)} 
          />
        </Footer>
        </Menu>

    </StepByStep>
  );
}