import { ChangeEvent, useEffect, useState } from 'react'
import { useSession } from 'hooks/useSession'
import { api } from 'services/api'
import { SelectChangeEvent } from 'components/Select'
import { v4 as uuid } from 'uuid';
import { useToast } from 'hooks/useToast'
import { isValidatedInput, ValueInputTypes } from 'utils/validateInput'
import { useHistory } from 'react-router'
import { BaseRoutes } from 'routes/BaseRoutes'

interface SelectOption {
  value: string,
  description: string,
}

interface TgroupCompany {
  tGroupCompanyId: number,
  tGroupCompany?: string,
}

interface Application {
  applicationId: number,
  applicationDescription?: string,
}

interface Role {
  roleId: number,
  roleName?: string,
}

const initForm = {
  userName: {value: '', error: false},
  email: {value: '', error: false},
  tGroupCompany: {value: '', description: '', error: false},
  permissions: {
    [uuid()]: {
      role: {value: '', description: '', error: false},
      application: {value: '', description: '', error: false},
    }
  }
}

export const useNewUser = () => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<SelectOption[]>();
  const [initApplicationOptions, setInitApplicationOptions] = useState<SelectOption[]>();
  const [applicationOptions, setApplicationOptions] = useState<SelectOption[]>();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>();
  const [inputsValues, setInputsValues] = useState(initForm);
  const history = useHistory();
  const session = useSession();
  const {setToast} = useToast();

  const push = (route: string): void => {
    history.push(route);
  }

  /* HOOKS */
  useEffect(() => {
    getSelectOptions();
  }, []);

  useEffect(() => {
    setValidForm(formValid());
  }, [inputsValues]);

  useEffect(() => {
    let selectedApplications:SelectOption[] = [];
    let auxApplications = [...(initApplicationOptions ?? [])];

    for (let [key, value] of Object.entries(inputsValues.permissions))   {
      let application = value.application;
      if (application.value.length > 0)
        selectedApplications.push({value: application.value, description: application.description});
    }

    for (let i = 0; i < selectedApplications.length; ++ i) {
      let application = selectedApplications[i];
      let option = auxApplications.find((a) => a.value === application.value);
      if (option) {
        const index = auxApplications.indexOf(option, 0);
        if (index > -1) {
          auxApplications.splice(index, 1);
        }
      }
    }

    setApplicationOptions(auxApplications);
  }, [inputsValues.permissions]);


  const formValid = ():boolean => {
    // essa logica ta horrorosa mas eh o que deu por enquanto
    if (inputsValues.userName.value.length == 0) {
      return false;
    }
    if (
      isValidatedInput([{
        name: 'email',
        valueInput: inputsValues.email.value,
        validateOptions: ['email'],
      }]).hasError
    ) {
      return false;
    }
    if (inputsValues.tGroupCompany.value.length == 0) {
      return false;
    }
    for (let [key, value] of Object.entries(inputsValues.permissions)) {
      if (value.application.value.length == 0 || value.role.value.length == 0 ) {
        return false;
      }
    }
    return true;
  }

  const createUser = async() => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    const user = {
      userName: inputsValues.userName.value,
      email: inputsValues.email.value,
      tGroupCompanyId: inputsValues.tGroupCompany.value,
      roles: Object.keys(inputsValues.permissions).map((key) => {
        return {
          roleId: inputsValues.permissions[key].role.value,
          applicationId: inputsValues.permissions[key].application.value,
        }
      })
    }

    setSubmitting(true);
    api.post('users', user, config).then((response) => {
      setToast({
        message: `Usuário cadastrado com sucesso`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      });
      push(BaseRoutes.permissions)
    }).catch((error) => {
      const errorResponse = error.response.data;
      setToast({
        message: `Erro ${errorResponse.StatusCode}: ${errorResponse.Message}`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }).finally(() => {
      setSubmitting(false);
    })
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const field = event.target.name;
    const value = event.target.value;

    const { validatedObject, hasError } = isValidatedInput([{
      name: field,
      valueInput: value,
      validateOptions: [field as ValueInputTypes, 'minCharacter'],
      min: 1,
    }])

    setInputsValues({
      ...inputsValues,
      [field]: {value: value, error: hasError},
    })
  }

  const handleCompanySelectChange = (event: SelectChangeEvent) => {
    const targetId = event.valueId;
    const description = event.optionSelected;

    setInputsValues({
      ...inputsValues,
      tGroupCompany: {
        value: targetId ?? '',
        description: description ?? '',
        error: false
      }
    })
  }

  const handlePermissionSelectChange = (event: SelectChangeEvent) => {
    const [target, index] = event.dataId?.split(':') ?? [];
    let targetId = event.valueId;
    let description = event.optionSelected;
    setInputsValues({
      ...inputsValues,
      permissions: {
        ...inputsValues.permissions,
        [index]: {
          ...inputsValues.permissions[index],
          [target]: {value: targetId, description: description, error: false}
        }
      }
    })
  }

  const getSelectOptions = async () => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    try {
      const response = await Promise.allSettled([
        api.get(`users/tgroup-companies`, config),
        api.get(`users/applications`, config),
        api.get(`users/roles`, config),
      ]);

      if (response[0].status !== "rejected") {
        let body = response[0].value.data;
        setHasError(false);
        setCompanyOptions(
          body.map((item: TgroupCompany):SelectOption => ({
            value: item.tGroupCompanyId.toString(),
            description: item.tGroupCompany ?? 'N/A',
          })),
        );
      } else {
        setHasError(true);
        return;
      }

      if (response[1].status !== "rejected") {
        let body = response[1].value.data;
        let options = body.map((item: Application):SelectOption => ({
          value: item.applicationId.toString(),
          description: item.applicationDescription ?? 'N/A',
        }));
        setHasError(false);
        setInitApplicationOptions(options)
        setApplicationOptions(options);
      } else {
        setHasError(true);
        return;
      }

      if (response[2].status !== "rejected") {
        let body = response[2].value.data;
        setHasError(false);
        setRoleOptions(
          body.map((item: Role):SelectOption => ({
            value: item.roleId.toString(),
            description: item.roleName ?? 'N/A',
          })),
        );
      } else {
        setHasError(true);
        return;
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }

  const addPermission = ():void => {
    let newPermission = {
      role: {value: '', description: '', error: false},
      application: {value: '', description: '', error: false},
    };

    setInputsValues({
      ...inputsValues,
      permissions: {
        ...inputsValues.permissions,
        [uuid()]: newPermission
      }
    })
  }

  const removeRole = (permissionId: string): void => {
    let auxPermissions = {...inputsValues.permissions};
    delete auxPermissions[permissionId];
    setInputsValues({
      ...inputsValues,
      permissions: auxPermissions,
    });
  }

  const handleOnSubmit = () => {
    createUser();
  }

  return {
    loading,
    hasError,
    validForm,
    submitting,
    handleInputChange,
    handleCompanySelectChange,
    handlePermissionSelectChange,
    handleOnSubmit,
    addPermission,
    removeRole,
    companyOptions,
    applicationOptions,
    roleOptions,
    inputsValues
  }
}