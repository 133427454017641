import styled from "styled-components";
import {
  colors,
  borders,
} from '../../styles/theme'

export const LoadingContainer = styled.div`
  
  .loader {
    position: relative;
    width: 100%;
    min-height: 70vh;
    border-radius: ${borders.lg};
  }
  
  .loader_cogs {
    width: 100px;
    height: 100px;
    top: -120px !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .COGfirst {
    position: relative;
    width: 100px;
    height: 100px;
    -webkit-transform-origin: 50px 50px;
            transform-origin: 50px 50px;
    -webkit-animation: rotate 10s infinite linear;
            animation: rotate 10s infinite linear;
  }
  .COGfirst div:nth-of-type(1) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .COGfirst div:nth-of-type(2) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .COGfirst div:nth-of-type(3) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .COGfirst div.firstPart {
    width: 100px;
    border-radius: ${borders.lg};
    position: absolute;
    height: 100px;
    background: ${colors.primary};
  }
  .COGfirst div.firstHole {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: white;
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .COGsecond {
    position: relative;
    width: 80px;
    -webkit-transform: rotate(16deg);
            transform: rotate(16deg);
    top: 28px;
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
    animation: rotate_left 10s .1s infinite reverse linear;
    left: -24px;
    height: 80px;
  }
  .COGsecond div:nth-of-type(1) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .COGsecond div:nth-of-type(2) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .COGsecond div:nth-of-type(3) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .COGsecond div.secondPart {
    height: 80px;
    width: 80px;
    border-radius: ${borders.ml};
    position: absolute;
    background: ${colors.primary_dark};
  }
  .COGsecond div.secondHole {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .COGthird {
    position: relative;
    width: 60px;
    top: -65px;
    -webkit-transform-origin: 30px 30px;
            transform-origin: 30px 30px;
    -webkit-animation: rotate_left 10.2s .4s infinite linear;
            animation: rotate_left 10.2s .4s infinite linear;
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    left: 79px;
    height: 60px;
  }
  .COGthird div:nth-of-type(1) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .COGthird div:nth-of-type(2) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .COGthird div:nth-of-type(3) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .COGthird div.thirdPart {
    width: 60px;
    border-radius: ${borders.md};
    position: absolute;
    height: 60px;
    background: ${colors.neutral_darkest};
  }
  .COGthird div.thirdHole {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: ${colors.white};
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  /* Animations */
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate_left {
    from {
      -webkit-transform: rotate(16deg);
              transform: rotate(16deg);
    }
    to {
      -webkit-transform: rotate(376deg);
              transform: rotate(376deg);
    }
  }
  @keyframes rotate_left {
    from {
      -webkit-transform: rotate(16deg);
              transform: rotate(16deg);
    }
    to {
      -webkit-transform: rotate(376deg);
              transform: rotate(376deg);
    }
  }
  @-webkit-keyframes rotate_right {
    from {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    to {
      -webkit-transform: rotate(364deg);
              transform: rotate(364deg);
    }
  }
  @keyframes rotate_right {
    from {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    to {
      -webkit-transform: rotate(364deg);
              transform: rotate(364deg);
    }
  }
`