import styled, { keyframes } from 'styled-components';
import {
  colors,
  spacings,
  fontSizes,
  fontWeights,
  borders
} from '../../styles/theme'

interface InputContainerProps {
  isError: boolean;
  typePassword: string,
  block: boolean,
  backGround: string,
  marginBotton: string,
  icon: boolean,
  animation: boolean,
}

const loading = keyframes`  
   from { transform: rotate(0deg) translateX(2.5px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(2.5px) rotate(-360deg); }
`;

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width:100%;
  max-width: ${props => props.block ? "100%" : "330px"};
  margin-bottom: ${props => props.marginBotton}px;
  font-family: 'DM Sans', sans-serif;

  .formField-input{
    position: relative;

    input {
      border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}` : `${borders.xs} solid ${colors.neutral_medium}`};
      outline: none;
      width: 100%;
      padding: ${props => props.typePassword === "password" || props.icon === true ? "16px 40px 16px 16px" : "16px"};
      color: ${colors.neutral_darkest};
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      background: ${props => props.backGround};
      box-shadow: 0 0 0 30px ${props => props.backGround} inset;
      -webkit-box-shadow: 0 0 0 30px ${props => props.backGround} inset;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -webkit-appearance: none;
      border-radius: ${borders.lg};

      &:not(:placeholder-shown){
        border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}` : `${borders.xs} solid ${colors.primary}`};
      }

      &:read-only {
        border: ${borders.xs} solid ${colors.neutral_medium};
        color: ${colors.neutral_darker}
      }

      &:focus:not(:read-only) {
        border: ${props => props.isError ? `${borders.xs} solid ${colors.secondary_four}` : `${borders.xs} solid ${colors.primary}`};
      }

      &::placeholder {
        color: transparent;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        top: 0%;
        font-size: ${fontSizes.sl};
        color: ${colors.neutral_darkest};
      }
    }

    label {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 15px;
      padding: 0px ${spacings.xs};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
      font-weight: ${fontWeights.w700};
      background: ${props => props.backGround};
      transform: translateY(-50%);
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
    }

    div{
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 5px;
      padding: ${spacings.sm};
      transform: translateY(-50%);
      cursor: pointer;
      svg{
        animation: ${props => props.animation ? loading : ""} 1.5s linear infinite;
      }
    }
    
  }

  span{
    position: absolute;
    width: 100%;
    padding-top: ${spacings.xs};
    padding-left: ${spacings.md};
    color: ${props => props.isError ? `${colors.secondary_four}` : `${colors.neutral_darker}`};
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.xs};
  }
`