import { CheckBoxChangeEvent } from 'components/CheckBox'
import { ChangeEvent, useEffect, useState } from 'react'
import { useSession } from 'hooks/useSession'
import { api } from 'services/api'
import { useToast } from 'hooks/useToast'
import { BaseRoutes } from 'routes/BaseRoutes'
import { useHistory } from 'react-router'

type Definition = "CREATE" | "READ" | "UPDATE" | "DELETE"

interface RoleProps {
  roleName: string,
  definitions: Definition[],
}

const initRole:RoleProps = {
  roleName: '',
  definitions: [],
}

export const useNewRole = () => {
  const [role, setRole] = useState<RoleProps>(initRole);
  const [loading, setLoading] = useState(false);
  const [invalidForm, setInvalidForm] = useState(true);
  const history = useHistory();
  const session = useSession();
  const {setToast} = useToast();

  useEffect(()=>{
    if (role.roleName.length > 0 && role.definitions.length > 0) {
      setInvalidForm(false);
    } else {
      setInvalidForm(true);
    }
  }, [role])

  const push = (route: string): void => {
    history.push(route);
  }

  const createPermission = async() => {
    const config = {
      headers: { Authorization: `Bearer ${session.getSession()}`},
    };

    setLoading(true);
    api.post('users/roles', role, config).then((_) => {
      setToast({
        message: `Permissão cadastrada com sucesso`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      });
      push(BaseRoutes.permissions)
    }).catch((error) => {
      const errorResponse = error.response.data;
      setToast({
        message: `Erro ${errorResponse.StatusCode}: ${errorResponse.Message}`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }).finally(() => setLoading(false))
  }

  const handleInputFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRole({
      ...role,
      roleName: value,
    })
  }

  const handleCheckBoxChange = (e: CheckBoxChangeEvent) => {
    const enabled:boolean = e.checked;
    const definition:Definition = e.value;
    let roleDefinitions = [...role.definitions];

    if (enabled) {
      roleDefinitions.push(definition);
    } else {
      const index = roleDefinitions.indexOf(definition, 0);
      if (index > -1) {
        roleDefinitions.splice(index, 1);
      }
    }
    
    setRole({
      ...role,
      definitions: roleDefinitions,
    });
  }

  const handleOnSubmit = () => {
    createPermission();
  }

  return {
    loading,
    invalidForm,
    role,
    handleInputFieldChange,
    handleCheckBoxChange,
    handleOnSubmit
  }
}