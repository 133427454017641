import styled from 'styled-components';
import {
  colors,
  borders,
  spacings,
} from '../../../styles/theme'

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: 54px;
  grid-gap: 24px;

  & + div {
    margin-top: ${spacings.lg};
  }

  .sortable{
    display: flex;
    align-items: center;

    p{
      margin-right: ${spacings.md};
    }
  }

  .download-participants{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacings.md};
    border-radius: ${borders.md};
    background: ${colors.neutral_light};
    border: none;

    h3{
      margin-right: ${spacings.md};
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 50%;
  gap: 32px;
`

export const Row = styled.div`
  display: flex;
  &:first-child .remove-role {
    display: none;
  }

  .remove-role {
    width: 53px;
  }
`
export const Col = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 32px;
`

export const Col2 = styled.div`
  width: 16.66%;
  padding-right: 32px;

  &.d-flex {
    display: flex;
  }
`

export const RoleFormContent = styled.div`
  display: grid;
  grid-template-columns: 350px auto auto auto auto;
  align-items: center;
  grid-gap: 32px;
`