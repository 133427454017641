import styled from "styled-components";
import {
  colors,
  spacings,
  borders
} from '../../styles/theme';

interface BadgeProps {
  fontSize?: number;
  fontWeight?: string | number;
  margin?: string;
}

export const BadgeContainer = styled.span<BadgeProps>`
  display: inline-block;
  padding: ${spacings.sl};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize}px;
  color: ${colors.neutral_darkest};
  background: ${colors.neutral_light};
  border-radius: ${borders.md};
  margin: ${props => props.margin || 0};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;

  &:last-child{
    margin-right: ${spacings.lg};
  }

`