import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { BaseRoutes } from '../../routes/BaseRoutes';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

import { CheckBox } from '../../components/CheckBox';
import { RouteLink } from '../../components/Link';
import { Title } from '../../components/Title';
import { Separator } from '../../components/Separator';
import { InputFormField } from '../../components/InputFormField';
import { Button } from '../../components/Button';
import { StepByStep } from '../../components/StepByStep';
import { InputVerificationCode } from '../../components/InputVerificationCode';


import { isValidatedInput, ValidateInputProps } from '../../utils/validateInput';
import { Container } from './styles';
import login_img from '../../assets/imgs/img_login/login_img.png';
import forgotPassword_img from '../../assets/imgs/img_forgotPassword/img_password_sucess.svg';

const initialValues = {
  email: {
    value: "",
    error: false,
  },
  code: {
    value: "",
    error: false,
  },
  password: {
    value: "",
    error: false,
  },
  confirmPassword: {
    value: "",
    error: false,
  }
};

const initBtn = {
  disabled: true,
  load: false
};

const initTimerCode = {
  text: "Para reenviar o código, espere 60s",
  timer: true,
}

const initCheckBox = {
  check1: false,
  check2: false,
  check3: false,
  check4: false,
}

export const ForgotPassword: React.FC = () => {
  const { connectAPI } = useAuth();
  const { setToast } = useToast();

  const [ currentIndex, setCurrentIndex ] = useState(1);
  const [ inputsValues, setInputsValues ] = useState(initialValues);
  const [ controlerBtn, setControlerBtn ] = useState(initBtn);
  const [ token, setToken ] = useState("");
  const [ timerResendCode, setTimerRenderCode ] = useState(initTimerCode);
  const [ checkBox, setCheckBox ] = useState(initCheckBox);
  const [ progressPassword, setProgressPassword ] = useState(0);

  const nextStep = (number: any) => {
    setCurrentIndex(number)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setInputsValues({
      ...inputsValues,
      [targetName]: {
        value: targetValue,
        error: false,
      },
    });

    if (targetName === "email") onValidateStepOne(targetValue, targetName);
    if (targetName === "code") onValidateStepTwo(targetValue, targetName);
    if (targetName === "password") onValidatePasswordStepThree(targetValue, targetName);
    if (targetName === "confirmPassword") confirmPasswordStepThree(targetValue, targetName);

  };

  const onValidateStepOne = (targetValue: string, targetName: string) => {

    const inputEmail: ValidateInputProps[] = [
      {
        name: "email",
        valueInput: targetName === "email" ? targetValue : inputsValues.email.value,
        validateOptions: ['email'],
      }
    ]
    
    const isValidateInputs = isValidatedInput(inputEmail);
    const { validatedObject, hasError } = isValidateInputs
    
    setInputsValues({
      ...inputsValues,
      email: {
        value: targetValue,
        error: !validatedObject["email"].isValidated,
      },
    });

    setControlerBtn({
      disabled: hasError,
      load: false,
    });
  };

  const handleSubmiteStepOne = async (event?: FormEvent) => {
    event?.preventDefault();
    setControlerBtn({ ...controlerBtn, load: true });

    if (!controlerBtn.load) {
      const email = inputsValues.email.value;

      const response = await connectAPI({
        url:`validation-account/${email}`, 
        body:{},  
        type:"post",
        messageError: "E-mail inválido"
      });

      if (response.connect) {
        setToken(response.data.validationAccountId);
        nextStep(2)
        timerResender(60);
      }
    }

    setControlerBtn({ ...controlerBtn, load: false });
  }

  const onValidateStepTwo = (targetValue: string, targetName: string) => {
    const codeLength = targetValue.length;

    setInputsValues({
      ...inputsValues,
      code: {
        value: targetValue,
        error: false,
      },
    });

    setControlerBtn({
      disabled: codeLength < 5 ? true : false,
      load: false
    });
  };

  const handleSubmiteStepTwo = async (event?: FormEvent) => {
    event?.preventDefault();
    setControlerBtn({ ...controlerBtn, load: true });

    if (!controlerBtn.load) {
      const response = await connectAPI({
        url:`validation-account/${token}/verify`,
        body: { token: inputsValues.code.value }, 
        config: {}, 
        type: "put"
      });
      
      const verified = response.data.verified;
      
      if(verified) {
        nextStep(3);
      }

      if (!verified) {
        setToast({
          message: "Código invalido",
          type: 'error',
          isActive: true,
          timeToRemoveToast: 3000
        })
      }
    }

    setControlerBtn({ ...controlerBtn, load: false });
  }

  const timerResender = (time: number) => {
    let oneMinuto = time;

    const timer = setInterval(() => {
      if (oneMinuto > 0) {
        oneMinuto -= 1
        setTimerRenderCode((timerResendCode) => ({
          ...timerResendCode,
          text: `Para reenviar o código, espere ${oneMinuto < 10 ? `0${oneMinuto}s` : `${oneMinuto}s`}`
        }));
      }
      if (oneMinuto === 0) {
        clearInterval(timer);
        setTimerRenderCode((timerResendCode) => ({
          ...timerResendCode,
          timer: false
        }));
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }

  const resendEmail = () => {
    setTimerRenderCode(() => ({
      text: `Para reenviar o código, espere 60s`,
      timer: true
    }));

    handleSubmiteStepOne();
    timerResender(60);
  }

  const onValidatePasswordStepThree = (targetValue: string, targetName: string) => {

    const inputPassword: ValidateInputProps[] = [
      {
        name: "password",
        valueInput: targetName === "password" ? targetValue : inputsValues.password.value,
        validateOptions: [
          'characterSpecial',
          'characterNumber',
          'characterUpper',
          'minCharacter'
        ],
        min: 8
      }
    ]

    const isValidateInputs = isValidatedInput(inputPassword);
    const { validatedObject, hasError } = isValidateInputs
    const objTypeError = validatedObject["password"].typeError;
    const valueIsNotEmpty = targetValue.length > 0;

    setInputsValues({
      ...inputsValues,
      password: {
        value: targetValue,
        error: hasError,
      },
    });

    setCheckBox({
      check1: objTypeError.indexOf('minCharacter') === -1 && valueIsNotEmpty ? true : false,
      check2: objTypeError.indexOf('characterSpecial') === -1 && valueIsNotEmpty ? true : false,
      check3: objTypeError.indexOf('characterNumber') === -1 && valueIsNotEmpty ? true : false,
      check4: objTypeError.indexOf('characterUpper') === -1 && valueIsNotEmpty ? true : false,
    })

    setProgressPassword(
      objTypeError.length === 0 ? 100 :
        objTypeError.length === 1 ? 75 :
          objTypeError.length === 2 ? 50 :
            objTypeError.length === 3 ? 25 : 0);

    if (inputsValues.confirmPassword.value.length > 0) {

      if (targetValue === inputsValues.confirmPassword.value) {
        setInputsValues({
          ...inputsValues,
          confirmPassword: {
            value: inputsValues.confirmPassword.value,
            error: false,
          },
          password: {
            value: targetValue,
            error: hasError,
          },
        });
      } else {
        setInputsValues({
          ...inputsValues,
          confirmPassword: {
            value: inputsValues.confirmPassword.value,
            error: true,
          },
          password: {
            value: targetValue,
            error: hasError,
          },
        });
      }
    }
  };

  const confirmPasswordStepThree = (targetValue: string, targetName: string) => {
    const inputPassword = inputsValues.password.value;

    if (targetValue === inputPassword) {
      setInputsValues({
        ...inputsValues,
        confirmPassword: {
          value: targetValue,
          error: false,
        },
      });
    } else {
      setInputsValues({
        ...inputsValues,
        confirmPassword: {
          value: targetValue,
          error: true,
        },
      });
    }

  }

  const onValidateStepThree = () => {
    const inputPassword = inputsValues.password.error;
    const inputConfirmPassword = inputsValues.confirmPassword.error;
    const inputPasswordLength = inputsValues.password.value.length > 0;
    const inputConfirmPasswordLength = inputsValues.confirmPassword.value.length > 0;

    if (!inputPassword && !inputConfirmPassword && inputPasswordLength && inputConfirmPasswordLength) {
      setControlerBtn({ ...controlerBtn, disabled: false })
    } else {
      setControlerBtn({ ...controlerBtn, disabled: true })
    }
  }

  const handleStepThree = async (event: FormEvent) => {
    event.preventDefault();
    setControlerBtn({ ...controlerBtn, load: true });

    if (!controlerBtn.load) {
      const req = {
        email: inputsValues.email.value,
        password: inputsValues.password.value,
        validationToken: inputsValues.code.value
      };

      const response = await connectAPI({
        url:"authentication/reset-password", 
        body:{ ...req }, 
        config:{}, 
        type:"post"
      });

      if (response.connect) {
        nextStep(4);
      }

      if (!response.connect) {
        setToast({
          message: "Senha inválida!",
          type: 'error',
          isActive: true,
          timeToRemoveToast: 3000
        })
      }
    }
    setControlerBtn({ ...controlerBtn, load: false });
  }

  useEffect(() => {
    if(currentIndex === 3)
      onValidateStepThree();
  }, [inputsValues])

  useEffect(() => {
    return () => setInputsValues(initialValues)
  }, [])


  return (
    <StepByStep currentIndex={currentIndex}>

      <Container index={currentIndex}>
        <div className="content">
          <div className="form-content">
            <div className="form-button">
              <RouteLink text="Voltar para login" route={BaseRoutes.login} icon={true} />
            </div>

            <div className="form-header">
              <Title elementHTML="h1" className="subtitle-medium" text="Recuperar senha" />
              <Title elementHTML="p" className="paragraph" text="Informe seu e-mail, enviaremos um código para recuperar sua senha" />
            </div>

            <form onSubmit={handleSubmiteStepOne}>
              <div className="form-body">
                <InputFormField
                  name="email"
                  value={inputsValues.email.value}
                  typeInput="email"
                  label="E-mail"
                  error={inputsValues.email.error}
                  mensageError="Ops, E-mail invalido"
                  autoFocus={true}
                  onChange={handleChange}
                />
              </div>

              <Button
                type="submit"
                label="Enviar código"
                size="large"
                block={true}
                typeBtn="blue"
                disable={controlerBtn.disabled}
                load={controlerBtn.load}
              />

            </form>
          </div>
        </div>
        <div className="login-img">
          <figure>
            <img src={login_img} alt="logo marca compre e confie" />
          </figure>
        </div>
      </Container>

      <Container index={currentIndex}>
        <div className="content">
          <div className="form-content">
            <div className="form-button">
              <RouteLink text="Voltar para login" route={BaseRoutes.login} icon={true} />
            </div>

            <div className="form-header">
              <Title elementHTML="span" text="">
                <h1 className="subtitle-medium">{`Digite o código de 5 dígitos enviado via e-mail para ${inputsValues.email.value}`}</h1>
              </ Title>
            </div>
            <form onSubmit={handleSubmiteStepTwo}>
              <div className="form-body">
                <InputVerificationCode onChange={onValidateStepTwo} value={inputsValues.code.value} placeholder="" length={5} />
              </div>

              <div className="form-footer">
                {timerResendCode.timer ?
                  <Title text={timerResendCode.text} elementHTML="p" className="paragraph" /> :
                  <Button
                    type="button"
                    label="Reenviar"
                    size="large"
                    block={true}
                    typeBtn="outline"
                    onClick={resendEmail}
                  />
                }

                <Button
                  type="submit"
                  label="Enviar código"
                  disable={controlerBtn.disabled}
                  load={controlerBtn.load}
                  size="large"
                  block={true}
                  typeBtn="blue"
                />
              </div>
            </form>

          </div>
        </div>
        <div className="login-img">
          <figure>
            <img src={login_img} alt="logo marca compre e confie" />
          </figure>
        </div>
      </Container>

      <Container index={currentIndex}>
        <div className="content">
          <div className="form-content">
            <div className="form-button">
              <RouteLink text="Voltar para login" route={BaseRoutes.login} icon={true} />
            </div>

            <div className="form-header">
              <Title elementHTML="h1" className="subtitle-medium" text="Cadastre sua nova senha" />
            </div>

            <form onSubmit={() => { }}>
              <div className="form-body">
                <InputFormField
                  autoComplete="off"
                  name="password"
                  value={inputsValues.password.value}
                  typeInput="password"
                  label="Senha"
                  error={inputsValues.confirmPassword.error}
                  mensageError="Digite a mesma senha em ambos os campos"
                  autoFocus={true}
                  onChange={handleChange}
                />

                <Separator marginY="4" progressBar={progressPassword} />

                <Title elementHTML="span" className="tag" text="Sua senha deve conter ao menos:" />

                <div className="form-body-check">
                  <div>
                    <CheckBox
                      label="8 caracteres;"
                      name="check1"
                      value=""
                      readOnly={true}
                      checked={checkBox.check1}
                    />

                    <CheckBox
                      label="1 caracter especial;"
                      name="check2"
                      value=""
                      readOnly={true}
                      checked={checkBox.check2}
                    />
                  </div>

                  <div>
                    <CheckBox
                      label="1 numero;"
                      name="check3"
                      value=""
                      readOnly={true}
                      checked={checkBox.check3}
                    />

                    <CheckBox
                      label="1 letra maiúscula;"
                      name="check4"
                      value=""
                      readOnly={true}
                      checked={checkBox.check4}
                    />
                  </div>
                </div>

                <InputFormField
                  autoComplete="off"
                  value={inputsValues.confirmPassword.value}
                  name="confirmPassword"
                  typeInput="password"
                  error={inputsValues.confirmPassword.error}
                  mensageError="Digite a mesma senha em ambos os campos"
                  label="Confirme sua senha"
                  onChange={handleChange}
                />

              </div>

              <div className="form-footer">

                <Button
                  type="submit"
                  label="Cadastrar senha"
                  disable={controlerBtn.disabled}
                  load={controlerBtn.load}
                  size="large"
                  block={true}
                  typeBtn="blue"
                  onClick={handleStepThree}
                />
              </div>
            </form>

          </div>
        </div>
        <div className="login-img">
          <figure>
            <img src={login_img} alt="logo marca compre e confie" />
          </figure>
        </div>
      </Container>

      <Container index={currentIndex}>
        <div className="content">
          <div className="form-content">


            <div className="form-body">
              <img src={forgotPassword_img} alt="" />
              <Title elementHTML="h1" className="subtitle-medium" text="Sua senha foi atualizada com sucesso!" />
            </div>

            <div className="form-footer">
              <RouteLink text="Ir para login" route="/" icon={true}></RouteLink>
            </div>


          </div>
        </div>
        <div className="login-img">
          <figure>
            <img src={login_img} alt="logo marca compre e confie" />
          </figure>
        </div>
      </Container>
    
    </StepByStep >
  )
}