import { useCallback, useContext} from "react";
import { createContext, ReactNode } from "react";

interface SessionProviderProps {
  children: ReactNode
}

interface SessionContextProps {
  signIn: (token: string) => void;
  signOut: () => void;
  getSession: () => string;
}

const SessionContext = createContext({} as SessionContextProps);

export const SessionProvider = ({ children } : SessionProviderProps) => {
  
  const getSession = useCallback(() => {
    const token = localStorage.getItem("backoffice@confi") || '';
    return token.length > 0 ? token : "";
  }, [])

  const signIn = useCallback((token: string) => {
    localStorage.removeItem("backoffice@confi");
    localStorage.setItem("backoffice@confi", token);
  }, [])
  
  const signOut = useCallback(() => {
    localStorage.removeItem("backoffice@confi");
  }, [])

  return (
    <SessionContext.Provider value={{
      signIn,
      signOut,
      getSession
    }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
