import { SeparatorLine } from './styles';

export interface SeparatorProps {
  maxWidth?: string,
  marginY?: string,
  progressBar?: number,
}

export const Separator: React.FC<SeparatorProps> = ({ maxWidth = "330", marginY = "40", progressBar = 0 }) => {
  return (
    <SeparatorLine maxWidth={maxWidth} marginY={marginY} progressBar={progressBar} />
  )
}