import React from "react";
import { LoadingContainer } from "./styles";

export const Loading: React.FC = () => {
  return (
    <LoadingContainer>
      <div className='loader'>
        <div className='loader_cogs'>
          <div className='COGfirst'>
            <div className='firstPart'></div>
            <div className='firstPart'></div>
            <div className='firstPart'></div>
            <div className='firstHole'></div>
          </div>
          <div className='COGsecond'>
            <div className='secondPart'></div>
            <div className='secondPart'></div>
            <div className='secondPart'></div>
            <div className='secondHole'></div>
          </div>
          <div className='COGthird'>
            <div className='thirdPart'></div>
            <div className='thirdPart'></div>
            <div className='thirdPart'></div>
            <div className='thirdHole'></div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  )
}