import { useHistory, useParams } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { useAlert } from '../../../hooks/useAlert';
import { useAuth } from '../../../hooks/useAuth';
import { useSession } from '../../../hooks/useSession';
import { useToast } from '../../../hooks/useToast';

import { Menu } from '../../../components/Menu';
import { Title } from '../../../components/Title';
import { Card } from '../../../components/Card';
import { InputFormField } from '../../../components/InputFormField';
import { CheckBox } from '../../../components/CheckBox';
import { Button } from '../../../components/Button';
import { Separator } from '../../../components/Separator';
import { BreadCrumb } from '../../../components/BreadCrumb';
import { TableSimple } from '../../../components/TableSimple';
import { Tooltip } from '../../../components/Tooltip';
import { Select } from '../../../components/Select';
import { Footer } from '../../../components/Footer';
import { Badge } from '../../../components/Badge';
import { InputDatePicker } from '../../../components/InputDatePicker';
import { Header } from '../../../components/Header';
import { Loading } from '../../../components/Loading';
import { PlaceHolderNotExists } from '../../../components/PlaceholderNotExists';
import { GoToBack } from '../../../components/GoToBack';

import { apiBlob } from '../../../services/api';
import { isValidatedInput, ValidateInputProps } from '../../../utils/validateInput';
import { checkChange } from '../../../utils/checkChange';
import { convertDateToShowInput } from '../../../utils/formattingDate';
import { formattingOnlyNumber, formattingOnlyNumberCurrency } from '../../../utils/formattingOnlyNumber';
import { iconArrowRight, iconArrowRightWhite, iconMessage, iconTrash } from '../../../assets/icons';
import { CardContent, TicketContainer, HeaderContainer, WinnersContainer, FooterContainer } from './styles';

import ticket from '../../../assets/imgs/img_lottery/ticket.png';
import { menuListItems } from '../../../assets/menu/menuList';
interface ParamsProps {
  id: string;
  planDescription: string;
}

interface LotteryProps {
  lotteryPlanId: number
  description: string
}

interface BallotBoxDetailProps {
  ballotboxId: number,
  drawn: boolean,
  serie: string,
  startDate: string,
  endDate: string,
  ballotBoxType: string,
  cefLotteryDate: string,
  mccLotteryDate: string,
  capacity: string,
  coupons: string,
  prizeSuggestionId: string,
}

interface ContestProps {
  couponNumber: number;
  date: string;
  number: number;
  prize1: number;
  prize2: number;
  prize3: number;
  prize4: number;
  prize5: number;
}

interface BallotBoxPrizesSelect {
  prizeId: string;
  description: string;
}

interface BallotBoxSuggestionsSelect {
  prizeSuggestionId: string;
  suggestion: string;
}

interface WinnerProps {
  couponCode: string,
  couponId: number,
  cpf: string,
  email: string,
  name: string,
  phoneNumber: string,
}

interface PrizeProps {
  prizeId: string,
  value: string,
  prizeSuggestionId: string,
  suggestion: string
}

interface BallotBoxTypesSelect {
  description: string[];
}

const initPrize = {
  prizeId: "0",
  value: "",
  prizeSuggestionId: "0",
  suggestion: "",
}

const initBallotDetails = {
  serie: "",
  startDate: "",
  endDate: "",
  ballotBoxType: "",
  cefLotteryDate: "",
  mccLotteryDate: "",
  capacity: "",
  coupons: "",
  prizeSuggestionId: "",
  prizeId: "",
}

const initTableHeader = ["Cupom", "CPF", "Nome", "Telefone", "Email", ""];

export const BallotDetails: React.FC = () => {
  const {id } = useParams<ParamsProps>();  
  const { push } = useHistory();
  const { setToast } = useToast();
  const { getSession } = useSession();
  const { connectAPI, connectAllAPI, cancelRequest } = useAuth();
  const { openAlert, closeAlert, alert, setTextAlertInterface, setAlertPosition, resetTypeContinue} = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [actionAlert, setActionAlert] = useState("")
  const [ballotBoxData, setBallotBoxData] = useState<BallotBoxDetailProps>();
  const [contestData, setContestData] = useState<ContestProps>();
  const [winnersData, setWinnersData] = useState<WinnerProps[]>([]);
  const [lotteryData, setLotteryData] = useState<LotteryProps>();
  const [prizeData, setPrizeData] = useState<PrizeProps>(initPrize);

  //SELECTS
  const [selectBallotBoxType, setSelectBallotBoxType] = useState([]);
  const [selectBallotBoxPrize, setSelectBallotBoxPrize] = useState([]);
  const [selectBallotBoxPrizeId, setSelectBallotBoxPrizeId] = useState([]);
  const [selectBallotBoxSuggestions, setSelectBallotBoxSuggestions] = useState([]);
  const [selectBallotBoxSuggestionsId, setSelectBallotBoxSuggestionsId] = useState([]);
  const [currentSelect, setCurrentSelect] = useState("");

  //CHANGE DATA
  const [inputsValues, setInputsValues] = useState(initBallotDetails);
  const [changeInputs, setChangeInputs] = useState({});
  const [changeFooter, setChangeFooter] = useState<any>([])
  const [submiteButton, setSubmiteButton] = useState(true);
  const [inputsError, setInputsError] = useState<any>({});

  const session = getSession();
  const config = {
    headers: { Authorization: `Bearer ${session}`,    
    responseType: "blob" // important
  }};

  const getBallotDetailsData = async () => {
    setIsLoading(true);

    const response = await connectAllAPI([
      {url: `lotterys/ballotboxes/${id}`, type: "get", messageError: "Falha ao carregar dados da urna"},
      {url: "lotterys/ballotboxes/type", type: "get", messageError: "Falha ao carregar Id Tipo Urna"},
      {url: "lotterys/prizes", type:"get", messageError: "Falha ao listar prêmios"},
    ])

    if(response[0].connect){
      const data = response[0].data;
      setBallotBoxData(data.ballotBoxDetail);
      setContestData(data.contest);
      setLotteryData(data.lotteryDetail);
      setPrizeData(data.prize);

      const responsePrize = await connectAPI({
        url: `lotterys/prizes/${data.prize.prizeId}`,
        type: "get", 
        messageError: "Falha ao carregar sugestões de prêmio"
      });
  
      if(responsePrize.connect){
        const arraySelect: any = [];
        const arraySelectId: any = [];
  
        responsePrize.data.prizeSuggestion && responsePrize.data.prizeSuggestion.map((item:BallotBoxSuggestionsSelect, index:any) => (
          arraySelect.push(item.suggestion),
          arraySelectId.push(item.prizeSuggestionId),
          index === 0 && (
            setCurrentSelect(item.suggestion)
          )
        ))        
        setSelectBallotBoxSuggestions(arraySelect);
        setSelectBallotBoxSuggestionsId(arraySelectId);
      }

      const newObjtBallotBox = {
        serie: data.ballotBoxDetail.serie,
        startDate: data.ballotBoxDetail.startDate,
        endDate: data.ballotBoxDetail.endDate,
        ballotBoxType: data.ballotBoxDetail.ballotBoxType,
        cefLotteryDate: data.ballotBoxDetail.cefLotteryDate,
        mccLotteryDate: data.ballotBoxDetail.mccLotteryDate,
        capacity: formattingOnlyNumber(data.ballotBoxDetail.capacity),
        coupons: formattingOnlyNumber(data.ballotBoxDetail.coupons),
        prizeId: data.prize ? String(data.prize.prizeId) : "",
        prizeSuggestionId: data.prize ? String(data.prize.prizeSuggestionId) : ""
      }

      setInputsValues({
        ...inputsValues,
        ...newObjtBallotBox
      });

      setChangeInputs({
        ...newObjtBallotBox
      });

      handleSelectPrizeSuggestion(data.prize.prizeId);

      if (data.ballotBoxDetail) {
        let winnersList = data.winners.map((item: WinnerProps) => ({
          couponCode: item.couponCode,
          cpf: item.cpf,
          name: item.name,
          phoneNumber: item.phoneNumber,
          email: item.email,
          buttons: buttonsToTable(item, data.lotteryDetail, data.ballotBoxDetail)
        }))
        setWinnersData(winnersList);
      }
    }

    if(response[1].connect){
      const data = response[1].data;
      const arraySelect: any = [];
      data.map((item:BallotBoxTypesSelect) => (
        arraySelect.push(item.description)
      ))
      setSelectBallotBoxType(arraySelect);
    }

    if(response[2].connect){
      const data = response[2].data;
      const arraySelect: any = [];
      const arraySelectId: any = [];

      data.map((item:BallotBoxPrizesSelect) => (
        arraySelect.push(formattingOnlyNumberCurrency(item.description)),
        arraySelectId.push(item.prizeId)
      ))

      setSelectBallotBoxPrize(arraySelect);
      setSelectBallotBoxPrizeId(arraySelectId); 
    }

    setIsLoading(false);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> | any, dataName?: any) => {

    if (!!dataName) {
      setInputsValues({
        ...inputsValues,
        [dataName]: event
      })
    } else {
      const targetName = event.target.name;
      let targetValue = event.target.value;

      if (targetName === "capacity" || targetName === "coupons") {
        targetValue = formattingOnlyNumber(targetValue)
      }

      setInputsValues({
        ...inputsValues,
        [targetName]: targetValue
      })
    }
  }

  const buttonsToTable = (winner: WinnerProps, lottery: LotteryProps, ballot: BallotBoxDetailProps) => {
    return (
      <div className="tbody-flex">
        <Tooltip text="Enviar mensagem">
          <a href={`https://api.whatsapp.com/send?phone=${winner.phoneNumber}`} target="_blank" rel="noreferrer">
            <Button
              label=""
              size="small"
              typeBtn="default"
              onlyIcon={true}
              icon={iconMessage}
            />
          </a>
        </Tooltip>

        <Tooltip text="Ir para detalhes">
          <Button
            label=""
            size="small"
            typeBtn="default"
            onlyIcon={true}
            icon={iconArrowRight}
            onClick={() => push(`/lottery/winner-details/${lottery.description}/${ballot.ballotboxId}/${winner.couponId}`)}
          />
        </Tooltip>
      </div>
    )
  }

  const handleSelectPrizeSuggestion = async (id: number) => {
    const arraySelect: any = [];
    const arraySelectId: any = [];
    const response = await connectAPI({
      url: `lotterys/prizes/${id}`, 
      config,
      type: "get", 
      messageError: "Falha ao carregar sugestões de prêmio"
    });

    if(response.connect){
      const data = response.data;
      data.prizeSuggestion && data.prizeSuggestion.map((item:BallotBoxSuggestionsSelect, index:any) => (
        arraySelect.push(item.suggestion),
        arraySelectId.push(item.prizeSuggestionId),
        index === 0 && (
          setCurrentSelect(item.suggestion)
        )
      ))
      setSelectBallotBoxSuggestions(arraySelect);
      setSelectBallotBoxSuggestionsId(arraySelectId);
    }
  }

  const downloadDOC = async (title: string, item: string) => {
    try {
      const response = await apiBlob.get(`lotterys/ballotboxes/${item}`, config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${title}.docx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);

    } catch (error) {
      console.log(error);
      setToast({
        message: `Erro ao baixar documento.`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }
  }

  const validadeErrorInputs = () => {
    const initValues: ValidateInputProps[] = [
      {
        name: "serie",
        valueInput: inputsValues.serie,
        validateOptions: ['minCharacter'],
        min: 3
      },
      {
        name: "ballotBoxType",
        valueInput: inputsValues.ballotBoxType,
        validateOptions: ['minCharacter'],
        min: 3
      },
      {
        name: "capacity",
        valueInput: inputsValues.capacity,
        validateOptions: ['minCharacter'],
        min: 1
      },
      {
        name: "coupons",
        valueInput: inputsValues.coupons,
        validateOptions: ['minCharacter'],
        min: 1
      }
    ]

    const { validatedObject, hasError } = isValidatedInput(initValues);

    setSubmiteButton(hasError);
    setInputsError(validatedObject);
  }

  const getProps = (props: string) => (
    {
      "serie": "serie",
      "startDate": "Data Inicial",
      "endDate": "Data Final",
      "ballotBoxType": "Id Tipo Urna",
      "cefLotteryDate": "Sorteio CEF",
      "mccLotteryDate": "Data MCC",
      "capacity": "Capacidade",
      "prizeId": "Prêmio",
      "prizeSuggestionId": "Sugestão de prêmio"
    }
  )[props];

  const hiddenFooter = () => {
    closeAlert();
    setInputsValues({ ...inputsValues, ...changeInputs })
    setChangeFooter([]);
  }

  const handleDelete = async () => {
    try {
      await connectAPI({
        url: `lotterys/ballotboxes/${id}`, 
        config,
        type: "delete",
      });
      
      setToast({
        message: `Urna excluida com sucesso.`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      })
      
      push(`/lottery/lottery-details/${lotteryData?.lotteryPlanId}`)
    } catch (error) {
      setToast({
        message: `Erro ao excluir urna.`,
        isActive: true,
        type: 'error',
        timeToRemoveToast: 3000
      })
    }finally{
      closeAlert();
    }
  }

  const handleSubmit = async () => {
    
    const data = {
      "serie": inputsValues.serie,
      "startDate": new Date(inputsValues.startDate),
      "endDate": new Date(inputsValues.endDate),
      "ballotBoxType": inputsValues.ballotBoxType,
      "cefLotteryDate": new Date(inputsValues.cefLotteryDate),
      "mccLotteryDate": new Date(inputsValues.mccLotteryDate),
      "capacity": String(inputsValues.capacity.replace(/[^\d]+/g, '')),
      "prizeSuggestionId": Number(inputsValues.prizeSuggestionId),
    }

    const response = await connectAPI({
      url: `lotterys/ballotboxes/${id}`, 
      body: data, 
      config, 
      type: "put"
    });

    if (response.connect) {
      setToast({
        message: `Detalhes da urna atualizado com sucesso`,
        isActive: true,
        type: 'success',
        timeToRemoveToast: 3000
      })

      setChangeFooter([]);
      setChangeInputs({ ...inputsValues });
    }

    closeAlert();
  }

  const showAlertDelete = () => {
    setActionAlert("delete");
    setAlertPosition("center");
    setTextAlertInterface({
      title: "Excluir urna ?",
      description: "Atenção! Essa ação não pode ser revertida.",
      closeAlertText: "Voltar",
      continueAlertText: "Sim"
    })
    openAlert(true);
  }

  const showAlertUpdate = () => {
    setActionAlert("update")
    openAlert(true);
  }

  useEffect(() => {
    getBallotDetailsData();

    return () => {
      setBallotBoxData(undefined);
      setContestData(undefined);
      setLotteryData(undefined);
      setPrizeData(initPrize);
      cancelRequest.cancel("cancel");
    }
  }, [])

  useEffect(() => {    
    setPrizeData({...prizeData , suggestion: currentSelect})
  }, [ballotBoxData, selectBallotBoxPrize, currentSelect]);

  useEffect(() => {
    Number(inputsValues.prizeId) > 0 && handleSelectPrizeSuggestion(Number(inputsValues.prizeId))  
    const changed = checkChange(inputsValues, changeInputs);
    setChangeFooter(changed);
    validadeErrorInputs();
  }, [inputsValues])

  useEffect(() => {
    if(alert.typeContinue === "save"){
      if(actionAlert === "update"){
        handleSubmit();
      }

      if(actionAlert === "delete"){
        handleDelete();
      }
      resetTypeContinue();
    }
    
    if(alert.typeContinue === "discard"){
      resetTypeContinue();
      hiddenFooter(); 
      closeAlert();
    };
  }, [alert, actionAlert])

  if (isLoading) {
    return (
      <Menu links={menuListItems}>

        <Header>
          <HeaderContainer>
            <div>
              <GoToBack path={`/lottery`} />
            </div>

            <div className="header-text">
              <BreadCrumb itens={["Plano", "Detalhes da Urna"]} itemActive="Detalhes da Urna" />
            </div>
          </ HeaderContainer>
        </Header>

        <Loading />
      </Menu>
    )
  }

  if(!isLoading && ballotBoxData && lotteryData) {
    return (
      <Menu links={menuListItems}>

        <Header>
          <HeaderContainer>
            <div>
              <GoToBack path={`/lottery/lottery-details/${lotteryData.lotteryPlanId}`} />
            </div>

            <div className="header-text">
              <BreadCrumb itens={[lotteryData.description, "Detalhes da Urna"]} itemActive="Detalhes da Urna" />
            </div>
          </ HeaderContainer>
        </Header>

        <Card title="Dados da Urna">
          <CardContent>
            <div className="sortable">
              <Title text="Sorteada?" elementHTML="p" className="overline" />
              <CheckBox
                name="drawn"
                label={ballotBoxData.drawn ? "Sim" : "Não"}
                value={ballotBoxData.drawn}
                checked={true}
                checkedError={!ballotBoxData.drawn}
                readOnly={true}
                onChange={() => { }}
              />
            </div>

            <InputFormField
              name="serie"
              label="Série"
              backGround="white"
              marginBotton="0"
              value={inputsValues.serie}
              error={inputsError.serie ? !inputsError.serie.isValidated : false}
              mensageError="mínimo 3 digitos"
              readOnly={!!winnersData.length}
              onChange={handleChange}
            />

            <InputDatePicker
              name="startDate"
              label="Data inicial"
              marginBotton="0"
              selected={new Date(inputsValues.startDate) || null}
              value={convertDateToShowInput(inputsValues.startDate)}
              readOnly={!!winnersData.length}
              onChange={(event) => handleChange(event, "startDate")}
            />

            <InputDatePicker
              name="endDate"
              label="Data final"
              marginBotton="0"
              selected={new Date(inputsValues.endDate) || null}
              value={convertDateToShowInput(inputsValues.endDate)}
              readOnly={!!winnersData.length}
              onChange={(event) => handleChange(event, "endDate")}
            />

            <Select 
              name="Tipo" 
              value={inputsValues.ballotBoxType}
              appearWhere="top"
              optionsValue={selectBallotBoxType}
              optionsDescription={selectBallotBoxType}
              onChange={(e: any) => handleChange(e["valueId"], "ballotBoxType")}
              readOnly={!!winnersData.length}

            />
          </CardContent>
        </Card>
      
        <Card title="Dados Sorteio">
          <CardContent>
            <InputDatePicker
              name="cefLotteryDate"
              label="Sorteio CEF"
              marginBotton="0"
              selected={new Date(inputsValues.cefLotteryDate) || null}
              value={convertDateToShowInput(inputsValues.cefLotteryDate)}
              readOnly={!!winnersData.length}
              onChange={(event) => handleChange(event, "cefLotteryDate")}
            />

            <InputDatePicker
              name="mccLotteryDate"
              label="Data MCC"
              marginBotton="0"
              selected={new Date(inputsValues.mccLotteryDate) || null}
              value={convertDateToShowInput(inputsValues.mccLotteryDate)}
              readOnly={!!winnersData.length}
              onChange={(event) => handleChange(event, "mccLotteryDate")}
            />

            <InputFormField
              name="capacity"
              label="Capacidade"
              value={formattingOnlyNumber(inputsValues.capacity)}
              error={inputsError.capacity ? !inputsError.capacity.isValidated : false}
              mensageError="mínimo 3 digitos"
              backGround="white"
              marginBotton="0"
              readOnly={!!winnersData.length}
              onChange={handleChange}
            />

            <InputFormField
              name="coupons"
              label="Qtd Cupons"
              value={formattingOnlyNumber(inputsValues.coupons)}
              error={inputsError.coupons ? !inputsError.coupons.isValidated : false}
              mensageError="mínimo 3 digitos"
              backGround="white"
              marginBotton="0"
              readOnly={true}
              onChange={handleChange}
            />

          </CardContent>
            <CardContent>
              <Select 
                name={"Prêmio"}
                value={formattingOnlyNumberCurrency(prizeData.value)}
                appearWhere="top" 
                optionsValue={selectBallotBoxPrizeId}
                optionsDescription={selectBallotBoxPrize}
                onChange={(e: any) => handleChange(e["valueId"], "prizeId")}
                readOnly={!!winnersData.length}
              />
              
              <Select 
                name={"Sugestão de prêmio"} 
                value={prizeData.suggestion}
                appearWhere="top" 
                optionsValue={selectBallotBoxSuggestionsId}
                optionsDescription={(selectBallotBoxSuggestions)}
                onChange={(e: any) => handleChange(e["valueId"], "prizeSuggestionId")}
                readOnly={!!winnersData.length}
              />
            </CardContent>
        </Card>

        {!winnersData.length ? (
          <TicketContainer>  
            <div className="ticket-img">
              <img src={ticket} alt="" />
            </div>

            <div className="ticket-ballot">
              <Title text="Sorteio disponível em" elementHTML="span" className="tag" />
              <Title text={convertDateToShowInput(ballotBoxData.cefLotteryDate)} elementHTML="p" className="subtitle-medium" />
            </div>
          </TicketContainer>
        ) : (
            <WinnersContainer>
                <div className="winners-left-cards">
                  <Card title="Loteria Federal">

                    <div className="federal-lottery-drawn-number">
                      <Title 
                        text="Número sorteado:"
                        className="overline" 
                        elementHTML="p"
                      />
                      <Title 
                        text={String(contestData?.couponNumber)}
                        className="subtitle" 
                        elementHTML="h4"
                      />
                    </div>

                    <div className="federal-lottery-contest">
                      <Title 
                        text="Concurso:"
                        className="overline" 
                        elementHTML="p"
                      />
                      <Title 
                        text={`${contestData?.number} de ${convertDateToShowInput(contestData?.date)}`}
                        className="subtitle-small" 
                        elementHTML="strong"
                      />
                    </div>

                    <div className="federal-lottery-list-awards">
                      <ul>
                        <li>
                          <Title 
                            text={`1º Prêmio: ${contestData?.prize1}`}
                            className="paragraph-small" 
                            elementHTML="p"
                          />
                        </li>
                        <li>
                          <Title 
                            text={`2º Prêmio: ${contestData?.prize2}`}
                            className="paragraph-small" 
                            elementHTML="p"
                          />
                        </li>
                        <li>
                          <Title 
                            text={`3º Prêmio: ${contestData?.prize3}`}
                            className="paragraph-small" 
                            elementHTML="p"
                          />
                        </li>
                        <li>
                          <Title 
                            text={`4º Prêmio: ${contestData?.prize4}`}
                            className="paragraph-small" 
                            elementHTML="p"
                          />
                        </li>
                        <li>
                          <Title 
                            text={`5º Prêmio: ${contestData?.prize5}`}
                            className="paragraph-small" 
                            elementHTML="p"
                          />
                        </li>
                      </ul>
                    </div>
                  </Card>

                  <Card title="Gestão de sorteios">
                    <div className="card-content-buttons">
                      <Button
                        typeBtn="blue"
                        block={true}
                        icon={iconArrowRightWhite}
                        label="Baixar apuração"
                        spaceBetween={true}
                        onClick={() => downloadDOC("Apuração", "doc1")}
                      />
                      <Button
                        typeBtn="blue"
                        block={true}
                        icon={iconArrowRightWhite}
                        label="Baixar recibo"
                        spaceBetween={true}
                        disabled={!ballotBoxData.drawn}
                        onClick={() => downloadDOC("Recibo", "doc2")}
                      />
                      <Button
                        typeBtn="blue"
                        block={true}
                        icon={iconArrowRightWhite}
                        label="Baixar destinatário"
                        spaceBetween={true}
                        disabled={!ballotBoxData.drawn}
                        onClick={() => downloadDOC("Destinatário" ,"doc3")}
                      />
                      <Button
                        typeBtn="blue"
                        block={true}
                        icon={iconArrowRightWhite}
                        label="Carta ganhador"
                        spaceBetween={true}
                        disabled={!ballotBoxData.drawn}
                        onClick={() => downloadDOC("Carta ganhador" ,"doc4")}
                      />
                    </div>
                  </Card>
                </div>

              <Card title="Sorteados">
                <TableSimple
                  headerTable={initTableHeader}
                  bodyTable={winnersData}
                />
              </Card>
          </WinnersContainer>
        )}
        <FooterContainer>
          <Separator maxWidth="100%" marginY="24" />

          <footer>
            <Button 
              label="Remover urna" 
              disabled={!!winnersData.length} 
              icon={iconTrash} 
              onClick={() => showAlertDelete()}
            />
            <Title 
              text={`Sorteio disponível em ${convertDateToShowInput(ballotBoxData.cefLotteryDate)}`} 
              elementHTML="span" 
              className="tag" 
            />
          </footer>
        </FooterContainer>

        <Footer isVisible={changeFooter.length > 0 ? true : false}>

          <Title elementHTML="strong" text="Campos atualizados:" className="tag" />

          {changeFooter.map((item: string) => (
            item ?
              <Badge value={getProps(item) || ""} key={item} /> : null
          ))}

          <Button 
            label="Cancelar" 
            typeBtn="outline"
            onClick={() => openAlert(false)} 
          />
          <Button 
            label="Atualizar dados" 
            typeBtn="blue" 
            disable={submiteButton} 
            onClick={() => showAlertUpdate()}
          />
        </Footer>
      </Menu>
    );
  }

  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path={`/lottery`} />
          </div>

          <div className="header-text">
            <BreadCrumb itens={["Plano", "Detalhes da Urna"]} itemActive="Detalhes da Urna" />
          </div>
        </ HeaderContainer>

      </Header>

      <Card title="Detalhes da Urna">
        <PlaceHolderNotExists title="Erro ao receber dados" />
      </Card>
    </Menu>
  )
}