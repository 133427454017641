import styled, { keyframes } from "styled-components";
import {colors, shadows, borders, spacings, fontWeights, fontSizes} from '../../styles/theme'

type typeToast = "error" | "success" | "warning";

export interface ToastProps {
  type: typeToast,
  timeForHidden: number
}

const colorProps = {
  success: colors.secondary_one,
  error: colors.secondary_four,
  warning: colors.secondary_two,
}

const loading = keyframes`  
  from{
    width: 0%;
  }
  to{
    width: 100%;
  }
`;

const entrance = keyframes`  
 0%{
    transform: scale(0);
  }
  25%{
    transform: scale(1.1);
  }
  50%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
`;

export const ToastContainer = styled.div<ToastProps>`
    position: fixed;
    top: 144px;
    right: ${spacings.ls};
    
    z-index: 99;
    overflow: hidden;
    max-width: 320px;

    box-shadow:${shadows.md};
    border-radius: ${borders.md};
    
    background: ${colors.white};
    animation: ${entrance} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
    .toast-content{
      display: flex;
      align-items: center;
      padding: ${spacings.ls};
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      color: ${colors.neutral_darkest};
      border: ${borders.xs} solid ${props => colorProps[props.type]};
      border-top-left-radius: ${borders.md};
      border-top-right-radius: ${borders.md};

      .toast-content-icon{
        display: flex;
        align-items: center;
        margin-right: ${spacings.sl};
      }
    }

    .toast-content-timer{
      position: relative;
      height: 8px;
      background: ${props => colorProps[props.type]};
      opacity: 0.3;
    }

    span{
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: block;
      width: 0%;
      height: 8px;
      background: ${props => colorProps[props.type]};
      animation: ${loading} 3s linear both;
    }

`