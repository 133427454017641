import styled from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontWeights,
  fontSizes
} from '../../styles/theme'

interface FooterProps {
  isVisible: boolean;
}

export const Container = styled.footer<FooterProps>`

position: fixed;
width: 100%;
bottom: ${props => props.isVisible ? "0" : "-100%"};
left: 0px;
display: flex;
align-items: center;
justify-content: flex-end;
padding: ${spacings.md} ${spacings.lg};
border-top: ${borders.xs} solid ${colors.neutral_regular};
background: ${colors.white};
transition: all 0.6s linear;

.btn-tag{
  padding: ${spacings.sl};
  font-weight: ${fontWeights.w700};
  font-size: ${fontSizes.xs};
  color: ${colors.neutral_darkest};
  background: ${colors.neutral_light};
  border-radius: ${borders.md};
  margin-right: ${spacings.md};
  margin-left: ${spacings.md};

  &:last-child{
    margin-right: ${spacings.lg};
  }
}

button{
  margin-left: ${spacings.lg};
  min-width: 150px;
}
`;
