export const arrayEquals = (array1: any[], array2: any[], compareObject: boolean = false): boolean => {
  if (array1.length != array2.length)
    return false;

  for (var i = 0, l=array1.length; i < l; i++) {
    // Check if we have nested arrays
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!arrayEquals(array1[i], array2[i])) {
        return false;
      }
    } else if (array1[i] instanceof Object && array2[i] instanceof Object) {
      if (compareObject) {
        objectEquals(array1[i], array2[i])
      } else {
        return false;
      }
    } else if (array1[i] != array2[i]) { 
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;   
    }           
  }       
  return true;
}

export const objectEquals = (object1: Object, object2: Object, compareArray: boolean = true): boolean => {
  for (let propName in object1) {
    if (object1.hasOwnProperty(propName) != object2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof object1[propName as keyof typeof object1] != typeof object2[propName as keyof typeof object2]) {
      return false;
    }
  }

  for(let propName in object2) {
    if (object1.hasOwnProperty(propName) != object2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof object1[propName as keyof typeof object1] != typeof object2[propName as keyof typeof object2]) {
      return false;
    }

    if(!object1.hasOwnProperty(propName))
      continue;
  
    if (object1[propName as keyof typeof object1] instanceof Array && object2[propName as keyof typeof object2] instanceof Array) {
      if (compareArray) {
        if (!arrayEquals([object1[propName as keyof typeof object1]], [object2[propName as keyof typeof object2]])) {
          return false;
        }
      } else {
        if(object1[propName as keyof typeof object1] != object2[propName as keyof typeof object2]) {
          return false
        }
      }
    } else if (object1[propName as keyof typeof object1] instanceof Object && object2[propName as keyof typeof object2] instanceof Object) {
      if (!objectEquals(object1[propName as keyof typeof object1], object2[propName as keyof typeof object2])) {
        return false;
      }
    } else if(object1[propName as keyof typeof object1] != object2[propName as keyof typeof object2]) {
      return false;
    }
  }
  return true;
}

export const objectEqualKeys = (object1: Object, object2: Object): boolean => {
  for (let propName in object1) {
    if (object1.hasOwnProperty(propName) !== object2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof object1[propName as keyof typeof object1] !== typeof object2[propName as keyof typeof object2]) {
      return false;
    }
  }

  for (let propName in object2) {
    if (object1.hasOwnProperty(propName) !== object2.hasOwnProperty(propName)) {
      return false;
    } else if (typeof object1[propName as keyof typeof object1] !== typeof object2[propName as keyof typeof object2]) {
      return false;
    }
  }

  return true;
}