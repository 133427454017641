import styled from "styled-components";
import {
  colors,
  spacings
} from '../../styles/theme'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-template-rows: 1fr;
  gap: 32px;
  grid-auto-flow: row;
  grid-template-areas:
    ". .";
  
  .card-content-text{
    margin-bottom: ${spacings.ls};

    p{
      margin-bottom: ${spacings.sl};
      color: ${colors.neutral_darker};
    }
  }
  
`
