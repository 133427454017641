import styled from "styled-components";
import { SeparatorProps } from './index';
import {
  colors,
  borders
} from '../../styles/theme'

export const SeparatorLine = styled.div<SeparatorProps>`

position: relative;
width: 100%;
max-width: ${props => props.maxWidth}px;
height: 2px;
margin: ${props => props.marginY}px 0px;
background: ${colors.neutral_medium};
border-radius: ${borders.xs};

&::after{
  content: "";
  position: absolute;
  top: 0px;
  width: ${props => props.progressBar}%;
  height: 2px;
  background: ${props => props.progressBar === 0 ?
    "transparent" : props.progressBar === 25 ?
      `${colors.secondary_four}` : props.progressBar === 50 ?
        `${colors.secondary_three}` : props.progressBar === 75 ?
          `${colors.secondary_two}` : `${colors.secondary_one}`};
  transition: all 0.3s linear;
}
`;