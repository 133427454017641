import React, { InputHTMLAttributes, useState } from 'react';
import MaskedInput from 'react-text-mask'
import { InputContainer } from './styles';
export interface InputFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  value?: string,
  backGround?: string,
  marginBotton?: string,
  name: string,
  typeInput?: string,
  error?: boolean,
  mensageError?: string,
  mensagem?: string,
  block?: boolean,
  icon?: boolean,
  iconImg?: JSX.Element,
  animation?: boolean,
  mask?: any,
  isEncrypted?: boolean,
  currentStateEncrypted?: boolean
  onClick?: () => void,
  onChange: (text: any) => void;
}

const svgSearch = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.7472 12.419C15.5283 13.2 15.5283 14.4664 14.7472 15.2474C13.9662 16.0285 12.6998 16.0285 11.9188 15.2474L14.7472 12.419ZM10.2521 13.5807C9.47105 12.7997 9.47105 11.5333 10.2521 10.7523C11.0331 9.97124 12.2995 9.97124 13.0805 10.7523L10.2521 13.5807ZM11.9188 15.2474L10.2521 13.5807L13.0805 10.7523L14.7472 12.419L11.9188 15.2474Z" fill="#3A3956" />
  <rect x="-1" y="1" width="10" height="10" rx="5" transform="matrix(-1 0 0 1 11.333 1.83325)" stroke="#3A3956" strokeWidth="2" />
</svg>

const iconLocked = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.25 6.49999C6.25 6.9142 5.91421 7.24999 5.5 7.24999C5.08579 7.24999 4.75 6.9142 4.75 6.49999H6.25ZM11.25 6.49999C11.25 6.9142 10.9142 7.24999 10.5 7.24999C10.0858 7.24999 9.75 6.9142 9.75 6.49999H11.25ZM4.75 6.49999V5H6.25V6.49999H4.75ZM11.25 5V6.49999H9.75V5H11.25ZM8 1.75C9.79493 1.75 11.25 3.20507 11.25 5H9.75C9.75 4.0335 8.9665 3.25 8 3.25V1.75ZM4.75 5C4.75 3.20507 6.20508 1.75 8 1.75V3.25C7.0335 3.25 6.25 4.0335 6.25 5H4.75Z" fill="#3A3956"/>
<path d="M3.5 8C3.5 7.17157 4.17157 6.5 5 6.5H11C11.8284 6.5 12.5 7.17157 12.5 8V13C12.5 13.8284 11.8284 14.5 11 14.5H5C4.17157 14.5 3.5 13.8284 3.5 13V8Z" stroke="#3A3956" strokeWidth="1.5"/>
</svg>

const iconUnlocked = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.75 6.5C1.75 6.91421 1.41421 7.25 1 7.25C0.585786 7.25 0.25 6.91421 0.25 6.5H1.75ZM6.75 6.5C6.75 6.91421 6.41421 7.25 6 7.25C5.58579 7.25 5.25 6.91421 5.25 6.5H6.75ZM0.25 6.5V5H1.75V6.5H0.25ZM6.75 5V6.5H5.25V5H6.75ZM3.5 1.75C5.29493 1.75 6.75 3.20507 6.75 5H5.25C5.25 4.0335 4.4665 3.25 3.5 3.25V1.75ZM0.25 5C0.25 3.20507 1.70507 1.75 3.5 1.75V3.25C2.5335 3.25 1.75 4.0335 1.75 5H0.25Z" fill="#3A3956"/>
<path d="M3.5 8C3.5 7.17157 4.17157 6.5 5 6.5H11C11.8284 6.5 12.5 7.17157 12.5 8V13C12.5 13.8284 11.8284 14.5 11 14.5H5C4.17157 14.5 3.5 13.8284 3.5 13V8Z" stroke="#3A3956" strokeWidth="1.5"/>
</svg>


export const InputFormField: React.FC<InputFormFieldProps> = ({
  label = "Meu input",
  value,
  backGround = "#F8F8F9",
  marginBotton = "40",
  name,
  typeInput = "text",
  error = false,
  mensageError = "",
  mensagem = "",
  block = false,
  icon = false,
  iconImg,
  animation = false,
  mask = "",
  onChange,
  isEncrypted = false,
  currentStateEncrypted,
  onClick = () => {},
  ...props }) => {

  const [inputPassword, setInputPassword] = useState(typeInput);
  
  icon = (isEncrypted && typeInput !== "password" && icon) ? isEncrypted : icon;
  let iconEncrypted = (isEncrypted && currentStateEncrypted) ? iconLocked : iconUnlocked;
  
  const onViewPassword = () => {
    const switchTypeInput = inputPassword === "text" ? "password" : "text";
    setInputPassword(switchTypeInput)
  }

  return (
    <InputContainer
      icon={icon}
      block={block}
      isError={error}
      animation={animation}
      backGround={backGround}
      typePassword={typeInput}
      marginBotton={marginBotton}
    >
      <div className="formField-input">
        {mask.length > 0 ?
          <MaskedInput
            value={value}
            name={name}
            aria-label={name}
            mask={mask}
            type={inputPassword}
            placeholder=" "
            onChange={onChange}
            {...props} /> :
          <input
            value={value}
            name={name}
            aria-label={name}
            type={inputPassword}
            placeholder=" "
            onChange={onChange}
            {...props}
          />
        }

        <label htmlFor={name}>{label}</label>

        {typeInput === "password" && !icon ?
          <div onClick={onViewPassword}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.0002 4.94446C4.86935 4.94446 2.14414 7.59589 1.29858 8.50001C2.14414 9.40414 4.86935 12.0556 8.0002 12.0556C11.131 12.0556 13.8563 9.40414 14.7018 8.50001C13.8563 7.59589 11.131 4.94446 8.0002 4.94446ZM8.0002 11.5476C7.1187 11.5224 6.28442 11.1874 5.68036 10.6161C5.0763 10.0448 4.75177 9.28378 4.77794 8.50001C4.75177 7.71625 5.0763 6.95525 5.68036 6.38393C6.28442 5.81261 7.1187 5.4776 8.0002 5.45239C8.8817 5.4776 9.71597 5.81261 10.32 6.38393C10.9241 6.95525 11.2486 7.71625 11.2225 8.50001C11.2486 9.28378 10.9241 10.0448 10.32 10.6161C9.71597 11.1874 8.8817 11.5224 8.0002 11.5476Z" fill="#3A3956" />
              <path d="M7.99978 10.5318C9.14832 10.5318 10.0794 9.62211 10.0794 8.50001C10.0794 7.37791 9.14832 6.46826 7.99978 6.46826C6.85124 6.46826 5.92017 7.37791 5.92017 8.50001C5.92017 9.62211 6.85124 10.5318 7.99978 10.5318Z" fill="#3A3956" />
              <path d="M7.99978 10.5318C9.14832 10.5318 10.0794 9.62211 10.0794 8.50001C10.0794 7.37791 9.14832 6.46826 7.99978 6.46826C6.85124 6.46826 5.92017 7.37791 5.92017 8.50001C5.92017 9.62211 6.85124 10.5318 7.99978 10.5318Z" fill="#3A3956" />
              <path d="M15.89 8.20034C15.7471 8.02764 12.3421 3.92859 8 3.92859C3.65795 3.92859 0.252866 8.02764 0.110035 8.20034C0.0385349 8.28733 0 8.39228 0 8.50002C0 8.60776 0.0385349 8.71271 0.110035 8.7997C0.252866 8.9724 3.65795 13.0714 8 13.0714C12.3421 13.0714 15.7471 8.9724 15.89 8.7997C15.9615 8.71271 16 8.60776 16 8.50002C16 8.39228 15.9615 8.28733 15.89 8.20034ZM8 11.5476C7.1185 11.5224 6.28423 11.1874 5.68016 10.6161C5.0761 10.0448 4.75157 9.28378 4.77774 8.50002C4.75157 7.71625 5.0761 6.95526 5.68016 6.38393C6.28423 5.81261 7.1185 5.47761 8 5.4524C8.8815 5.47761 9.71577 5.81261 10.3198 6.38393C10.9239 6.95526 11.2484 7.71625 11.2223 8.50002C11.2484 9.28378 10.9239 10.0448 10.3198 10.6161C9.71577 11.1874 8.8815 11.5224 8 11.5476Z" fill="#3A3956" />
              {inputPassword === "password" ? <rect x="5.53906" y="3.12708" width="12.6645" height="2.54274" rx="1" transform="rotate(45 5.53906 3.12708)" fill="#F8F8F9" /> : null}
              {inputPassword === "password" ? <rect x="3.72437" y="3.09839" width="13.2807" height="1.98683" rx="0.993416" transform="rotate(45 3.72437 3.09839)" fill="#3A3956" /> : null}
            </svg>
          </div> : null
        }

        {isEncrypted && typeInput !== "password"  && (
          <div onClick={onClick}>
            {iconEncrypted}
          </div>
        )}

        {icon ? <div>{iconImg ? iconImg : svgSearch}</div> : null}
      </div>

      {error ? <span>{mensageError}</span> : null}
      {!error && mensagem.length > 0 ? <span>{mensagem}</span> : null}

    </InputContainer>
  )
}