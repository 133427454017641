import styled from "styled-components";
import {
  colors,
  spacings,
  fontSizes,
  fontWeights,
  borders
} from '../../styles/theme'

export const TabNavHeader = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 5px;

    li{
      list-style: none;
      margin-right: ${spacings.ls};

      &:last-child{
        margin-right: 0;
      }

      button{
        padding-bottom: ${spacings.sm};
        font-weight: ${fontWeights.w700};
        font-size: ${fontSizes.md};
        color: ${colors.neutral_darker};
        border: none;
        border-bottom: ${borders.sm} solid transparent;
        background: none;

        &.isActive{
          color: ${colors.neutral_darkest};
          border-bottom: ${borders.sm} solid  ${colors.primary};
        }
      }
    }

`
export const TabNavContent = styled.div`

`