import React, { ChangeEvent, useEffect, useState } from 'react';
import { iconArrowTop } from '../../assets/icons';

import { SelectCuston } from './styles';

type SizeBtnProps = "small" | "large";
type AppearWhereProps = "bottom" | "top";

export interface SelectProps {
  dataId?: string,
  name: string,
  optionsValue: string[],
  optionsDescription: string[],
  minWidthOptions?: number,
  appearWhere?: AppearWhereProps,
  onChange?: any,
  readOnly?: boolean,
  value?: string,
  size?: SizeBtnProps,
  block?: boolean,
}

export interface SelectChangeEvent {
  dataId?: string,
  valueId?: string,
  optionSelected?: string,
}

 export const Select: React.FC<SelectProps> = (
  {
    dataId = "", 
    name, 
    readOnly = false, 
    value = "", 
    size = "large",
    optionsValue, 
    optionsDescription, 
    onChange, 
    minWidthOptions = 100, 
    appearWhere = "top",
    block = false,
  }) => {
  const [clickSelect, setClickSelect] = useState(false); 
  const [nameSelect, setNameSelect] = useState(value || name);
  const [hasSelectedItem, setHasSelectedItem] = useState(!!value);
  
  const handleIsOpen = () => {
    if(readOnly) return
    setClickSelect(!clickSelect);
  }

  const itemSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.dataset.values;
    const option = event.target.dataset.options;
    const dataId = event.target.dataset.id;

    const objectMounted:SelectChangeEvent = {
      dataId,
      valueId: value,
      optionSelected: option,
    }

    setHasSelectedItem(true);
    setNameSelect(option || '');
    onChange(objectMounted);
  }

  useEffect(() => {
    setClickSelect(false);
  }, [nameSelect]);

  return (
    <SelectCuston 
      selected={clickSelect} 
      minWidthOptions={minWidthOptions} 
      appearWhere={appearWhere} 
      onClick={handleIsOpen}
      itemSelected={hasSelectedItem}
      size={size}
      readOnly={readOnly}
      block={block}
    >
      <div className="wrapper">
        <div className="select" >
          <strong>{nameSelect}</strong>
        </div>
          <button>{iconArrowTop}</button>
      </div>

      <div className="select-options">
        <div className="select-options-content">
          {optionsDescription.map((option, index) => (
            <div
              key={optionsValue[index] || index}
              data-id={dataId}
              data-options={option}
              data-values={optionsValue[index]}
              onClick={(e: any) => itemSelected(e)}
              className={nameSelect === option ? "select-options-item isActive" : "select-options-item"}              
            >
              <span></span>
              <strong>{option}</strong>
            </div>
          ))}
        </div>
      </div>
    </SelectCuston>
  )
}