
//FONT-SIZE
export const fontSizes = {
  s0250: "0.25rem",
  s0500: "0.50rem",
  s0625: "0.625rem",
  s0750: "0.75rem",
  s0875: "0.875rem",
  s1000: "1rem",
  s1125: "1.125rem",
  s1250: "1.250rem",
  s1500: "1.5rem",
  s2000: "2rem",
  s2500: "2.5rem",
  s3000: "3rem",
  s9000: "9rem",
  s20000: "20rem"
}

//FONT-WEIGHT
export const fontWeights = {
  w100: 100,
  w200: 200,
  w300: 300,
  w400: 400,
  w500: 500,
  w600: 600,
  w700: 700,
  w800: 800,
  w900: 900,
}

//PRIMARY COLORS
export const colors = {
  blue200: "#5056FF",
  blue300: "#484DE5",
  blue400: "#4045CC",
  blue700: "#151D6B", 
  blue800: "#101652",
  blue900: "#0B0F38",
  
  //SECONDARY COLORS
  green200: "#85EFC4",
  green300: "#1FDE91",
  green400: "#1BC480",
  green500: "#18AB70",
  
  yellow200: "#FAD07D",
  yellow300: "#FDC201",
  yellow400: "#E5B001",
  yellow500: "#CC9D01",
  
  orange200: "#FFC37C",
  orange400: "#FB9B2A",
  orange500: "#E08A26",
  orange700: "#C77B21",
  
  red200: "#FF8888",
  red400: "#E54B4B",
  red500: "#CC4343",
  red600: "#B23A3A",
  
  //SUPPORT COLORS
  purple100: "#E3E5FF",
  purple200: "#CACEFF",
  
  mint100: "#CAFDF1",
  mint200: "#B1FDEB",
  
  beige100: "#F9ECE0",
  beige200: "#F9DFC7",
  
  pink100: "#F3E2EA",
  pink200: "#F3C9DD",
  
  //NEUTRALS COLORS
  white100: "#FFFFFF",
  gray100: "#F8F8F9",
  gray200: "#F0F1F4",
  gray300: "#E0E3EB",
  gray400: "#C2C2CD",
  gray700: "#3A3956",
  transparent: "transparent",
  
  //SHADOWS COLORS
  grayOpacity05: "#3A395605",
  grayOpacity08: "#3A395608",
  grayOpacity12: "#3A395612"
}