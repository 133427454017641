import { BreadCrumb } from "components/BreadCrumb"
import { Card } from "components/Card"
import { GoToBack } from "components/GoToBack"
import { Header } from "components/Header"
import { CardContent, HeaderContainer } from "./styles"
import { Loading } from "components/Loading"
import { Menu } from "components/Menu"
import { BaseRoutes } from "routes/BaseRoutes"
import { useRoleDetails } from "./useRoleDetails"
import { PlaceHolderNotExists } from "components/PlaceholderNotExists"
import { RoleForm } from "../shared/roleForm"
import { Footer } from "components/Footer"
import { Title } from "components/Title"
import { Button } from "components/Button"
import { Badge } from "components/Badge"
import { menuListItems } from '../../../assets/menu/menuList';

export const RoleDetails:React.FC = () => {
  const {
    loading,
    error,
    role,
    changeFooter,
    validForm,
    handleInputFieldChange,
    handleCheckBoxChange,
    discardHandler,
    submitHandler
  } = useRoleDetails();
  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path={BaseRoutes.permissions}/>
          </div>
          <div className="header-text">
            <BreadCrumb itens={["Permissões", loading ? '. . .' : role?.roleName]} />
          </div>
        </HeaderContainer>
      </Header>
      {
        loading
          ? <Loading/>
          : error
            ? <PlaceHolderNotExists title="Falha ao buscar permissao"/>
            : <div>
                <Card title="Permissão">
                  <CardContent>
                    <RoleForm
                      role={role}
                      handleInputFieldChange={handleInputFieldChange}
                      handleCheckBoxChange={handleCheckBoxChange}
                    />
                  </CardContent>
                </Card>
              </div> 
      }
      <Footer isVisible={changeFooter.length > 0}>
        <Title elementHTML="strong" text="Campos atualizados:" className="tag" />
        {changeFooter.map((item: string) => (item ? <Badge value={item} key={item} /> : null))}
        <Button label="Descartar" size="medium" typeBtn="outline" onClick={discardHandler} />
        <Button label="Atualizar dados" size="medium" typeBtn="blue" disable={!validForm} onClick={submitHandler} />
      </Footer>
    </Menu>
  )
}