import React, { ReactNode, useEffect } from 'react'
import { useTable } from '../../hooks/useTable'
import { GoToBack } from '../GoToBack'
import { Header } from '../Header'

import { TabNavHeader, TabNavBtn } from './styles'

interface tabNavItens {
  children?: ReactNode
  selected: string;
  setSelected: any;
  tabs: string[],
}

export const TabNav: React.FC<tabNavItens> = ({ children, tabs, selected = "Dados pessoais", setSelected }) => {
  const {clearFilters} = useTable();
  
  useEffect(() =>{
    return () => {
      clearFilters();
    }
  }, [])
  return (
    <>
      <Header >      
        <TabNavBtn >
          <GoToBack path="/home"/>
        </TabNavBtn>

        <TabNavHeader>
          {
            tabs.map(tab => {
              const active = (tab === selected ? 'isActive ' : '');
              return (
                <li key={tab}>
                  <button className={active} onClick={() => setSelected(tab)}>
                    {tab}
                  </button>
                </li>
              );
            })
          }
        </TabNavHeader>
      </Header>

      {children}
    </>
  )
}