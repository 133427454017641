export const iconOrderBy = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.0855103" width="16.0127" height="16" rx="4" fill="#F8F8F9" />
  <path d="M9.24643 5L13.0956 5" stroke="#3A3956" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M3.58829 10.1923L4.94793 11.551C5.02602 11.629 5.15256 11.629 5.23066 11.551L6.59066 10.1923M5.08947 11.1923V5.69226" stroke="#3A3956" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M9.24643 8.46155L11.0941 8.46155" stroke="#C2C2CD" strokeWidth="1.5" strokeLinecap="round" />
  <path d="M9.24643 11.9231L10.0933 11.9231" stroke="#E0E3EB" strokeWidth="1.5" strokeLinecap="round" />
</svg>

export const iconCopy = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.6447 13.7177C6.52411 14.8383 7.16404 14.1984 6.04346 15.319C3.80229 17.5601 0.440545 14.1984 2.68171 11.9572C3.80229 10.8366 6.04348 8.59544 7.16406 7.47485C8.28462 6.35429 9.66893 6.53832 10.5094 7.37874" stroke="#3A3956" strokeWidth="2" strokeLinecap="round" />
  <path d="M10.3553 4.28204C11.4759 3.16146 10.836 3.80138 11.9565 2.6808C14.1977 0.439637 17.5595 3.80138 15.3183 6.04255C14.1977 7.16313 11.9565 9.40432 10.8359 10.5249C9.71538 11.6455 8.33107 11.4614 7.49064 10.621" stroke="#3A3956" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconCopyWhite = <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.31338 14.628C8.33287 15.6085 8.8928 15.0486 7.91229 16.0291C5.95127 17.9901 3.00974 15.0486 4.97076 13.0876C5.95127 12.1071 7.91231 10.146 8.89282 9.16553C9.87331 8.18504 11.0846 8.34606 11.82 9.08143" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M11.6866 6.37185C12.6671 5.39134 12.1072 5.95127 13.0877 4.97076C15.0487 3.00974 17.9903 5.95127 16.0292 7.91229C15.0487 8.8928 13.0877 10.8538 12.1072 11.8344C11.1267 12.8148 9.91542 12.6538 9.18004 11.9184" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
</svg>


export const iconEmail = <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 1C2.5 1 1 2.5 1 4V11.5C1 13 2.5 14.5 4 14.5H16C18.25 14.5 19 13 19 11.5V4C19 2.5 17.5 1 16 1H4Z" stroke="#3A3956" strokeWidth="2" />
  <path d="M4.75 4.75C5.27771 5.27771 7.77796 6.81102 9.3933 8.02328C9.75709 8.29629 10.2582 8.31559 10.6283 8.05123L15.25 4.75" stroke="#3A3956" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconEmailOpacity = <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 1C2.5 1 1 2.5 1 4V11.5C1 13 2.5 14.5 4 14.5H16C18.25 14.5 19 13 19 11.5V4C19 2.5 17.5 1 16 1H4Z" stroke="#C2C2CD" strokeWidth="2" />
  <path d="M4.75 4.75C5.27771 5.27771 7.77796 6.81102 9.3933 8.02328C9.75709 8.29629 10.2582 8.31559 10.6283 8.05123L15.25 4.75" stroke="#C2C2CD" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconEmailWhite = <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 1C2.5 1 1 2.5 1 4V11.5C1 13 2.5 14.5 4 14.5H16C18.25 14.5 19 13 19 11.5V4C19 2.5 17.5 1 16 1H4Z" stroke="#fff" strokeWidth="2" />
  <path d="M4.75 4.75C5.27771 5.27771 7.77796 6.81102 9.3933 8.02328C9.75709 8.29629 10.2582 8.31559 10.6283 8.05123L15.25 4.75" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
</svg>


export const iconRedirect = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.25 1.75L5.5 8.5" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M13 5.5L13 1.10004C13 1.04482 12.9552 1.00004 12.9 1.00004L8.5 0.999999" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M4 1H1.1C1.04477 1 1 1.04233 1 1.09756C1 1.98117 1 8.60089 1 10C1 11.5 2.5 13 4 13C5.86133 13 12.0534 13 12.9031 13C12.9583 13 13 12.9566 13 12.9013C13 12.4161 13 10.2949 13 10" stroke="#3A3956" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconRedirectWhite = <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0937 5.90625L9.1875 11.8125" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M15.75 9.1875L15.75 5.35004C15.75 5.29481 15.7052 5.25004 15.65 5.25004L11.8125 5.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M7.875 5.25H5.35C5.29477 5.25 5.25 5.29135 5.25 5.34658C5.25 6.16276 5.25 11.9065 5.25 13.125C5.25 14.4375 6.5625 15.75 7.875 15.75C9.49549 15.75 14.8674 15.75 15.6536 15.75C15.7089 15.75 15.75 15.7053 15.75 15.6501C15.75 15.2011 15.75 13.3804 15.75 13.125" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
</svg>


export const iconArrowLeft = <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.75 11L5.25 11" stroke="#3A3956" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M9.1875 14.9375L5.33585 11.2594C5.22138 11.1478 5.22138 10.9442 5.33585 10.8325L9.1875 7.0625" stroke="#3A3956" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
</svg>
;

export const iconArrowRight = <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2175 5L1.98242 5" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M6.97461 9L11.8577 5.26355C12.0028 5.15013 12.0028 4.94329 11.8577 4.82986L6.97461 1" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const iconArrowRightWhite = <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2175 5L1.98242 5" stroke="#ffffff" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M6.97461 9L11.8577 5.26355C12.0028 5.15013 12.0028 4.94329 11.8577 4.82986L6.97461 1" stroke="#ffffff" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const iconArrowBlue = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="8" fill="#2125FF" />
  <path d="M15.1875 12L8.25 12" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M12 15L15.6682 12.1977C15.7773 12.1126 15.7773 11.9575 15.6682 11.8724L12 9" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const iconDataNotExists = <svg width="72" height="64" viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="72" height="64" rx="16" fill="white" />
  <rect x="42" y="6" width="24" height="24" rx="12" fill="#2125FF" />
  <path d="M58 14L54 18M50 22L54 18M54 18L58 22M54 18L50 14" stroke="white" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconX = <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 1L4 4M1 7L4 4M4 4L7 7M4 4L1 1" stroke="#3A3956" strokeWidth="2" strokeLinecap="round" />
</svg>

export const iconArrowTop = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 6L5.7035 1.59811C5.83111 1.4673 6.0638 1.4673 6.19141 1.59811L10.5 6" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const iconPlus = <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5625 5.5H5.5M9.4375 5.5L5.5 5.5M5.5 5.5V1.5625M5.5 5.5L5.5 9.4375" stroke="#3A3956" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
</svg>

export const iconPlusWhite = <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5625 5.5H5.5M9.4375 5.5L5.5 5.5M5.5 5.5V1.5625M5.5 5.5L5.5 9.4375" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
</svg>


export const iconCalendar = <svg width="18" height="17" viewBox="0 0 18 17"   fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 13.6333C1 14.5667 1.94118 15.5 2.88235 15.5L12 15.5C15 15.5 17 13.5 17 10.5V4.5C17 3.5 16 2.5 15 2.5L12.2941 2.5L5.70588 2.5L3 2.5C2 2.5 1 3.50602 1 4.5L1 13.6333Z" stroke="#3A3956" strokeWidth="2" strokeLinecap="round"/>
  <path d="M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1L7 1ZM5 4C5 4.55228 5.44772 5 6 5C6.55228 5 7 4.55228 7 4H5ZM5 1V4H7V1L5 1Z" fill="#3A3956"/>
  <path d="M13 1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1L13 1ZM11 4C11 4.55228 11.4477 5 12 5C12.5523 5 13 4.55228 13 4H11ZM11 1V4H13V1L11 1Z" fill="#3A3956"/>
  <path d="M12 9.5L16.9999 9.5V10.5C16.9998 13.5 14.9997 15.5 11.9997 15.5H10L10 11.5C10 10.5 11.0122 9.5 12 9.5Z" fill="#3A3956"/>
</svg>


export const iconDownload = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 17.5V3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H11.0331C11.5772 1.5 12.0977 1.72162 12.4748 2.11378L15.9417 5.71933C16.2999 6.0919 16.5 6.58869 16.5 7.10555V17.5C16.5 18.6046 15.6046 19.5 14.5 19.5H3.5C2.39543 19.5 1.5 18.6046 1.5 17.5Z" stroke="#3A3956" strokeWidth="2" />
  <path d="M9 13.5L9 6" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  <path d="M6 12L8.80233 14.9346C8.88741 15.0218 9.04254 15.0218 9.12761 14.9346L12 12" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
</svg>

export const iconTrash = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 4.50003L3 4.5V18.5C3 19.0523 3.44772 19.5 4 19.5H14C14.5523 19.5 15 19.0586 15 18.5063V4.50003Z" stroke="#F52929" strokeWidth="2" />
  <path d="M6.75 9V15" stroke="#F52929" strokeWidth="2" strokeLinecap="round" />
  <path d="M11.25 9V15" stroke="#F52929" strokeWidth="2" strokeLinecap="round" />
  <path d="M1.5 4.5H16.5" stroke="#F52929" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6 4.5L7.22361 2.05279C7.393 1.714 7.73926 1.5 8.11803 1.5H9.88197C10.2607 1.5 10.607 1.714 10.7764 2.05279L12 4.5" stroke="#F52929" strokeWidth="2" />
</svg>

export const iconWhiteTrash = <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 4.00002L4 4V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5552 12 13.0029V4.00002Z" stroke="white" strokeWidth="1.5"/>
  <path d="M6.5 7V11" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  <path d="M9.5 7V11" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  <path d="M3 4H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6 4L6.72361 2.55279C6.893 2.214 7.23926 2 7.61803 2H8.38197C8.76074 2 9.107 2.214 9.27639 2.55279L10 4" stroke="white" strokeWidth="1.5"/>
</svg>

export const iconMessage = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 9.99998C19 14.8631 15.4878 18.25 10.6584 18.25H1.93831C1.5378 18.25 1.30155 17.8026 1.5254 17.4705L3.63413 14.3421C1.65847 11.7368 2.31706 7.39472 3.63413 5.65788C4.95121 3.92104 7.14633 1.74998 10.6585 1.74998C16.3134 1.74998 19 6.03714 19 9.99998Z" stroke="#3A3956" strokeWidth="2" strokeMiterlimit="10"/>
</svg>


export const iconMessageWhite = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 9.99998C19 14.8631 15.4878 18.25 10.6584 18.25H1.93831C1.5378 18.25 1.30155 17.8026 1.5254 17.4705L3.63413 14.3421C1.65847 11.7368 2.31706 7.39472 3.63413 5.65788C4.95121 3.92104 7.14633 1.74998 10.6585 1.74998C16.3134 1.74998 19 6.03714 19 9.99998Z" stroke="#fff" strokeWidth="2" strokeMiterlimit="10"/>
</svg>

export const iconTicket = <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.89855 1.75458C9.29546 1.34348 10.099 1.32497 10.5055 1.71751L12.3677 3.51537C12.7743 3.90795 11.5922 5.08337 12.7388 6.19037C13.8854 7.29736 15.0186 6.07476 15.4252 6.46734L21.8285 12.6498C22.2352 13.0423 22.2449 13.846 21.848 14.2571L19.6319 16.5523C19.6319 16.5523 18.4854 15.4453 17.3277 16.6444C16.1701 17.8434 17.3166 18.9504 17.3166 18.9504L15.1006 21.2456C14.7037 21.6568 13.9002 21.6753 13.4936 21.2826L7.09022 15.1002C6.68361 14.7077 7.86564 13.5323 6.71908 12.4253C5.57251 11.3183 4.43932 12.5408 4.03271 12.1483L2.1706 10.3505C1.76398 9.95789 1.75428 9.15423 2.15118 8.74313L8.89855 1.75458Z" fill="#transparent"/>
<path d="M8.89855 1.75458C9.29546 1.34348 10.099 1.32497 10.5055 1.71751C10.5055 1.71751 11.707 2.8774 12.3677 3.51537C12.7743 3.90795 11.5922 5.08337 12.7388 6.19037C13.8854 7.29736 15.0186 6.07476 15.4252 6.46734C15.8318 6.85991 19.5922 10.4906 21.8285 12.6498C22.2352 13.0423 22.2449 13.846 21.848 14.2571C21.2724 14.8533 20.2783 15.8828 19.6319 16.5523C19.6319 16.5523 18.4854 15.4453 17.3277 16.6444C16.1701 17.8434 17.3166 18.9504 17.3166 18.9504C16.3433 19.9586 15.2886 21.0509 15.1006 21.2456C14.7036 21.6568 13.9002 21.6753 13.4936 21.2826C12.7566 20.5711 7.49683 15.4928 7.09022 15.1002C6.68361 14.7077 7.86564 13.5323 6.71908 12.4253C5.57251 11.3183 4.43932 12.5408 4.03271 12.1483C3.76603 11.8908 2.68263 10.8448 2.1706 10.3505C1.76398 9.95789 1.75428 9.15423 2.15118 8.74313C3.60649 7.2358 8.89855 1.75458 8.89855 1.75458ZM8.89855 1.75458C8.50165 2.16567 9.44981 1.18362 8.89855 1.75458Z" stroke="#C2C2CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


export const iconTicketWhite = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.89855 2.75458C9.29546 2.34348 10.099 2.32497 10.5055 2.71751L12.3677 4.51537C12.7743 4.90795 11.5922 6.08337 12.7388 7.19037C13.8854 8.29736 15.0186 7.07476 15.4252 7.46734L21.8285 13.6498C22.2352 14.0423 22.2449 14.846 21.848 15.2571L19.6319 17.5523C19.6319 17.5523 18.4854 16.4453 17.3277 17.6444C16.1701 18.8434 17.3166 19.9504 17.3166 19.9504L15.1006 22.2456C14.7037 22.6568 13.9002 22.6753 13.4936 22.2826L7.09022 16.1002C6.68361 15.7077 7.86564 14.5323 6.71908 13.4253C5.57251 12.3183 4.43932 13.5408 4.03271 13.1483L2.1706 11.3505C1.76398 10.9579 1.75428 10.1542 2.15118 9.74313L8.89855 2.75458Z" fill="transparent"/>
<path d="M8.89855 2.75458C9.29546 2.34348 10.099 2.32497 10.5055 2.71751C10.5055 2.71751 11.707 3.8774 12.3677 4.51537C12.7743 4.90795 11.5922 6.08337 12.7388 7.19037C13.8854 8.29736 15.0186 7.07476 15.4252 7.46734C15.8318 7.85991 19.5922 11.4906 21.8285 13.6498C22.2352 14.0423 22.2449 14.846 21.848 15.2571C21.2724 15.8533 20.2783 16.8828 19.6319 17.5523C19.6319 17.5523 18.4854 16.4453 17.3277 17.6444C16.1701 18.8434 17.3166 19.9504 17.3166 19.9504C16.3433 20.9586 15.2886 22.0509 15.1006 22.2456C14.7036 22.6568 13.9002 22.6753 13.4936 22.2826C12.7566 21.5711 7.49683 16.4928 7.09022 16.1002C6.68361 15.7077 7.86564 14.5323 6.71908 13.4253C5.57251 12.3183 4.43932 13.5408 4.03271 13.1483C3.76603 12.8908 2.68263 11.8448 2.1706 11.3505C1.76398 10.9579 1.75428 10.1542 2.15118 9.74313C3.60649 8.2358 8.89855 2.75458 8.89855 2.75458ZM8.89855 2.75458C8.50165 3.16567 9.44981 2.18362 8.89855 2.75458Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const iconReloadWhite = <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.875 12.05L14.3556 12.591C14.6458 12.8697 15.1042 12.8697 15.3944 12.591L14.875 12.05ZM17.5819 10.491C17.8807 10.2042 17.8904 9.72941 17.6035 9.4306C17.3167 9.13179 16.8419 9.1221 16.5431 9.40896L17.5819 10.491ZM13.2069 9.40896C12.9081 9.1221 12.4333 9.13179 12.1465 9.4306C11.8596 9.72941 11.8693 10.2042 12.1681 10.491L13.2069 9.40896ZM13.2069 15.741C13.5057 15.4542 13.5154 14.9794 13.2285 14.6806C12.9417 14.3818 12.4669 14.3721 12.1681 14.659L13.2069 15.741ZM9.40625 15.5C6.77101 15.5 4.6875 13.4567 4.6875 11H3.1875C3.1875 14.3423 6.00088 17 9.40625 17V15.5ZM4.6875 11C4.6875 8.54328 6.77101 6.5 9.40625 6.5V5C6.00088 5 3.1875 7.65773 3.1875 11H4.6875ZM9.40625 6.5C10.1677 6.5 11.3781 6.73442 12.3749 7.41841C13.3321 8.07527 14.125 9.16308 14.125 11H15.625C15.625 8.63692 14.5616 7.09973 13.2236 6.18159C11.9251 5.29058 10.401 5 9.40625 5V6.5ZM14.125 11V12.05H15.625V11H14.125ZM15.3944 12.591L17.5819 10.491L16.5431 9.40896L14.3556 11.509L15.3944 12.591ZM15.3944 11.509L13.2069 9.40896L12.1681 10.491L14.3556 12.591L15.3944 11.509ZM9.40625 17C9.82799 17 10.492 16.941 11.1769 16.7669C11.8487 16.5962 12.633 16.292 13.2069 15.741L12.1681 14.659C11.867 14.948 11.3753 15.1688 10.8075 15.3131C10.2528 15.454 9.71368 15.5 9.40625 15.5V17Z" fill="white"/>
</svg>



