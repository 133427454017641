import styled from 'styled-components'
import {
    colors,
    spacings,
    borders,
    fontWeights,
} from '../../styles/theme'

export const BoxNotification = styled.div`
    display: block;
    margin: 2rem auto;
    padding: 40px;
    border-radius: ${borders.lg};
    width: fit-content;

    background-color: ${colors.neutral_light};

    div {
        display: flex;
        align-items: center;

        span {
            margin-left: ${spacings.md};
            font-weight: ${fontWeights.w700};
        }
    }
    
`