import { createContext, ReactNode, useContext, useEffect, useState} from 'react';
import { useToast } from "../useToast";
import { api } from "../../services/api";
import { useSession } from "../useSession";
import axios, { CancelTokenSource } from 'axios';

type RequestType = "post" | "get" | "put" | "delete";

interface AuthProviderProps {
  children: ReactNode
}

interface AuthConnectionProps {
  url: string;
  body?: {};
  config?: {};
  type: RequestType;
  messageError?: string;
  messageSuccess?: string;
}

interface ResponseDataProps {
  connect: boolean;
  data: any;
}

interface AuthContextProps {
  connectAPI: (data:AuthConnectionProps ) => Promise<any>;
  connectAllAPI: (data:AuthConnectionProps[] ) => Promise<any>;
  cancelRequest: CancelTokenSource;
}

const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { setToast} = useToast();
  const { signOut, getSession } = useSession();
  const [ newRequest, setNewRequest ] = useState(false);

  const session = getSession();
  const cancelRequest = axios.CancelToken.source();
  const configLocal = {
    headers: {Authorization: `Bearer ${session}`},
    cancelToken: cancelRequest.token
  };

  const connectAllAPI = async (data: AuthConnectionProps[]) => {
    setNewRequest(true);
    let responseData:ResponseDataProps[] = [];

    const response = await Promise.allSettled(
      data.map(item => (
        api[item.type](item.url, item.config || configLocal)
      ))
    )
    
    response.map((item, index) => {     
      if(item.status === "rejected"){
        
        if(item.reason?.response?.status === 401){
          window.location.replace("/");
          signOut();
          return 0;
        }

        if(item.reason?.message !== "cancel"){
          setToast({
            message: data[index].messageError ? data[index].messageError! : "Falha ao conectar com o servidor",
            type: 'error',
            isActive: true,
            timeToRemoveToast: 3000
          })
        }

        responseData = ([
          ...responseData,
          {connect: false, data: item}
        ])
      }else{
        responseData = ([
          ...responseData,
          {connect: true, data: item.value.data}
        ])
      }
      setNewRequest(false);
      return 0
    });

    return responseData;
  }

  const connectAPI = async ({url, body, config, type, messageError, messageSuccess}: AuthConnectionProps) => {
    let responseData:ResponseDataProps;
    let response;

    try { 
      setNewRequest(true);

      if(body) response = await api[type](url, body, config || configLocal);
      else response = await api[type](url, config || configLocal);

      responseData = {
        connect: true,
        data: response.data
      }

      messageSuccess && (
        setToast({
          message: messageSuccess,
          type: 'success',
          isActive: true,
          timeToRemoveToast: 3000
        })
      )

    } catch (error: any) {
      if(error.status === 401){
        window.location.replace("/");
        signOut();
        return 0;
      }

      error.message !== "cancel" && ( 
        setToast({
          message: messageError ? messageError : "Falha ao conectar com o servidor",
          type: 'error',
          isActive: true,
          timeToRemoveToast: 3000
        })
      )

      responseData = {
        connect: false,
        data: []
      }
    }finally{
      setNewRequest(false)
    }
    return responseData;
  }

  useEffect(() => {}, [newRequest])

  return (
    <AuthContext.Provider value={{ 
      connectAPI,
      connectAllAPI,
      cancelRequest
    }}>
      {children}
    </AuthContext.Provider>
  );

}

export const useAuth = () => useContext(AuthContext);