import styled from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontSizes,
  fontWeights,
} from '../../styles/theme'

export const MenuContainer = styled.aside`
  position: fixed;
  width: 88px;
  height: 100%;
  background-color: ${colors.primary};
  box-shadow: ${borders.xs} ${borders.xs} ${borders.xs} 1px ${colors.neutral_light};
  transition: width 0.2s linear;
  z-index: 10;

  &:hover{
    width: 200px;
    ul{
      li{
        a{
          span{
            visibility: visible;
          }
        }
      }
    }
  }

  .menu-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 118px;
  }

  nav{
    min-height: 100%;
    border-top-left-radius: ${borders.xl};
    background: ${colors.white};
  }

  ul{
    display: flex;
    flex-direction: column;
    padding-top: ${spacings.lg};
    padding: ${spacings.lg} ${spacings.md} 0;
    overflow-y: auto;
    overflow-x: hidden;

    li{
      display: flex;
      margin-bottom: ${spacings.md};
      border-radius: ${spacings.sl};
      list-style: none;
      pointer-events: auto;

      a{
        img, span{
          pointer-events: none;
        }
      }

      &.isActive{
        a{
          background-color: ${colors.primary};
          color: ${colors.white};
        }
      }

      a{
        border-radius: ${borders.md};
        justify-self: flex-start;
        min-height: 55px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: ${spacings.md};
        font-weight: ${fontWeights.w700};
        font-size: ${fontSizes.sm};
        color: ${colors.neutral_darkest};
        text-decoration: none;
        background: ${colors.neutral_light};
        transition: all .2s linear;

        &:hover{
          background-color: ${colors.primary_hover};
          color: ${colors.white};
        }

        span{
          visibility: hidden;
          white-space: nowrap;
          transition: visibility 0.2s linear;
        }

        img{
          margin-right: ${spacings.sm};
        }
      }
    }

    &.nav-ul-divider{
      height: calc(100vh - 530px);
      border-bottom-color: ${colors.neutral_regular};
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }

    &:last-child{
      padding-top: 16px;
    }
  }
`;

export const Header = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0px;
  left: 88px;
  width: calc(100% - 88px);
  height: 115px;
  padding-left: ${spacings.lg};
  border-bottom: 1px solid ${colors.neutral_regular};
  background: ${colors.white};

  .header-container{
    display: flex;
    align-items: center;
    width: 100%;

    button{
      margin-left: ${spacings.lg};
    } 
  }
`

export const Content = styled.div`
  position: fixed;
  top: 115px;
  left: 88px;
  width: calc(100% - 88px);
  height: calc(100% - 115px);
  padding: ${spacings.ls} ${spacings.lg} 0 ${spacings.lg};
  overflow-y: auto;
`