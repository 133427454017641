import React, { ReactNode } from 'react';

import { Container } from './styles';

interface FooterProps {
  isVisible: boolean;
  children: ReactNode;
}

export const Footer: React.FC<FooterProps> = ({isVisible, children}) => {
  return (
    <Container isVisible={isVisible}>
      {children}
    </Container>
  );
}