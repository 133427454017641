import styled from 'styled-components';
import {
  colors,
  borders
} from '../../../styles/theme'

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 32px;
`

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px;
  left: 88px;
  width: calc(100% - 88px);
  height: 115px;
  border-bottom: 1px solid ${colors.neutral_regular};
  background: ${colors.white};

  .header-text{
    display: flex;
    h1{
      padding-bottom: ${borders.md};
      display: inline;
      border-bottom: ${borders.sm} solid ${colors.primary};
    }
  }
`