export function formattingCurrency(value: number | string ): string {
  const newValue = String(value).replace(/\D+/g, '')

  const currencyFormate = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(Number(newValue));

  return currencyFormate;
}
