
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "../../../hooks/useAuth";

import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { Loading } from "../../../components/Loading";
import { TableSimple } from "../../../components/TableSimple"
import { PlaceHolderNotExists } from "../../../components/PlaceholderNotExists";

import { formattingDate } from "../../../utils/formattingDate";
import { iconReloadWhite } from '../../../assets/icons/index'

interface NotificationProps {
  queueId: number,
  sentDate: string,
  subject: string,
  channel: string,
  address: string
  reSend: boolean
}
interface ParamsURLProps {
  id: string;
}

const initTableHeadNotification = ["Data de envio", "Assunto", "Canal", "Email / Telefone", ""];

export const Notifications: React.FC = () => {
  const { id } = useParams<ParamsURLProps>();
  const { connectAPI, cancelRequest } = useAuth();
  const [ notificationData, setNotificationData ] = useState<NotificationProps[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const onLoadingDataNotification = async () => {
    setIsLoading(true);

    const response = await connectAPI({
      url: `notifications?customerId=${id}`,
      type: "get", 
      messageError: "Falha ao listar notificações"
    });

    const data = response.connect ? response.data : [];
    setNotificationData(data);
    setIsLoading(false);
  }

  const reesendNotification = async (event: any) => {
    const queueId = event.target.value;

    await connectAPI({
      url: `notifications/${queueId}/resend`, 
      body: {}, 
      type: "post",
      messageError: "Falha ao reenviar E-mail/SMS!",
      messageSuccess: "E-mail/SMS enviado com sucesso!"
    });
  }

  const buttonTable = ({ ...props }: NotificationProps) => {
    return (
      <Button
        label="Reenviar"
        value={props.queueId}
        disable={!props.reSend}
        typeBtn="blue"
        size="small"
        icon={iconReloadWhite}
        onClick={reesendNotification}
      />
    )
  }

  const newData = () => {
    const newArray: any = [];

    notificationData.map(item => {
      newArray.push({
        sentDate: formattingDate(item.sentDate, true),
        subject: item.subject,
        channel: item.channel,
        address: item.address,
        tdButton: buttonTable(item)
      })
      return null
    })

    return newArray;
  }

  useEffect(() => {
    onLoadingDataNotification();

    return () => {
      setNotificationData([])
      cancelRequest.cancel("cancel")
    };
  }, [])

  if (isLoading) {
    return (
      <Card title="Detalhes">
        <Loading />
      </Card>
    )
  }

  if (!isLoading && notificationData.length) {
    return (
      <Card title="Detalhes">
        <TableSimple
          headerTable={initTableHeadNotification}
          bodyTable={newData()}
          listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
          quantityButtonsOnPaginate={5}
        />
      </Card>
    )
  }

  return (
    <Card title="Detalhes">
      <PlaceHolderNotExists title="Usuário não possui notificações" />
    </Card>
  )
}