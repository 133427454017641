import ReactInputVerificationCode from 'react-input-verification-code';

import { Container } from './styles';

interface reactInputVerificationCodeProps {
  value: string;
  length: number;
  placeholder: string;
  onChange: any;
}

export const InputVerificationCode = ({ value, length = 5, placeholder = "", onChange }: reactInputVerificationCodeProps) => {
  return (
    <Container>
      <ReactInputVerificationCode onChange={onChange} value={value} placeholder={placeholder} length={length} />
    </Container>
  )
}