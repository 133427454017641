import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import { Header } from "../../components/Header"
import { Title } from "../../components/Title"
import { Card } from "../../components/Card"
import { TableSimple } from "../../components/TableSimple"
import { Button } from "../../components/Button"
import { Loading } from "../../components/Loading"
import { Badge } from "../../components/Badge"
import { PlaceHolderNotExists } from "../../components/PlaceholderNotExists"
import { formattingDate } from "../../utils/formattingDate"
import { formattingOnlyNumber, formattingOnlyNumberCurrency } from "../../utils/formattingOnlyNumber"
import { iconArrowRight, iconArrowRightWhite, iconPlus, iconPlusWhite } from "../../assets/icons"
import { Grid } from "./styles"
import { menuListItems } from "../../assets/menu/menuList"
import { Menu } from "../../components/Menu"

import link_user from '../../assets/imgs/img_menu/menu_link_user_selected.svg'
import link_user_not_selected from '../../assets/imgs/img_menu/menu_link_user_not_selected.svg'
import link_lottery from '../../assets/imgs/img_menu/menu_link_lottery_selected.svg'
import link_lottery_not_selected from '../../assets/imgs/img_menu/menu_link_lottery_not_selected.svg'
import link_settings from '../../assets/imgs/img_menu/menu_link_settings_selected.svg'
import link_settings_not_selected from '../../assets/imgs/img_menu/menu_link_settings_not_selected.svg'
import link_account from '../../assets/imgs/img_menu/menu_link_account_selected.svg'
import link_account_not_selected from '../../assets/imgs/img_menu/menu_link_account_not_selected.svg'

interface LotteryProps {
  lotteryPlanId: number,
  description: string,
  startDate: string,
  endDate: string,
  couponQuantity: number
}

interface SuggestionProps {
  prizeSuggestionId: number,
  suggestion: string,
}

interface PrizeProps {
  prizeId: number,
  description: string,
  prizeSuggestion: SuggestionProps[]
}

interface NextLotteryProps {
  ballotBoxId: number,
  cefLotteryDate: string,
  lotteryPlanDescription: string,
}

const linkMenu = [
  {
    url_img: link_user_not_selected,
    url_img_hover: link_user,
    path: "/home",
    name: "Usuários",
    isActive: false,
  },
  {
    url_img: link_lottery,
    url_img_hover: link_lottery_not_selected,
    path: "/lottery",
    name: "Sorteios",
    isActive: true,
  },
  {
    url_img: link_settings_not_selected,
    url_img_hover: link_settings,
    path: "/permissions",
    name: "Permissões",
    isActive: false,
  },
  {
    url_img: link_account_not_selected,
    url_img_hover: link_account,
    path: "/login",
    name: "Minha conta",
    isActive: false,
  }
];

const headerTableLottery = ["Id", "Descrição", "Data Inicial", "Data Final", "Qtd. Cupons", ""];
const headerTablePrize = ["Valor", "Sugestões", ""];

export const Lottery: React.FC = () => {
  const {push} = useHistory();
  const {connectAllAPI, cancelRequest} = useAuth();
  const [lotteryData, setLotteryData] = useState<LotteryProps[]>([]);
  const [prizeData, setPrizeData] = useState<PrizeProps[]>([]);
  const [nextLotteryData, setNextLotteryData] = useState<NextLotteryProps>({} as NextLotteryProps);
  const [isLoading, setIsLoading] = useState(false);

  const getLotteryAndPrizeData = async () => {
    setIsLoading(true);
    const response = await connectAllAPI([
      {url: "lotterys/plans", type:"get", messageError: "Falha ao listar planos"},
      {url: "lotterys/prizes", type:"get", messageError: "Falha ao listar prêmios"}
    ])
    
    if(!response[0].connect){
      setLotteryData([])
    }else{
      const data = response[0].data;
      const newArray = data.lotteryPlans.map((item:LotteryProps) => ({
        lotteryPlanId: item.lotteryPlanId,
        description: item.description,
        startDate: formattingDate(item.startDate),
        endDate: formattingDate(item.endDate),
        couponQuantity: formattingOnlyNumber(item.couponQuantity),
        tdButton: buttonTable(`/lottery/lottery-details/${item.lotteryPlanId}`)
      }))
      
      setLotteryData(newArray.sort((a:any, b:any) => a.lotteryPlanId - b.lotteryPlanId));
      setNextLotteryData({
        ballotBoxId: data.nextBallotBox.ballotBoxId,
        cefLotteryDate: formattingDate(data.nextBallotBox.cefLotteryDate),
        lotteryPlanDescription: data.nextBallotBox.lotteryPlanDescription
      });
    }

    if(!response[1].connect){
      setPrizeData([])
    }else{
      const prizesOrdened = [] as PrizeProps[];
      response[1].data.sort((a:PrizeProps, b:PrizeProps) => b.prizeId - a.prizeId).map((item:PrizeProps) => (
        prizesOrdened.push({
          prizeId: item.prizeId,
          description: Number(item.description) ? formattingOnlyNumberCurrency(item.description) : item.description,
          prizeSuggestion: (item.prizeSuggestion)
        })
      ))
      setPrizeData(prizesOrdened);
    }
    setIsLoading(false);
  }

  const addPrizeDataToList = () => {
    const newArray: any = [];
    prizeData.map(item => (
      newArray.push({
        value: item.description,
        prizeSuggestion: badgesPrize(item.prizeSuggestion),
        tdButton: buttonTable(`/lottery/prize-details/${item.prizeId}`),
      })
    ))
    return newArray.sort((a:any, b:any) => a.lotteryPlanId - b.lotteryPlanId);
  }

  const badgesPrize = (suggestions: SuggestionProps[]) => {
    const newArray: any = [];
    let count = 0;
  
    suggestions ? suggestions.map((suggest, index) => (  
      // eslint-disable-next-line no-sequences
      count++,      
      index < 2 && (
        newArray.push(<Badge margin="0 8px 0 0" fontSize={14} fontWeight={500} key={suggest.suggestion} value={suggest.suggestion}/>)
      )
    )) : (
      newArray.push("")
    )

    count > 2 && newArray.push(<Badge fontSize={14} fontWeight={500} key={count} value={String(`+${count-2}`)}/>);
    return newArray;
  }

  const buttonTable = (route: string) => {
    return (
      <Button 
        label="" 
        size="small" 
        typeBtn="default" 
        icon={iconArrowRight} 
        onlyIcon={true} 
        onClick={() => push(route)} 
      />
    )
  }

  useEffect(() => {
    getLotteryAndPrizeData();

    return () => {      
      setLotteryData([]);
      setPrizeData([]);
      cancelRequest.cancel("cancel");
    };   
  }, [])

  if(!isLoading && !prizeData.length && !lotteryData.length){
    return (
      <Menu links={menuListItems}>
        <Header>
          <Title text="Gestão de sorteios" elementHTML="h1" className="subtitle-medium" />
        </ Header>
        <Card title="">
          <PlaceHolderNotExists title="Falha ao connectar com o servidor"/>
        </Card>
      </Menu>
    )
  }

  return (  
    <Menu links={menuListItems}>
      <Header>
        <Title text="Gestão de sorteios" elementHTML="h1" className="subtitle-medium" />
      </ Header>

      {isLoading ? <Loading/> : (
        <Grid>
          <div className="col">
            <Card 
              title="Planos" 
              labelButton="Adicionar novo plano" 
              hasButton={true} 
              iconButton={iconPlus} 
              iconHover={iconPlusWhite}
              onClick={() => push("lottery/register-new-plan")}
            >
                {lotteryData.length ? (
                  <TableSimple 
                    headerTable={headerTableLottery} 
                    bodyTable={lotteryData} 
                    listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
                    quantityButtonsOnPaginate={5}
                  />
                ) : (
                  <PlaceHolderNotExists title="Falha ao listar planos" />
                )}
            </Card>            
            
            <Card 
              title="Prêmios" 
              labelButton="Adicionar novo prêmio" 
              hasButton={true} 
              iconButton={iconPlus}
              iconHover={iconPlusWhite}
              onClick={() => push("lottery/register-new-prize")}
            >
              {prizeData.length ? (
                <TableSimple 
                  headerTable={headerTablePrize} 
                  bodyTable={addPrizeDataToList()} 
                  listRowsPerPage={["10 itens", "30 itens", "90 itens"]}
                  quantityButtonsOnPaginate={5}
                />
              ) : (
                <PlaceHolderNotExists title="Falha ao listar prêmios" />
              )}
            </Card>
          </div>
  
          <div className="col">
            <Card title="Próximo sorteio" >
              {nextLotteryData.ballotBoxId > 0 ? (
                  <>
                    <div className="card-content-text">
                      <Title text="Data sorteio CEF" elementHTML="p" className="overline" />
                      <Title text={nextLotteryData.cefLotteryDate} elementHTML="h3" className="subtitle-medium" />
                    </div>
                    <Button 
                      label="Ver mais" 
                      size="small" 
                      typeBtn="blue"
                      icon={iconArrowRightWhite} 
                      onClick={() => push(`/lottery/ballot-details/${nextLotteryData.ballotBoxId}`)}
                    />
                  </>
                ) : (
                  <PlaceHolderNotExists title="Falha ao buscar próximo sorteio."/>
                )
              }
            </Card>
          </div>
  
        </Grid>
      )}

    </Menu>
  )
}