import React, { Children, ReactNode } from 'react'

interface tabNavItens {
  children?: ReactNode;
  currentIndex: number;
}

export const StepByStep: React.FC<tabNavItens> = ({ children, currentIndex = 0 }) => {

  const changeStep = () => {
    const totalChildren = Children.count(children);

    const childrenCurrent = Children.map(children, (child: any, index) => {

      if (currentIndex > totalChildren && index + 1 === totalChildren) {
        return React.cloneElement(child, {
          "data-index": `${index + 1}`
        })
      }

      if (index + 1 === currentIndex) {
        return React.cloneElement(child, {
          "data-index": `${index + 1}`
        })
      }
    })

    return childrenCurrent;
  }

  return (
    <>
      {changeStep()}
    </>
  )
}