import { Button } from "components/Button";
import { Card } from "components/Card";
import { GoToBack } from "components/GoToBack";
import { Header } from "components/Header";
import { Title } from "components/Title";
import { Menu } from '../../../components/Menu';
import { RoleForm } from "../shared/roleForm";
import { HeaderContainer, Footer } from './styles';
import { useNewRole } from "./useNewRole";
import { menuListItems } from '../../../assets/menu/menuList';


export const NewRole: React.FC = () => {
  const { 
    loading,
    invalidForm,
    role,
    handleInputFieldChange,
    handleCheckBoxChange,
    handleOnSubmit
  } = useNewRole();
  return (
    <Menu links={menuListItems}>
      <Header>
        <HeaderContainer>
          <div>
            <GoToBack path="/permissions" />
          </div>
          <div className="header-text">
            <Title text="Cadastrar nova permissão" elementHTML="h1" className="btn-text" />
          </div>
        </HeaderContainer>
      </Header>
      <Card title="Permissão">
        <RoleForm
          handleInputFieldChange={handleInputFieldChange}
          handleCheckBoxChange={handleCheckBoxChange}
          role={role}
        />
      </Card>
      <Footer>
          <Button 
          label="Cadastrar permissão" 
          typeBtn="blue"
          load={loading}
          disabled={invalidForm}
          onClick={handleOnSubmit} 
        />
      </Footer>
    </Menu>
  );
}