import styled from 'styled-components'
import {
  colors,
  spacings,
  borders
} from '../../styles/theme';

interface CardProps {
  hasBorder: boolean;
}

export const CardContainer = styled.div<CardProps>`
    background: ${colors.white};
    ${props => props.hasBorder ? `border: ${borders.xs} solid ${colors.neutral_regular}` : ""};
    border-radius: ${borders.md};

    & + div {
        margin-top: ${spacings.ls};
    }
    
    .card-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 70px;
      padding: ${spacings.ls};
      border-bottom: ${borders.xs} solid ${colors.neutral_light};
    }

    .card-content{
        padding: ${spacings.ls};
    }

    .card-footer{
        padding: ${spacings.ls};
    }
`