import { arrayEquals, objectEqualKeys, objectEquals } from "./compare";

/**
 * Returns which fields of two objects of the same type are different. `objValue` and `objCheck` are two objects that must be the same type.
 * @param objValue changed values
 * @param objCheck initial values
 * @throws error if `objValue` and `objCheck` are objects of different types
 * @returns array of changed fields from `objValue` and `objCheck`
 */
export const checkChange = (objValue: Object, objCheck: Object): string[] => {
  const changed: string[] = [];
  if (!objectEqualKeys(objValue, objCheck)) {
    console.warn('checkChange: objects have different keys');
    return [];
  }

  for (let i in objValue) {
    if (objValue[i as keyof typeof objValue] !== objCheck[i as keyof typeof objCheck]) {
      changed.push(i)
    }
  }
  return changed;
};

export const checkChangeDeep = (objValue: Object, objCheck: Object): string[] => {
  const changed: string[] = []
  if (!objectEqualKeys(objValue, objCheck)) {
    console.warn('checkChange: objects have different keys');
    return [];
  }

  for (let i in objValue) {
    if (objValue instanceof Array && objCheck instanceof Array) {
      if (!arrayEquals(objValue[i as keyof typeof objValue], objCheck[i as keyof typeof objCheck])) {
        changed.push(i)
      }
    } else if (objValue instanceof Object && objCheck instanceof Object) {
      if (!objectEquals(objValue[i as keyof typeof objValue], objCheck[i as keyof typeof objCheck])) {
        changed.push(i)
      }
    } else {
      if (objValue[i as keyof typeof objValue] != objCheck[i as keyof typeof objCheck]) {
        changed.push(i)
      }
    }
  }
  return changed;
}