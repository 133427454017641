import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';

import { BtnDefault } from './styles';

type SizeBtnProps = "small" | "medium" | "large";
type SideBtnProps = "left" | "right";
type ColorBtnProps = "default" | "outline" | "blue" | "danger" | "warning" | "success";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string,
  size?: SizeBtnProps,
  block?: boolean,
  typeBtn?: ColorBtnProps,  
  disable?: boolean,
  danger?: boolean,
  link?: boolean,
  iconSide?: SideBtnProps,
  icon?: any,
  iconHover?: any,
  onlyIcon?: boolean,
  load?: boolean,
  spaceBetween?: boolean,
  onClick?: any
}


export const Button: React.FC<ButtonProps> = (
  {
    label = "button",
    size = "large",
    block = false,
    typeBtn = "default",
    disable = false,
    link = false,
    iconSide = "left",
    icon,    
    iconHover,
    onlyIcon = false,
    load = false,
    spaceBetween = false,
    onClick,
    ...props
  }) => {
    const [localIcon, setLocalIcon] = useState(icon)


  if (load) {
    disable = true;
  }

  let classValue = `${load ? " load" : ""} ${size}`;
  
  useEffect(() => {}, [localIcon])

  return (
    <BtnDefault
      disabled={disable}
      onClick={onClick}
      block={block}
      iconSide={iconSide}
      onlyIcon={onlyIcon}
      typeBtn={typeBtn}
      className={classValue}
      spaceBetween={spaceBetween}
      onMouseOver={() => setLocalIcon(iconHover || icon)}
      onMouseLeave={() => setLocalIcon(icon)}
      {...props}
    >

      {load ?
        <div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div> :

        !!icon && !onlyIcon ?
          <>
            {iconSide === "left" ? label : <span>{localIcon}</span>}
            {iconSide === "left" ? <span>{localIcon}</span> : label}
          </> :
          
          !localIcon ? label : <span>{localIcon}</span>
      }

    </BtnDefault >
  )
}