import styled from "styled-components";
import {
  colors,
  fontSizes, 
  fontWeights, 
  spacings,
  borders
} from '../../styles/theme';

export const Container = styled.div`
  background: ${colors.white};
  margin-bottom: ${spacings.ls};
  border-radius: ${borders.lg};

  .card-header{
    padding: 0 0 ${spacings.ls} 0;
    border-bottom: ${borders.xs} solid ${colors.neutral_light};
    margin-bottom: ${spacings.ls};
  }
`

export const ContainerTable = styled.div`
  border: ${borders.xs} solid ${colors.neutral_regular};
  border-radius: ${borders.md};

table{
  border-collapse: separate; 
  border-spacing: 0 0; 
  width: 100%;

  thead{
    background: ${colors.neutral_light};

    tr{
      th{
        min-width: 24px;
        height: 48px;
        padding-left: ${spacings.md};
        font-size: ${fontSizes.sl};
        color: ${colors.neutral_darkest};
        text-align: left;
        white-space: nowrap;

        button{
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          border: none;
          font-weight: ${fontWeights.w700};
          font-size: ${fontSizes.sl};
          color: ${colors.neutral_darkest};
          background-color: transparent; */

          svg{
            margin-left: ${spacings.sl};
          }
        }
      }
    }
  }

  tbody{

    tr{
      background: ${colors.neutral_light};
      border-bottom: 1px solid ${colors.neutral_medium};
      transition: all 0.2s linear;

      &:last-child{
        border-bottom: 1px solid transparent;
        border-bottom-left-radius: ${borders.ml};
        border-bottom-right-radius: ${borders.ml};
      }

      td{
        height: 54px;
        padding: ${spacings.sm} ${spacings.md};
        text-align: left;
        font-weight: ${fontWeights.w500};
        font-size: ${fontSizes.sm};
        color: ${colors.neutral_darkest};

        &:first-child, &:nth-last-child(2){
          white-space: nowrap;
        }

        span{
          white-space: nowrap;
        }

        .paragraph-small{
          margin-bottom: ${spacings.sl};

          &:last-child{
            margin-bottom: 0;
          }
        }

        .tbody-flex{
          display: inline-flex;
          align-items: center;

          button{
            margin-right: ${spacings.sl};
          }

          figure{
            width: 50px;
            height: auto;
            margin-right: ${spacings.sl};

            img{
              object-fit: cover;
              width: 100%;
              height: auto;
            }
          }
        }
      }

      &:hover{
        background: ${colors.neutral_regular};
      }

      &:nth-child(2n+1){
        background: white;

        &:hover{
          background: ${colors.neutral_regular};
        }
      }
    }
  }
}
`

export const TablePagination = styled.div`
  padding-top: ${spacings.ls};
  margin-bottom: ${spacings.ls};

  display: flex;
  justify-content: space-between;

`

export const ItemsPerPage = styled.div`

  strong, span{
    font-weight: ${fontWeights.w700};
    font-size: ${fontSizes.sl};
    color: ${colors.neutral_darkest};
    margin-right: ${spacings.md};
  }
  
`

export const ItemsNavigation = styled.div`
  display: flex;

  .paginate{
    display: flex;
    align-items: center;

    .arrow-left{
      margin-left: ${spacings.sl};
      svg{
        transform: rotate(-90deg);
      }
    }

    .arrow-right{
      svg{
        transform: rotate(90deg);
      }
    }

    .paginate-dot {
      display: flex;
      color: ${colors.neutral_regular};

      strong{
        font-size: ${fontSizes.ml};
        text-align: center;
        margin-bottom: calc(50%);


        &:nth-child(2){
          margin: 0 ${spacings.xs};
        }
      }
    }
  }


  button{
    min-height: ${spacings.xl};
    margin-right: ${spacings.sl};
    padding: ${spacings.sl};
    border: ${borders.xs} solid ${colors.neutral_regular};
    border-radius: ${spacings.sl};
    font-size: ${fontSizes.sm};
    color: ${colors.neutral_darkest};
    font-weight: ${fontWeights.w700};
    background: ${colors.white};
    transition: all 0.2s linear;

    &:hover, &.isActive{
        color: ${colors.white};
        border: ${borders.xs} solid ${colors.primary_hover};
        background-color: ${colors.primary_hover};

        svg{
          path{
            stroke: ${colors.white};
          }
        }
      }

    &.button-paginate-last{
      margin-left: ${spacings.sl};
    }

    &.prev{
      color: ${colors.white};
      border: ${borders.xs} solid ${colors.primary_hover};
      background-color: ${colors.primary_hover};

      &[disabled]{
        cursor: not-allowed;
      }
      
      span{
        display: block;
        transform: rotate(90deg) translateY(-2px);
      }
      
    }

    &.next{
      color: ${colors.white};
      border: ${borders.xs} solid ${colors.primary_hover};
      background-color: ${colors.primary_hover};
      
      span {
        display: block;
        transform: rotate(-90deg) translateY(-2px);
      }
    }

    &.lastPage{
      color: ${colors.neutral_darkest};
      background: ${colors.white};
      border: ${borders.xs} solid ${colors.neutral_regular};
    }

    &:last-child{      
      margin-right: 0px;
    }

    &.isActive{
      color: ${colors.white};
      border: ${borders.xs} solid ${colors.primary_hover};
      background-color: ${colors.primary_hover};
      pointer-events: none;
    }

    &:hover{
      color: ${colors.white};
      border: ${borders.xs} solid ${colors.primary_hover};
      background-color: ${colors.primary_hover};
    }
  }

  .dot-btn{
    display: flex;
    align-items: center;

    span{
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-left: ${spacings.xs};
      background: ${colors.neutral_regular};
      border-radius: 50%;

      &:first-child{
        margin-left: 0px;
      }
    }
  }
`