import styled from "styled-components";
import {
  colors
} from '../../styles/theme'

export const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
  min-height: 100vh;

  form, .login-img{
    display: grid;
    place-items: center;
    width: 100%;
  }

  form {
    .form-content{
      width: 100%;
      max-width: 330px;
      margin: 0px 20px;

      .form-header{
        margin-bottom: 60px;

        figure{
          margin-bottom: 20px;
        }
      }

      .form-body{
       
      }

      .form-footer{
        text-align: center;
      }
    }
  }

  .login-img{
    background: ${colors.support_one_light};

    figure{
      margin: 0 20px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 768px){
    .login-img{
      display: none;
    }
  }

`