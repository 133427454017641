import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {v4 as uuid} from 'uuid';

import { useAuth } from '../../../hooks/useAuth';
import { useAlert } from '../../../hooks/useAlert';

import { Button } from '../../../components/Button';
import { Loading } from '../../../components/Loading';
import { Title } from '../../../components/Title';
import { Card } from '../../../components/Card';
import { Footer } from '../../../components/Footer';
import { GoToBack } from '../../../components/GoToBack';
import { Header } from '../../../components/Header';
import { InputFormField } from '../../../components/InputFormField';

import { formattingOnlyNumberCurrency } from '../../../utils/formattingOnlyNumber';
import { iconPlus, iconWhiteTrash } from '../../../assets/icons';
import { HeaderContainer, CardContent } from './styles';
import { menuListItems } from '../../../assets/menu/menuList';
import { Menu } from '../../../components/Menu';

interface SuggestionProps {
  prizeSuggestionId: string;
  suggestion: string,
}

interface PrizeProps {
  prizeId: string;
  description: string;
  prizeSuggestion: SuggestionProps[];
}

interface OldPrizeProps {
  oldDescription: string;
  qtdOldSuggestions: number;
}

interface PrizeParamsProps {
  id: string;
}

interface MessagePrizeValuesProps {
  hasError: boolean;
  messageError?: string;
  textMessageBelowInput?: string;
  animationSearch: boolean; 
  hasValueValid: boolean; 
}

const initPrizeSuggestion = [{
  prizeSuggestionId: uuid()+"_local",
  suggestion: ""
}]

export const RegisterNewPrize: React.FC = () => {
  const {id} = useParams<PrizeParamsProps>();
  const {push} = useHistory();
  const {connectAPI, cancelRequest} = useAuth();
  const {openAlert, closeAlert, alert, setAlertPosition, setTextAlertInterface} = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButtonSavePrize, setIsLoadingButtonSavePrize] = useState(false);
  const [messageValueInput, setMessageValueInput] = useState<MessagePrizeValuesProps>();  
  const [animationInput, setAnimationInput] = useState<boolean>();
  const [prizeAlreadyExists, setPrizeAlreadyExists] = useState<boolean>();
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [prizeValue, setPrizeValue] = useState<string>("");
  const [prizeInitValue, setPrizeInitValue] = useState<string>("");
  const [prizeInitialized, setPrizeInitialized] = useState<OldPrizeProps>();
  const [prizeSuggestions, setPrizeSuggestions] = useState<SuggestionProps[]>(id ? [] : initPrizeSuggestion);
  const [suggestionsInputs, setSuggestionsInputs] = useState<SuggestionProps[]>([]);
  const [currentSuggestion, setCurrentSuggestion] = useState<string>("");
  const [debounce, setDebounce] = useState<NodeJS.Timeout | any>();
  
  const getPrizeData = async () => {    
    setIsLoading(true);
    setPrizeValue("");
    setPrizeSuggestions([]);
    setPrizeInitialized({} as OldPrizeProps);
    
    const response = await connectAPI({
      url: `lotterys/prizes/${id}`, 
      type: "get", 
      messageError: "Falha ao retornar dados do prêmio"
    });

    if(response.connect){
      const data:PrizeProps = response.data;

      setPrizeValue(formattingOnlyNumberCurrency(data.description));
      setPrizeInitValue(formattingOnlyNumberCurrency(data.description));
      setPrizeSuggestions(data.prizeSuggestion);
      setPrizeInitialized({oldDescription: data.description, qtdOldSuggestions: data.prizeSuggestion.length});
    }
    setIsLoading(false)
  }

  const handleSubmitPrize = async () => {
    setIsLoadingButtonSavePrize(true)

    let arraySuggestions: SuggestionProps[] = [];
    for(let value of Object.values(suggestionsInputs)){
      if(String(value) !== ""){
        arraySuggestions.push(value);
      }
    }

    const response = await connectAPI({
      url: "lotterys/prizes", 
      body: {description: cleanNumberFormattedString(prizeValue), suggestions: arraySuggestions}, 
      type: "post",
      messageError: "Falha ao salvar prêmio",
      messageSuccess: "Prêmio salvo com sucesso"
    });

    if(response.connect){
      push("/lottery")
    }
    setIsLoadingButtonSavePrize(false)
  }

  const handleEditPrize = async () => {
    setIsLoadingButtonSavePrize(true);
    let arraySuggestions: SuggestionProps[] = [];

    for(let value of Object.values(suggestionsInputs)){
      if(String(value) !== ""){
        arraySuggestions.push(value);
      }
    }

    const response = await connectAPI({
      url: `lotterys/prizes/${id}`, 
      body: {description: cleanNumberFormattedString(prizeValue), suggestions: arraySuggestions}, 
      type: "put",
      messageError: "Falha ao salvar prêmio",
      messageSuccess: "Prêmio salvo com sucesso"
    });

    if(response.connect){
      getPrizeData()
    }
    setIsLoadingButtonSavePrize(false)
    setSuggestionsInputs([])
  }

  const handleDeleteSuggestion = async (idSuggestion: string) => {
    setPrizeValue(prizeInitValue)
    prizeInitialized && setPrizeInitialized({...prizeInitialized, qtdOldSuggestions: prizeInitialized?.qtdOldSuggestions-1})
    const response = await connectAPI({
      url: `/lotterys/prizes/${id}?prizeSuggestionId=${idSuggestion}`, 
      type: "delete",
      messageError: "Falha ao deletar sugestão de prêmio",
      messageSuccess: "Sugestão removida com sucesso"

    });

    setIsLoading(false);
  }

  const handleCreateNewInputSuggestion = () => {
    const newSuggestion = {
      prizeSuggestionId: uuid()+"_local",
      suggestion: ""
    }
    setPrizeSuggestions([ ...prizeSuggestions, newSuggestion])
  }

  const handleRemoveInputSuggestion = async (idSuggestion: string) => {
    if(idSuggestion && !verifyPrizeSuggestionIdIsLocal(idSuggestion)){
      handleDeleteSuggestion(idSuggestion);
    }
    setPrizeSuggestions([...prizeSuggestions.filter(suggest => String(suggest.prizeSuggestionId) !== String(idSuggestion))]);
    const convertToArray = Object.entries(suggestionsInputs);

    const itemToRemove = convertToArray.findIndex(item => item[0] === idSuggestion);
    if(itemToRemove !== -1) convertToArray.splice(itemToRemove,1);

    const convertedToObject = convertToArray.reduce((accum:any, [key, value]) => {
      accum[key] = value;
      return accum;
    }, {});

    setSuggestionsInputs(convertedToObject);
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    setSuggestionsInputs({...suggestionsInputs, [targetName]: targetValue})
  }

  const verifyPrizeSuggestionIdIsLocal = (id: string) => {
    return id.includes("_local");
  }

  const checkPrizeExists = async (event: any) => {    
    const value = cleanNumberFormattedString(event.target.value);
    setPrizeValue(formattingOnlyNumberCurrency(value));

    debounceFN(
      async (value: string) => {
        setAnimationInput(true);
        const response = await connectAPI({
          url: `lotterys/prizes/verify?description=${value}`, 
          type: "get", 
          messageError: "Falha ao verificar se prêmio é válido"
        });
        if(response.connect){
          setPrizeAlreadyExists(response.data);
        }
        setAnimationInput(false);        
      }, value);
  }

  const cleanNumberFormattedString = (value: String) => {
    return String(Number(String(value).replace(/\D+/g, '')));
  }

  const debounceFN = (fn: any, value: string) => {
    clearTimeout(debounce);
    const delay = setTimeout(() => {
      fn(value);
    }, 1500)

    setDebounce(delay);
  }

  const showAlert = (id: string) => {
    setCurrentSuggestion(id);
    setAlertPosition("center");
    setTextAlertInterface({
      title: "Excluir sugestão de prêmio?",
      description: "Atenção! Essa ação não pode ser revertida.",
      closeAlertText: "Voltar",
      continueAlertText: "Sim"
    })
    openAlert(true);
  }

  const getConfirmation = () => {
    (currentSuggestion && prizeSuggestions.length > 1) && handleRemoveInputSuggestion(currentSuggestion);
    closeAlert();
  }

  const handleClickToRemoveSuggestion = (event: any) => {
    const clickSuggestionValue = event.target.value;
    if(verifyPrizeSuggestionIdIsLocal(clickSuggestionValue)){
      handleRemoveInputSuggestion(clickSuggestionValue);
    }else{
      showAlert(clickSuggestionValue);
    }
  }

  const validation = {   
    isSuggestionValid: false,
    hasSuggestionLength: 0,

    //VALUE
    validValue() {
      const value = prizeValue && cleanNumberFormattedString(prizeValue);
      const oldValue = prizeInitialized?.oldDescription && cleanNumberFormattedString(prizeInitialized?.oldDescription);
      setMessageValueInput({ 
        hasError: false,
        messageError: "",
        textMessageBelowInput: "",
        animationSearch: false,
        hasValueValid: false
      })
      if((value === "0" || value === undefined)){
        setAnimationInput(undefined);
        setPrizeAlreadyExists(false)
        setMessageValueInput({ 
          hasError: false,
          textMessageBelowInput: "O valor não pode ser igual a R$ 0,00",
          animationSearch: false,
          hasValueValid: false
        })
      }else{
        if((!id && animationInput !== false && !!value) 
        || (id && value !== oldValue && animationInput !== false && !!value)){
          setMessageValueInput({ 
            hasError: false,
            textMessageBelowInput: "Verificando...",
            animationSearch: true,
            hasValueValid: false
          })
        }else if((!id && !prizeAlreadyExists && animationInput === false && messageValueInput?.textMessageBelowInput === "Verificando...") 
        || (id && messageValueInput?.textMessageBelowInput === "Verificando..." && value && !prizeAlreadyExists && prizeInitialized && value !== prizeInitialized.oldDescription && animationInput === false)){
          setMessageValueInput({ 
            hasError: false,
            textMessageBelowInput: "Valor válido para cadastro",
            animationSearch: false,
            hasValueValid: true
          })
        }else{
          if((!id && prizeAlreadyExists && animationInput === false && messageValueInput?.textMessageBelowInput === "Verificando...") 
          || (id && prizeAlreadyExists && animationInput === false && prizeInitialized && value !== prizeInitialized.oldDescription && messageValueInput?.textMessageBelowInput === "Verificando...")){
            setMessageValueInput({
              hasError:true,
              messageError: "Valor já está cadastrado",
              animationSearch: false,
              hasValueValid: false
            })
          }else{
            setMessageValueInput({ 
              hasError: false,
              textMessageBelowInput: "",
              animationSearch: false,
              hasValueValid: false
            })
          }
        }
      }
    },

    validAndCheckPrizeValue(event: any){
      const value = event.target.value ? cleanNumberFormattedString(event.target.value) : "";

      if((!id && value !== "" && value !== "0" && value !== null && value !== undefined) 
      || (id && prizeInitialized && value !== prizeInitialized.oldDescription && value !== "" && value !== "0" && value !== null && value !== undefined)){
        checkPrizeExists(event);
      }else{     
        setPrizeValue(formattingOnlyNumberCurrency(event.target.value.replace(/\D+/g, '')));
      }
      validation.validValue();
    },

    //SUGGESTIONS
    validSuggestions(suggestion: SuggestionProps){
      const arraySuggestions = Object.values(suggestionsInputs); 
      const quantitySuggestions = [] as SuggestionProps[];
      
      if(verifyPrizeSuggestionIdIsLocal(String(suggestion.prizeSuggestionId))){
        
        arraySuggestions && Object.entries(arraySuggestions).filter(([name, value]) => (
          String(value) !== "" && value  !== undefined) && quantitySuggestions.push( value));
          
        validation.hasSuggestionLength = quantitySuggestions.length;
  
        validation.isSuggestionValid = (!id && !!validation.hasSuggestionLength) || (!!id && !!validation.hasSuggestionLength)
      }
      
      return (
        !!id && !verifyPrizeSuggestionIdIsLocal(String(suggestion.prizeSuggestionId)) 
        && prizeInitialized?.qtdOldSuggestions === 1)
        || (!id && verifyPrizeSuggestionIdIsLocal(String(suggestion.prizeSuggestionId))
        && prizeSuggestions.length === 1)
    },

    //BUTTON CADASTRAR OR EDITAR
    canSaveOrEdit(){
      if(id){
        return {
          type: "edit",
          authorized: validation.isSuggestionValid || messageValueInput?.hasValueValid
        }
      }else{
        return {
          type: "create",
          authorized: validation.isSuggestionValid && messageValueInput?.hasValueValid
        }
      }
    }
  }

  useEffect(() => {
    alert.typeContinue === "save" && getConfirmation();
  }, [alert])
  
  useEffect(() => {
    id && getPrizeData();

    return () => {
      setPrizeValue("")
      setPrizeSuggestions([])
      cancelRequest.cancel("cancel")
    };
  }, [])

  useEffect(() => {
    if(id){
      if(Object.values(suggestionsInputs).length > 1){
        setHasSuggestions(true)
      }else{
        setHasSuggestions(false)
      }
    }else{
      if(Object.values(suggestionsInputs).length){
        setHasSuggestions(true)
      }else{
        setHasSuggestions(false)
      }
    }
  }, [suggestionsInputs])

  useEffect(() => {
    validation.validValue();
  }, [
    prizeValue,
    prizeAlreadyExists, 
    animationInput, 
    prizeInitialized, 
    currentSuggestion
  ])
      
  useEffect(() => {
    
  }, [
    prizeSuggestions, 
    hasSuggestions, 
    prizeAlreadyExists, 
    suggestionsInputs, 
    prizeInitialized, 
    messageValueInput
  ])

  if(isLoading){
    return (
      <Menu links={menuListItems}>
        <Header>
          <HeaderContainer>
              <GoToBack path="/lottery"/>
            <div className="header-btn"> 
            </div>

            <div className="header-text">
              <Title text="Prêmio" elementHTML="h1" className="btn-text" />
            </div>
          </ HeaderContainer>
        </Header>

        <Loading />
      </Menu>
    )
  }

    return (
      <Menu links={menuListItems}>
        <Header>
          <HeaderContainer>
            <div className="header-btn"> 
              <GoToBack path="/lottery"/>
            </div>
            <div className="header-text">
              <Title text={!id ? "Cadastrar novo prêmio" : "Editar prêmio"} elementHTML="h1" className="btn-text" />
            </div>
          </ HeaderContainer>
        </Header>
  
        <Card title="Prêmio">
          <InputFormField
            autoComplete="off" 
            name="value" 
            label="Valor" 
            value={prizeValue}
            error={messageValueInput?.hasError}
            mensageError={messageValueInput?.messageError}
            mensagem={messageValueInput?.textMessageBelowInput}
            backGround="white"
            icon={true}
            animation={messageValueInput?.animationSearch}
            onChange={validation.validAndCheckPrizeValue}
          />
        </Card>
  
        <Card 
          title="Sugestão de prêmio" 
          hasButton={true} 
          labelButton="Adicionar sugestão"
          iconButton={iconPlus} 
          onClick={handleCreateNewInputSuggestion}
        >
          {prizeSuggestions.map((suggest, index) => (
            <CardContent key={suggest.prizeSuggestionId}>
              {verifyPrizeSuggestionIdIsLocal(String(suggest.prizeSuggestionId)) ? 
                <InputFormField 
                  label="Sugestão de prêmio" 
                  block={true}
                  name={String(suggest.prizeSuggestionId)}
                  onChange={onChange}
                  backGround="white"
                />
                :               
                <InputFormField 
                  label="Sugestão de prêmio" 
                  block={true}
                  readOnly={true}
                  value={suggest.suggestion}
                  name={String(suggest.prizeSuggestionId)}
                  onChange={onChange}
                  backGround="white"
                />
              }
              
              <Button
                label=""
                onlyIcon={true}
                icon={iconWhiteTrash}
                value={suggest.prizeSuggestionId}
                typeBtn="danger"
                disabled={validation.validSuggestions(suggest)}
                onClick={handleClickToRemoveSuggestion}
              />
            </CardContent>
          ))}
  
        </Card>
  
        <Footer isVisible={true}>
          {validation.canSaveOrEdit().authorized ? (
            <Button
              label={id ? "Editar prêmio" : "Cadastrar prêmio"}
              size="medium"
              typeBtn="blue"
              load={isLoadingButtonSavePrize}
              onClick={validation.canSaveOrEdit().type === "edit" ? handleEditPrize : handleSubmitPrize}
            />
          ) : (   
            <Button
              label="Cadastrar prêmio"
              size="medium"
              disable={true}
            />
          )}
        </Footer> 
      </Menu>
    );
}