import styled from "styled-components";
import {colors, spacings, borders, fontSizes, fontWeights} from '../../styles/theme'

export const Container = styled.div`
  position: absolute;
  top: ${spacings.lg};
  
  button{
    display: flex;
    position: relative;
    transition: all 0.2s;
    align-items: center;
    background: none;
    border: ${borders.xs} solid transparent;
    

    svg{
      margin-right: ${spacings.sl};
    }

    span{
      color: ${colors.neutral_darkest};
      font-size: ${fontSizes.sm};
      font-weight: ${fontWeights.w700};
    }

    &:hover, &.active, &:focus {
      background: none;
      border: ${borders.xs} solid transparent;
    }

    &:hover{
      svg{
        filter: brightness(0.9);
      }
      
      span{
        filter: brightness(0.9);
      }
    }
  }
`