import styled, { keyframes, css } from "styled-components";
import {
  colors,
  spacings,
  borders,
  fontSizes,
  fontWeights,
  shadows
} from '../../styles/theme'

type SizeBtnProps = "small" | "large";

interface SelectProps {
  selected: boolean,
  minWidthOptions?: number,
  appearWhere?: "bottom" | "top",
  itemSelected: boolean,  
  size: SizeBtnProps;
  readOnly: boolean;
  block: boolean;
}

const size = {
  small: "40px",
  large: "53px"
}

const fade = keyframes`
  from {
    opacity: 0;
    transform: scale(0)
  },
  to{
    opacity: 1;
    transform: scale(1)
  }
`

export const SelectCuston = styled.div<SelectProps>`
  display: ${props => props.block ? "block" : "inline-flex"};
  position: relative;
  margin-right: ${spacings.md};
  cursor: pointer;

  &:last-child{
    margin-right: 0;
  };

.wrapper{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: auto;
  width: 100%;
  min-height: ${props => size[props.size]};
  height: 100%;
  /* z-index: 0;  FICOU COMO 0 POIS NAS TELAS ONDE EXISTE UM DATEPICKER ACIMA, O SELECT SOBREPOE O CALENDAR DO DATEPICKER - REMOVER COMENTÁRIO AO SOLUCIONAR */

  border-radius: ${props => props.size === "small" ? `${borders.md}` : `${borders.lg}`};

  padding: 0 ${props =>  props.size === "small" ? `${spacings.sm}` : `${spacings.md}`};

  border: 2px solid ${({selected, itemSelected, readOnly}:SelectProps) => (selected && itemSelected ) || (!readOnly && itemSelected) ? `${colors.primary}` : `${colors.neutral_medium}`};

  overflow: hidden;
  text-overflow: ellipsis;
  
  .select {
    pointer-events: none;
    transition: all 0.2s linear;

    strong{
      font-family: DM Sans;
      font-style: normal;
      font-weight: ${fontWeights.w700};
      font-size: ${fontSizes.sm};
      color: ${props => props.readOnly ? colors.neutral_darker : colors.neutral_darkest};
      text-overflow: ellipsis;
    }
  }

  button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-left: ${spacings.sl};
    background: ${colors.neutral_medium};
    border-radius: ${borders.sm};
    border: none;
    ${(props) => props.readOnly && css`pointer-events: none;`}
    
    svg{
      transform: ${(props) => props.appearWhere === "bottom" ? "rotate(0deg)" : "rotate(180deg)"}
    }
    
    svg{
      transition: all 0.2s linear;
      ${(props) => props.selected && css`
        animation: arrowEffectButton 5s linear infinite;
        transform: ${props.appearWhere === "bottom" ? "rotate(180deg)" : "rotate(0deg)"}
      `};
    }
  
    &:hover {
      filter: brightness(0.9);
    }
  }
}


.select-options{
  z-index: 10;
  ${props => props.selected ? "display: block;" : "display: none;"}
  position: absolute;
  ${props => props.appearWhere === "top" ? "top: 45px;" : "bottom: 45px;"}
  left: 0px;
  width: 100%;
  min-width: ${props => props.minWidthOptions}%;
  width: auto;
  height: auto;
  max-height: 200px;
  border-radius: ${borders.lg};
  background: ${colors.white};
  overflow: auto;
  box-shadow: ${shadows.sm};
  animation: ${fade} 0.2s linear;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .select-options-content{
    display: flex;
    flex-direction: column;  
  }

  .select-options-item{
      display: flex;
      align-items: center;
      padding: ${spacings.sm};

      &.isActive{
        span{
          background: ${colors.primary};
        }
      }

      span{
        display: inline-block;
        height: 15px;
        width: 15px;
        margin-right: ${spacings.sm};
        border-radius: 50%;
        border: ${borders.xs} solid ${colors.primary};
        pointer-events: none;
      }
      
      strong{
        font-family: DM Sans;
        font-weight: ${fontWeights.w500};
        font-size: ${fontSizes.sm};
        color: ${colors.neutral_darkest};
        white-space: nowrap;
        pointer-events: none;
      }

      &:hover{
        background-color: ${colors.white};
      }
    }
}
`

export const Content = styled.div`

`